import { Button, Box, Flex, Icon, Image, Text, useToast, Skeleton, useColorMode } from '@chakra-ui/react'
import { BiChevronDown, BiSortDown } from 'react-icons/bi'
import avatarDefault from "../../../assets/img/Avatar.png"
import { FormTextEditor } from '../../../shared/components/FormElements/FormTextEditor';
import { Formik, Form as FormikForm } from "formik";
import { RespuestaForoInt } from '../../../interfaces/ForoInt';
import { Dispatch, SetStateAction } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { DateTime } from 'luxon';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { useAuthContext } from '../../../shared/context/user.context';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { addForoRespuesta } from '../../../shared/middlewares/foro.middlewate';
import { handleErrors } from '../../../shared/utils/functions/handleErrors';
import { toastNotify } from '../../../shared/utils/functions/toastNotify';
import { StatusEnumTypes } from '../../../shared/utils/types/StatusEnumTypes';

interface Props {
    respuestas: RespuestaForoInt[];
    query: { sortBy?: string; order?: string; };
    setQuery: Dispatch<SetStateAction<{ sortBy?: string; order?: string; }>>;
    Refresh: () => void;
}

export default function RespuestasPregunta({
    respuestas,
    query,
    setQuery,
    Refresh
}: Props) {
    const { preguntaId } = useParams()
    const toast = useToast()
    const { user } = useAuthContext();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { colorMode } = useColorMode()

    const initialValues = {
        contenido: null,
    };

    const validationSchema = Yup.object().shape({
        contenido: Yup.string()
            .required("El contenido es requerido")
            .typeError("El contenido debe ser un texto"),
    });

    const onSubmit = async (
        values: { contenido: string | null; },
        { resetForm }: { resetForm: () => void }
    ) => {
        if (!preguntaId || !user?.id) return;
        if (!values?.contenido) return toastNotify(toast, StatusEnumTypes.WARNING, "Debes ingresar contenido en la respuesta")

        const respuesta = {
            preguntaId: preguntaId,
            contenido: values?.contenido,
            userId: user?.id,
            fijado: false,
        }

        addForoRespuesta({
            data: respuesta
        })
            .then(() => {
                Refresh()
                resetForm()
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    return (
        <Flex
            direction="column"
            gap="20px"
            pb="40px"
        >
            <Flex
                align="center"
                justifyContent="space-between"
            >
                <Text
                    fontSize="18px"
                    fontWeight="700"
                >
                    {respuestas?.length || 0} respuestas
                </Text>

                <Box position="relative">
                    <Button
                        fontSize="15px"
                        fontWeight="400"
                        color="font"
                        bg={colorMode === "dark" ? "bg_dark_light" : "#F0F0F3"}
                        rounded="16px"
                        border="1px solid"
                        borderColor={colorMode === "dark" ? "border_variant" : "border"}
                        p="8px"
                        h="fit-content"
                        w="fit-content"
                        minW="160px"
                        rightIcon={<Icon as={BiChevronDown} />}
                        leftIcon={<Icon as={BiSortDown} />}
                        onClick={() => setIsOpen(!isOpen)}
                        _hover={{}}
                        _active={{ transform: "scale(0.98)" }}
                        transition={"all 0.3"}
                    >
                        Ordenar por
                    </Button>

                    <AnimatePresence>
                        {isOpen && (
                            <motion.div
                                initial={{ opacity: 0, height: 0, width: 0 }}
                                animate={{ opacity: 1, height: 'auto', width: 'auto' }}
                                exit={{ opacity: 0, height: 0, width: 0 }}
                                transition={{ duration: 0.3 }}
                                style={{
                                    position: 'absolute',
                                    top: "120%",
                                    right: "10%",
                                    zIndex: 10,
                                    backgroundColor: colorMode === "dark" ? "#191D23" : "purewhite",
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    overflow: 'hidden',
                                }}
                            >
                                <Flex
                                    direction="column"
                                    px="10px"
                                    py="15px"
                                    gap="5px"
                                >
                                    <Button
                                        bg={query?.order === "desc" ? "main" : "transparent"}
                                        h="fit-content"
                                        py="10px"
                                        px="25px"
                                        w="100%"
                                        fontSize="16px"
                                        fontWeight="700"
                                        color={query?.order === "desc" ? "purewhite" : "font"}
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation()

                                            const newQuery = { ...query, order: "desc" }

                                            setQuery(newQuery)
                                            setIsOpen(false)
                                        }}
                                        isDisabled={query?.order === "desc"}
                                        _disabled={{ cursor: "not-allowed", bg: "main" }}
                                        _hover={query?.order === "desc" ? {} : undefined}
                                    >
                                        Más reciente
                                    </Button>

                                    <Button
                                        bg={query?.order === "asc" ? "main" : "transparent"}
                                        h="fit-content"
                                        py="10px"
                                        px="25px"
                                        w="100%"
                                        fontSize="16px"
                                        fontWeight="700"
                                        color={query?.order === "asc" ? "purewhite" : "font"}
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation()

                                            const newQuery = { ...query, order: "asc" }

                                            setQuery(newQuery)
                                            setIsOpen(false)
                                        }}
                                        isDisabled={query?.order === "asc"}
                                        _disabled={{ cursor: "not-allowed", bg: "main" }}
                                        _hover={query?.order === "asc" ? {} : undefined}
                                    >
                                        Más antigua
                                    </Button>
                                </Flex>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </Box>
            </Flex>


            <Flex
                direction="column"
                gap="24px"
                w="full"
                bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                p="32px"
                rounded="10px"
                border="1px solid"
                borderColor={colorMode === "dark" ? "transparent" : "border"}
            >
                {respuestas ?
                    respuestas?.map((respuesta: RespuestaForoInt) => (
                        <Flex
                            direction="column"
                            gap="24px"
                            borderBottom="1px solid"
                            borderBottomColor={colorMode === "dark" ? "border_variant" : "border"}
                            pb="30px"
                        >
                            <Flex
                                gap="20px"
                                align="center"
                            >
                                <Flex
                                    w="40px"
                                    h="40px"
                                >
                                    <Image
                                        src={avatarDefault}
                                        objectFit="cover"
                                    />
                                </Flex>
                                <Flex
                                    direction="column"
                                    gap="5px"
                                >
                                    <Text
                                        fontSize="16px"
                                        fontWeight="600"
                                        color="font"
                                    >
                                        {respuesta?.user?.nombreCompleto}
                                    </Text>

                                    <Text
                                        fontSize="13px"
                                        fontWeight="500"
                                        color="font_grey"
                                    >
                                        Publicado {DateTime.fromISO(respuesta?.createdAt!).toRelative({ locale: 'es' })}
                                    </Text>
                                </Flex>
                            </Flex>

                            <MarkdownPreview
                                source={respuesta?.contenido}
                                style={{
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    color: colorMode === "dark" ? "#FFFFFF" : "#515561",
                                    lineHeight: "140%",
                                    width: '100%',
                                    maxWidth: '100%',
                                    backgroundColor: "transparent"
                                }}
                                wrapperElement={{ "data-color-mode": colorMode === "dark" ? "dark" : "light" }}
                            />
                        </Flex>
                    ))
                    :
                    <Skeleton
                        h="124px"
                        w="full"
                        rounded="8px"
                    />
                }


                <Flex
                    direction="column"
                    gap="24px"
                >
                    <Flex
                        align="start"
                        gap="20px"
                    >
                        <Image
                            src={user?.avatar?.url || avatarDefault}
                            w="40px"
                            h="40px"
                            rounded="8px"
                        />

                        <Formik
                            onSubmit={onSubmit}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                        >
                            {(formik) => {
                                const { handleSubmit } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                        style={{
                                            width: "100%"
                                        }}
                                    >
                                        <FormTextEditor
                                            height="200px"
                                            name='contenido'
                                            onChange={formik.setFieldValue}
                                            placeholder="Escribe una respuesta"
                                            styles={{
                                                border: "1px solid",
                                                borderRadius: "8px",
                                                padding: "0",
                                                fontSize: "15px",
                                                fontWeight: "400",
                                                lineHeight: "150%",
                                                minHeight: "200px",
                                                borderColor: colorMode === "dark" ? "#576776" : "#E2E8F0",
                                                color: colorMode === "dark" ? "#FFFFFF" : "#000000",
                                                backgroundColor: colorMode === "dark" ? "bg_dark_light" : "purewhite",
                                            }}
                                        />


                                        <Flex
                                            gap="12px"
                                        >
                                            <Button
                                                rounded="10px"
                                                bg="main"
                                                fontSize="16px"
                                                fontWeight="700"
                                                color="purewhite"
                                                p="16px"
                                                type='submit'
                                            >
                                                Subir respuesta
                                            </Button>
                                        </Flex>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>

    )
}
