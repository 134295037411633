import { Flex, useColorMode, useDisclosure } from '@chakra-ui/react'
import ChatSelector from '../components/ChatSelector'
import { Topbar } from '../../../shared/components/Topbar/Topbar'
import ChatConversation from '../components/ChatConversation'
import { useData } from '../../../shared/hooks/useData'
import { EndpointTypes } from '../../../shared/utils/types/EndpointTypes'
import { Route, Routes, useParams } from 'react-router-dom'
import { CardSinInformacion, TypeCardSinInformacion } from '../../../shared/components/Cards/CardSinInformacion'
import { NewChatModal } from '../components/Modales/NewChat'

export default function Mensajeria() {
    const params = useParams();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { colorMode } = useColorMode()

    const { data: conversaciones, Refresh } = useData({
        endpoint: EndpointTypes.MENSAJERIA_CONVERSACION,
        query: {
            sortBy: "updatedAt",
            order: "desc",
            limit: 100
        }
    })

    return (
        <Topbar
            title="Mensajería"
            searchBar
        >
            <Flex
                w="100%"
                h="full"
                rounded="12px"
                px="30px"
                py="20px"
            >
                <ChatSelector
                    conversaciones={conversaciones}
                    onOpen={onOpen}
                />

                {!params["*"] &&
                    <Flex
                        border="1px solid"
                        borderColor={colorMode === "dark" ? "border_variant" : "border"}
                        borderLeft="none"
                        roundedRight="20px"
                        w="full"
                    >
                        <CardSinInformacion
                            type={TypeCardSinInformacion.MENSAJES}
                        />
                    </Flex>
                }

                <Routes>
                    <Route
                        path='/:id'
                        element={
                            <ChatConversation />
                        }
                    />
                </Routes>

                <NewChatModal
                    isOpen={isOpen}
                    onClose={onClose}
                    Refresh={Refresh}
                />

            </Flex>
        </Topbar>
    )
}
