import { Box, Flex, Icon, Text, useColorMode } from "@chakra-ui/react"
import { HiOutlineClipboard } from "react-icons/hi"
import { LeccionInt } from "../../../../interfaces/CursosInt"
import { ComponenteLeccionEnum, PreviewTipoEnum } from "../../../../shared/utils/types/LeccionTipoEnum";
import { Dispatch, SetStateAction } from "react";
import { PDFViewer } from "../PreviewItems/PDFViewer";
import { Video } from "../PreviewItems/Video";
import { IframeViewer } from "../PreviewItems/IframeViewer";
import { ImageViewer } from "../PreviewItems/ImageViewer";
import { MarkdownViewer } from "../PreviewItems/Markdown";
import { EntregarFile } from "./components/Entrega";
import { TipoEntregaEnum } from "../../../../shared/utils/types/EntregableEstadoEnum";

interface Props {
    leccion: LeccionInt | undefined;
    endPrev: boolean;
    realizarEntrega: (action: { file: File | null, setFile: Dispatch<SetStateAction<File | null>>, value: string, setValue: Dispatch<SetStateAction<string>> }) => void;
    componentSubtype: ComponenteLeccionEnum | undefined;
    loading: boolean;
}

export const EnunciadoItem = ({
    leccion,
    endPrev,
    realizarEntrega,
    componentSubtype,
    loading
}: Props) => {
    const { colorMode } = useColorMode();

    return (
        <Flex
            p="40px"
            border="1px solid"
            borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
            direction="column"
            rounded="20px"
            gap="25px"
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            justifyContent="center"
        >
            <Flex
                direction="column"
                mb="6px"
            >
                <Text
                    color="font"
                    fontSize="24px"
                    fontStyle="normal"
                    fontWeight="700"
                >
                    {leccion?.nombre}
                </Text>

                <Flex
                    gap="10px"
                    alignItems="center"
                >
                    <Icon
                        as={HiOutlineClipboard}
                        color="#A5A8B3"
                        boxSize="20px"
                    />

                    <Text
                        color="#A5A8B3"
                        fontSize="15px"
                        fontWeight="400"
                        lineHeight="160%"
                        letterSpacing="0.075px"
                    >
                        Entregable
                    </Text>
                </Flex>
            </Flex>

            <Flex
                w="100%"
                gap="20px"
                direction="column"
            >
                <Box h="1px" bg="#E6E6EA" w="100%" />

                <Text
                    color="font"
                    fontSize="14px"
                    fontWeight="400"
                >
                    Estado:

                    <Text
                        py="4px"
                        px="15px"
                        rounded="8px"
                        bg={colorMode === "dark" ? "main" : "#F0F0F3"}
                        as="span"
                        ml="10px"
                        color={colorMode === "dark" ? "purewhite" : "#515561"}
                        fontSize="16px"
                        fontWeight="600"
                    >
                        Pendiente de Entrega
                    </Text>
                </Text>

                <Box h="1px" bg="#E6E6EA" w="100%" />
            </Flex>

            {leccion?.contenido &&
                <>
                    <Flex
                        w="100%"
                    >
                        {(componentSubtype === ComponenteLeccionEnum.PDF_VIEWER && leccion?.contenido) &&
                            <PDFViewer
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }

                        {(componentSubtype === ComponenteLeccionEnum.VIDEO && leccion?.contenido) &&
                            <Video
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }

                        {(componentSubtype === ComponenteLeccionEnum.IFRAME && leccion?.contenido) &&
                            <IframeViewer
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }

                        {(componentSubtype === ComponenteLeccionEnum.IMAGEN && leccion?.contenido) &&
                            <ImageViewer
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }

                        {(componentSubtype === ComponenteLeccionEnum.MARKDOWN_PREVIEW && leccion?.contenido) &&
                            <MarkdownViewer
                                leccion={leccion}
                                tipo={PreviewTipoEnum.ENTREGA}
                            />
                        }
                    </Flex>

                    <Box h="1px" bg="#E6E6EA" w="100%" />
                </>
            }

            <EntregarFile
                realizarEntrega={realizarEntrega}
                endPrev={endPrev}
                tipo={TipoEntregaEnum.ENUNCIADO}
                leccion={leccion}
                loading={loading}
            />
        </Flex>
    )
}