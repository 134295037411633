import { Box, Flex, Icon, Spinner, Text, useColorMode, useDisclosure } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { BsArrowLeftSquareFill, BsArrowRightSquareFill, BsArrowsFullscreen, BsFillDashCircleFill, BsFillPlusCircleFill } from "react-icons/bs";
import { Document, Page } from 'react-pdf';
import { useOutWindowContext } from "../../../../shared/context/outWindow.context";
import { LeccionInt } from "../../../../interfaces/CursosInt";
import { HiOutlinePaperClip } from "react-icons/hi";
import { capitalizeFirst } from "../../../../shared/utils/functions/textTransform";
import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { PreviewTipoEnum } from "../../../../shared/utils/types/LeccionTipoEnum";

interface Props {
    leccion: LeccionInt | undefined;
    tipo?: PreviewTipoEnum;
}

export const PDFViewer = ({
    leccion,
    tipo = PreviewTipoEnum.TEORIA
}: Props) => {
    const { colorMode } = useColorMode();
    const { outWindow } = useOutWindowContext();
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [scale, setScale] = useState<number>(0.82);
    const { onOpen: onOpenFullScreen, onClose: onCloseFullScreen, isOpen: isOpenFullScreen } = useDisclosure();

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    const exitFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    };

    useEffect(() => {
        if (outWindow)
            exitFullScreen();
    }, [outWindow])

    return (
        <Flex
            p={tipo === PreviewTipoEnum.TEORIA ? "40px" : "0"}
            border={tipo === PreviewTipoEnum.TEORIA ? "1px solid" : "none"}
            borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
            direction="column"
            rounded="20px"
            gap="40px"
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            justifyContent="center"
            w="100%"
        >
            <Flex
                direction="column"
                display={tipo === PreviewTipoEnum.TEORIA ? "flex" : "none"}
            >
                <Text
                    color="font"
                    fontSize="24px"
                    fontStyle="normal"
                    fontWeight="700"
                >
                    {leccion?.nombre}
                </Text>

                <Flex
                    gap="10px"
                    alignItems="center"
                >
                    <Icon
                        as={HiOutlinePaperClip}
                        color="#A5A8B3"
                        boxSize="20px"
                    />

                    <Text
                        color="#A5A8B3"
                        fontSize="15px"
                        fontWeight="400"
                        lineHeight="160%"
                        letterSpacing="0.075px"
                    >
                        Lección
                    </Text>
                </Flex>
            </Flex>

            <Flex
                w={"100%"}
                h={"100%"}
                pos={"relative"}
            >
                <Flex
                    overflow="auto"
                    w={"100%"}
                    h={"100%"}
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        'scrollbarWidth': 'none',
                        '-ms-overflow-style': 'none',
                        scrollBehavior: 'smooth',
                    }}
                >
                    <Flex
                        w={"calc(100vw - 615px)"}
                        h={"calc(100vh - 375.5px)"}
                    >
                        <Document
                            file={leccion?.contenido}
                            onLoadSuccess={onDocumentLoadSuccess}
                            loading={
                                <Spinner
                                    color="font"
                                    size="xl"
                                />
                            }
                        >
                            <Page
                                pageNumber={pageNumber}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                loading={<Box h="800px" w="calc(100vw - 750px)" />}
                                scale={scale}
                            />
                        </Document>
                    </Flex>

                    <Flex
                        pos={"absolute"}
                        left="0"
                        bottom="0"
                        bg="#444444"
                        w="100%"
                        justifyContent="space-between"
                        py="5px"
                        px="10px"
                        rounded="4px"
                    >
                        <Flex
                            alignItems="center"
                            gap="5px"
                        >
                            <Icon
                                as={BsFillDashCircleFill}
                                boxSize="22px"
                                color="purewhite"
                                cursor={scale >= 0.7 ? "pointer" : "not-allowed"}
                                _active={scale >= 0.7 ? { transform: "scale(0.9)" } : {}}
                                opacity={scale >= 0.7 ? 1 : 0.7}
                                onClick={() => {
                                    if (scale >= 0.7)
                                        setScale(scale - 0.05)
                                }}
                            />

                            <Icon
                                as={BsFillPlusCircleFill}
                                boxSize="22px"
                                color="purewhite"
                                cursor={scale <= 1.62 ? "pointer" : "not-allowed"}
                                _active={scale <= 1.62 ? { transform: "scale(1.1)" } : {}}
                                opacity={scale <= 1.62 ? 1 : 0.7}
                                onClick={() => {
                                    if (scale <= 1.62)
                                        setScale(scale + 0.05)
                                }}
                            />
                        </Flex>

                        <Flex
                            alignItems="center"
                            gap="5px"
                        >
                            <Icon
                                as={BsArrowsFullscreen}
                                boxSize="20px"
                                mr="10px"
                                color="purewhite"
                                cursor="pointer"
                                onClick={(e: React.MouseEvent) => {
                                    e.stopPropagation();

                                    onOpenFullScreen();
                                }}
                            />
                            <Icon
                                as={BsArrowLeftSquareFill}
                                boxSize="22px"
                                color="purewhite"
                                cursor={pageNumber === 1 ? "not-allowed" : "pointer"}
                                _active={pageNumber === 1 ? {} : { transform: "scale(0.9)" }}
                                opacity={pageNumber === 1 ? 0.7 : 1}
                                onClick={() => {
                                    if (pageNumber > 1) setPageNumber(pageNumber - 1)
                                }}
                            />

                            <Icon
                                as={BsArrowRightSquareFill}
                                boxSize="22px"
                                color="purewhite"
                                cursor={pageNumber === numPages ? "not-allowed" : "pointer"}
                                _active={pageNumber === numPages ? {} : { transform: "scale(1.1)" }}
                                opacity={pageNumber === numPages ? 0.7 : 1}
                                onClick={() => {
                                    if (pageNumber < numPages!) setPageNumber(pageNumber + 1)
                                }}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <ModalFullScreen
                isOpen={isOpenFullScreen}
                onClose={onCloseFullScreen}
                leccion={leccion}
            />
        </Flex>
    )
}

interface PropsModal {
    isOpen: boolean;
    onClose: () => void;
    leccion: LeccionInt | undefined;
}

const ModalFullScreen = ({ isOpen, onClose, leccion }: PropsModal) => {
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [scale, setScale] = useState<number>(0.82);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    return (
        <Modal
            size="full"
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />

            <ModalContent>
                <Flex
                    w={"100%"}
                    h={"100%"}
                    pos={"relative"}
                    justifyContent="center"
                >
                    <Flex
                        h={"100vh"}
                        css={{
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            'scrollbarWidth': 'none',
                            '-ms-overflow-style': 'none',
                            scrollBehavior: 'smooth',
                        }}
                        overflow="auto"
                    >
                        <Document
                            file={leccion?.contenido}
                            onLoadSuccess={onDocumentLoadSuccess}
                            loading={
                                <Spinner
                                    color="font"
                                    size="xl"
                                />
                            }
                        >
                            <Page
                                pageNumber={pageNumber}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                loading={<Box h="800px" w="calc(100vw - 750px)" />}
                                scale={scale}
                            />
                        </Document>

                        <Flex
                            pos={"absolute"}
                            left="0"
                            bottom="0"
                            bg="#444444"
                            w="100%"
                            justifyContent="space-between"
                            py="5px"
                            px="10px"
                            rounded="4px"
                        >
                            <Flex
                                alignItems="center"
                                gap="5px"
                            >
                                <Icon
                                    as={BsFillDashCircleFill}
                                    boxSize="22px"
                                    color="purewhite"
                                    cursor={scale >= 0.7 ? "pointer" : "not-allowed"}
                                    _active={scale >= 0.7 ? { transform: "scale(0.9)" } : {}}
                                    opacity={scale >= 0.7 ? 1 : 0.7}
                                    onClick={() => {
                                        if (scale >= 0.7)
                                            setScale(scale - 0.05)
                                    }}
                                />

                                <Icon
                                    as={BsFillPlusCircleFill}
                                    boxSize="22px"
                                    color="purewhite"
                                    cursor={scale <= 1.62 ? "pointer" : "not-allowed"}
                                    _active={scale <= 1.62 ? { transform: "scale(1.1)" } : {}}
                                    opacity={scale <= 1.62 ? 1 : 0.7}
                                    onClick={() => {
                                        if (scale <= 1.62)
                                            setScale(scale + 0.05)
                                    }}
                                />
                            </Flex>

                            <Flex
                                alignItems="center"
                                gap="5px"
                            >
                                <Icon
                                    as={BsArrowsFullscreen}
                                    boxSize="20px"
                                    mr="10px"
                                    color="purewhite"
                                    cursor="pointer"
                                    onClick={(e: React.MouseEvent) => {
                                        e.stopPropagation();

                                        onClose();
                                    }}
                                />

                                <Icon
                                    as={BsArrowLeftSquareFill}
                                    boxSize="22px"
                                    color="purewhite"
                                    cursor={pageNumber === 1 ? "not-allowed" : "pointer"}
                                    _active={pageNumber === 1 ? {} : { transform: "scale(0.9)" }}
                                    opacity={pageNumber === 1 ? 0.7 : 1}
                                    onClick={() => {
                                        if (pageNumber > 1) setPageNumber(pageNumber - 1)
                                    }}
                                />

                                <Icon
                                    as={BsArrowRightSquareFill}
                                    boxSize="22px"
                                    color="purewhite"
                                    cursor={pageNumber === numPages ? "not-allowed" : "pointer"}
                                    _active={pageNumber === numPages ? {} : { transform: "scale(1.1)" }}
                                    opacity={pageNumber === numPages ? 0.7 : 1}
                                    onClick={() => {
                                        if (pageNumber < numPages!) setPageNumber(pageNumber + 1)
                                    }}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </ModalContent>
        </Modal>
    )
}