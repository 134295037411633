import { Topbar } from '../../../../shared/components/Topbar/Topbar'
import { Flex, useDisclosure } from '@chakra-ui/react'
import { useData } from '../../../../shared/hooks/useData'
import { EndpointTypes } from '../../../../shared/utils/types/EndpointTypes'
import { CorreccionesColumns } from '../../components/CorreccionesColumns'
import { Table } from '../../../../shared/components/Table/Table'
import { TypeCardSinInformacion } from '../../../../shared/components/Cards/CardSinInformacion'
import { CorreccionDrawer } from '../../components/CorreccionDrawer'
import { useState } from 'react'
import { Filter } from '../../components/Filter'

export default function Correcciones() {
  const [entregableId, setEntregableId] = useState<string>()
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [query, setQuery] = useState<{
    estado?: string;
    alumno?: string;
    grupo?: string;
    curso?: string;
  }>({
  })

  const { data: entregables, loading, Refresh } = useData({
    endpoint: EndpointTypes.ENTREGABLES,
    query: {
      ...query,
      sortBy: "fechaEntrega",
      order: "desc",
    }
  })

  const { data: grupos } = useData({
    endpoint: EndpointTypes.GRUPOS,
    query: {
      limit: 20
    }
  })

  const onRowClick = (data: any) => {
    setEntregableId(data?.id)

    onOpen()
  }

  return (
    <Topbar
      title='Correcciones'
      searchBar
    >
      <Flex
        px="50px"
        py="20px"
        w="100%"
        direction="column"
        gap="20px"
      >

        <Filter
          query={query}
          setQuery={setQuery}
          data={entregables?.data || []}
          grupos={grupos?.data}
        />

        <Table
          columns={CorreccionesColumns({ onRowClick })}
          query={query}
          setQuery={setQuery}
          data={entregables?.data}
          loading={loading}
          total={entregables?.meta?.total || 0}
          typeSinInformacion={TypeCardSinInformacion.ENTREGABLES}

        />

        <CorreccionDrawer
          isOpen={isOpen}
          onClose={onClose}
          id={entregableId}
          setRefresh={Refresh}
        />
      </Flex>
    </Topbar>
  )
}
