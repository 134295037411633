import { Flex, Icon, Text, useColorMode, useDisclosure } from "@chakra-ui/react"
import { BiFile } from "react-icons/bi"
import { BsDownload } from "react-icons/bs"
import { format } from "../../../../../shared/utils/functions/formatDate"
import { EntregablesInt } from "../../../../../interfaces/Entregables"
import { HistorialEntregas } from "./HistorialEntregas"

interface Props {
    entregable: EntregablesInt | null;
    entregables: EntregablesInt[];
}

export const EntregaPendiente = ({
    entregable,
    entregables
}: Props) => {
    const { isOpen, onClose, onOpen } = useDisclosure()
    const { colorMode } = useColorMode();

    return (
        <Flex
            direction="column"
        >
            <Flex
                direction="column"
                w="100%"
                gap="25px"
            >
                <Flex
                    direction="column"
                    fontSize="13px"
                    fontWeight="400"
                    color="font"
                    gap="10px"
                >
                    <Text
                        fontSize="18px"
                        fontWeight="700"
                        color="font"
                    >
                        Entrega realizada:
                    </Text>

                    <Text>
                        Has completado la entrega de este ejercicio. A continuación, puedes descargar y visualizar el archivo que has entregado, la fecha de entrega se muestra debajo del mismo.
                    </Text>

                    <Text>
                        Tu ejercicio será revisado por el tutor correspondiente. Te notificaremos cuando la corrección esté disponible.
                    </Text>
                </Flex>

                <Flex
                    direction="column"
                    p="15px"
                    bg={colorMode === "dark" ? "bg_dark_light" : "#F8F8F9"}
                    rounded="15px"
                >
                    <Flex
                        w="100%"
                        rounded="15px"
                        position="relative"
                        direction="column"
                        gap="10px"
                    >
                        <Flex
                            h="80px"
                            p="20px"
                            border="1px solid"
                            borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                            rounded="10px"
                            justifyContent="space-between"
                        >
                            <Flex
                                alignItems="center"
                                gap="10px"
                            >
                                <Icon
                                    as={BiFile}
                                    boxSize="24px"
                                    color={colorMode === "dark" ? "purewhite" : "secondary"}
                                />

                                <Flex
                                    direction="column"
                                    gap="4px"
                                >
                                    <Text
                                        fontSize="14px"
                                        fontWeight="600"
                                        lineHeight="140%"
                                    >
                                        {entregable?.adjunto?.name}
                                    </Text>

                                    <Text
                                        color="#8C909C"
                                        fontSize="13px"
                                        fontWeight="500"
                                        lineHeight="16px"
                                        letterSpacing={"-0.78px"}
                                    >
                                        {entregable?.adjunto?.size} KB
                                    </Text>
                                </Flex>
                            </Flex>

                            <Flex
                                p="6px"
                                justifyContent="center"
                                alignItems="center"
                                w="fit-content"
                                h="fit-content"
                                bg="purewhite"
                                rounded="8px"
                                cursor="pointer"
                                _active={{ transform: "scale(0.9)" }}

                                onClick={(e: React.MouseEvent) => {
                                    e.stopPropagation()

                                    const url = entregable?.adjunto?.url;

                                    if (url) {
                                        const link = document.createElement('a');
                                        link.href = (url?.includes(".txt") || url?.includes(".json")) ? `data:text/html,${url}` : url
                                        link.download = entregable.adjunto.name || 'archivo.txt';
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    }
                                }}
                            >
                                <Icon
                                    as={BsDownload}
                                    color="#8C909C"
                                    boxSize="24px"
                                />
                            </Flex>
                        </Flex>

                        <Flex
                            direction="column"
                        >
                            <Text
                                fontSize="12px"
                                fontWeight="400"
                                lineHeight="160%"
                                color="#8C909C"
                            >
                                Fecha de entrega: <Text as="span" fontWeight="700" color="font">{format({ date: entregable?.fechaEntrega, customFormat: "dd/MM/yyyy" })}</Text>
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex
                mt="25px"
                justifyContent="end"
                display={(entregables && entregables?.length > 0) ? "flex" : "none"}
            >
                <Text
                    w="fit-content"
                    rounded="8px"
                    py="5px"
                    px="15px"
                    bg="main"
                    fontSize="13px"
                    fontWeight="400"
                    color="purewhite"
                    cursor="pointer"
                    onClick={onOpen}
                >
                    Ver historial de entregas
                </Text>
            </Flex>

            <HistorialEntregas
                entregables={entregables}
                isOpen={isOpen}
                onClose={onClose}
            />
        </Flex>
    )
}
