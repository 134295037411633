import { Flex, Button, Icon, Text, useToast, Tooltip, useColorMode } from "@chakra-ui/react"
import { FormInput } from "@imaginagroup/bit-components.ui.form-input"
import { BiCloudUpload, BiTrash } from "react-icons/bi"
import { useAuthContext } from "../../../../shared/context/user.context"
import { InputItem } from "../Index/Index"
import { removeAvatarUser, uploadAvatarUser } from "../../../../shared/middlewares/users.middleware"
import { toastNotify } from "../../../../shared/utils/functions/toastNotify"
import { StatusEnumTypes } from "../../../../shared/utils/types/StatusEnumTypes"
import { AvatarUploader } from "../../../../shared/components/FormElements/AvatarUploader"
import { useEffect, useState } from "react"

interface Props {
    inputsForm: InputItem[];
}

export const Cuenta = ({
    inputsForm
}: Props) => {
    const { colorMode } = useColorMode()
    const { user, refreshUser } = useAuthContext();
    const toast = useToast();
    const [file, setFile] = useState<File[] | null>(null)
    const [isUploading, setIsUploading] = useState<boolean>(false)

    const handleRemove = async () => {
        if (file && file[0]) setFile(null)
        else
            if (!user?.id) return;
        setIsUploading(true)

        await removeAvatarUser()
            .then(async () => {
                refreshUser({
                    avatar: null
                })
            })
            .catch((err: Error) => console.error({ err }))
            .finally(() => setIsUploading(false))
    };

    const handleUpload = () => {
        if (!user?.id) return
        if (!file) return toastNotify(toast, StatusEnumTypes.ERROR, "Debe cargar una imagen antes de actualizar su perfil.")

        setIsUploading(true)

        uploadAvatarUser(file)
            .then((response: any) => {
                refreshUser({
                    avatar: {
                        url: response?.data?.data?.avatar?.url
                    }
                });

                setFile(null)
                toastNotify(toast, StatusEnumTypes.SUCCESS, "¡Avatar subido correctamente!")
            })
            .catch((error) => {
                console.log(error)
                toastNotify(toast, StatusEnumTypes.ERROR, "Error al subir el avatar, vuelva a intentarlo")
            })
            .finally(() => setIsUploading(false))

    };

    return (
        <Flex
            direction="column"
            gap="24px"
        >
            <Text
                color="font"
                fontSize="18px"
                fontWeight="700"
            >
                Cuenta
            </Text>

            <Flex
                alignItems="center"
                gap="20px"
            >
                <AvatarUploader
                    name={`${user?.nombre} ${user?.apellidos}`}
                    src={(file && file.length > 0 ? file[0] : user?.avatar?.url) || ""}
                    onUpload={setFile}
                />

                <Tooltip
                    label="Primero debes cargar tu imagen"
                    hasArrow
                    placement="bottom"
                    isDisabled={(file && file?.length > 0) ? true : false}
                    rounded="6px"
                    color="purewhite"
                    fontSize="14px"
                    fontWeight="400px"
                    px="10px"
                    py="5px"
                    bg="pureblack"
                >
                    <Button
                        py="10px"
                        px="16px"
                        h="fit-content"
                        w="fit-content"
                        rounded="10px"
                        bg={colorMode === "dark" ? "main" : "secondary"}
                        color="purewhite"
                        fontSize="16px"
                        fontWeight="700"
                        lineHeight="22px"
                        letterSpacing="-0.408px"
                        isLoading={isUploading}
                        isDisabled={!file || file?.length === 0}
                        rightIcon={
                            <Icon as={BiCloudUpload} boxSize="24px" />
                        }
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation()

                            handleUpload()
                        }}
                    >
                        Subir foto nueva
                    </Button>
                </Tooltip>

                <Button
                    py="9px"
                    px="32px"
                    h="fit-content"
                    w="fit-content"
                    rounded="10px"
                    bg={colorMode === "dark" ? "transparent" : "purewhite"}
                    border="1px solid"
                    borderColor={colorMode === "dark" ? "purewhite" : "#A5A8B3"}
                    isDisabled={!user?.avatar?.url && (!file || file?.length === 0)}
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()

                        handleRemove();
                    }}
                >
                    <Icon
                        as={BiTrash}
                        boxSize="24px"
                    />
                </Button>
            </Flex>

            {inputsForm?.map((i: InputItem) => (
                <FormInput
                    name={i?.name}
                    label={i?.label}
                    isBlockError
                    ui={{
                        styleLabel: {
                            fontSize: "14px",
                            fontWeight: "700",
                            color: "font",
                            letterSpacing: "-0.14px"
                        },
                        styleInput: {
                            border: colorMode === "dark" ? "1px solid #576776" : "1px solid #E6E6EA",
                            background: colorMode === "dark" ? "bg_dark_light" : "purewhite",
                            color: colorMode === "dark" ? "purewhite" : "font",
                            rounded: "8px",
                            px: "15px",
                            py: "12px"
                        }
                    }}
                />
            ))}
        </Flex>
    )
}