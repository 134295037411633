const filterSelectStyles = {
    container: (styles: any, { isDisabled }: any) => ({
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',
        zIndex: "999"
    }),
    singleValue: (styles: any) => ({
        ...styles,
        color: '#000',
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        letterSpacing: "-0.16px",
    }),
    indicatorSeparator: (styles: any) => ({ display: 'none' }),
    control: (styles: any) => ({
        ...styles,
        borderRadius: '7px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        border: "1px solid #E6E6EA",
        padding: "1px 0",
        zIndex: "999"
    }),
    option: (styles: any, { isFocused }: any) => ({
        ...styles,
        cursor: 'pointer',
        textAlign: 'left',
        fontWeight: 'medium',
        color: '#000',
        backgroundColor: isFocused ? '#CCC' : '#FFF',
        zIndex: "999"
    }),
    placeholder: (styles: any) => ({
        ...styles,
        color: "dark_grey",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        letterSpacing: "-0.16px",
    }),
};

const filterSelectStylesDark = {
    container: (styles: any, { isDisabled }: any) => ({
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',
        zIndex: "999"
    }),
    singleValue: (styles: any) => ({
        ...styles,
        color: '#F8F8F8',
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        letterSpacing: "-0.16px",
    }),
    indicatorSeparator: (styles: any) => ({ display: 'none' }),
    menu: (styles: any) => ({
        ...styles,
        backgroundColor: '#191D23',
        zIndex: "999"
    }),
    control: (styles: any) => ({
        ...styles,
        borderRadius: '7px',
        backgroundColor: '#191D23',
        border: "1px solid #576776",
        padding: "1px 0",
        zIndex: "999"
    }),
    option: (styles: any, { isFocused }: any) => ({
        ...styles,
        cursor: 'pointer',
        textAlign: 'left',
        fontWeight: 'medium',
        color: '#F8F8F8',
        backgroundColor: isFocused ? '#262C36' : '#191D23',
        zIndex: "999"
    }),
    placeholder: (styles: any) => ({
        ...styles,
        color: "#8C909C",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        letterSpacing: "-0.16px",
    }),
};

export { filterSelectStyles, filterSelectStylesDark };