import { Flex, Image, Text, useColorMode } from "@chakra-ui/react"
import cardContadorFinalizado from "../../../../assets/img/noData1.png"
import cardContadorFinalizadoDark from "../../../../assets/img/noData-Dark1.png"

export const CardContadorSinMatricula = () => {
    const { colorMode } = useColorMode()

    return (
        <Flex
            p="20px"
            border="1px solid"
            borderColor={colorMode === "dark" ? "border_variant" : "#DFE1E6"}
            rounded="16px"
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            gap="24px"
            direction="column"
            alignItems="center"
            justifyContent="center"
            h="356px"
        >
            <Text
                color="font"
                fontSize="21px"
                fontWeight="600"
                textAlign="center"
            >
                ¡Aún no tienes matrículas asignadas!
            </Text>

            <Image
                src={colorMode === "dark" ? cardContadorFinalizadoDark : cardContadorFinalizado}
                w="160px"
                h="175px"
            />
        </Flex>
    )
}