import { Flex, Text, useColorMode } from "@chakra-ui/react";

export const CardSinFecha = () => {
    const { colorMode } = useColorMode();

    return (
        <Flex
            p="20px"
            border="1px solid"
            borderColor={colorMode === "dark" ? "border_variant" : "#DFE1E6"}
            rounded="16px"
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            gap="20px"
            direction="column"
            justify="center"
            alignItems="center"
            h="356px"
        >
            <Text
                color="font_grey"
                fontSize="22px"
                fontWeight="500"
                textAlign="center"
            >
                El curso no tiene fecha de finalización
            </Text>
        </Flex>

    )
}