import { Flex, Text, useColorMode } from "@chakra-ui/react"

export const CardActividadSinMatricula = () => {
    const { colorMode } = useColorMode()

    return (
        <Flex
            rounded="16px"
            bg={colorMode === "dark" ? "bg_dark_light" : "#F4F0FF"}
            px="24px"
            py="24px"
            direction="column"
            alignItems="start"
            justifyContent="space-between"
            h="190px"
            overflow="auto"
            position="relative"
            border="1px solid"
            borderColor={colorMode === "dark" ? "border_variant" : "border"}
        >
            <Text
                color="font"
                fontSize="12px"
                fontWeight="400"
                textTransform="uppercase"
                zIndex="99"
            >
                Anuncio
            </Text>

            <Flex
                direction="column"
                alignItems="start"
                gap="8px"
                zIndex="99"
            >
                <Text
                    color="font"
                    fontSize="24px"
                    fontWeight={"600"}
                >
                    Próximamente
                </Text>

                <Text
                    color="font"
                    fontSize="12px"
                    fontWeight="400">
                    Aún no has sido matriculado, pero pronto comenzará tu aprendizaje. Le recomendamos estar atento a futuras comunicaciones sobre el inicio del curso.
                </Text>
            </Flex>
        </Flex>
    )
}