import { Flex, useToast } from "@chakra-ui/react"
import { Route, Routes, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useProgressContext } from "../../../../shared/context/progress.context";
import { useDataId } from "../../../../shared/hooks/useDataId";
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes";
import { HeaderLeccion } from "../../components/HeaderLeccion";
import { AccordionContenido } from "../../components/AccordionContenido";
import { LeccionesSlug } from "../Slug/LeccionesSlug";
import { useData } from "../../../../shared/hooks/useData";
import { useAuthContext } from "../../../../shared/context/user.context";
import { UserRolEnum } from "../../../../shared/utils/types/UserRolEnum";
import { MatriculasInt } from "../../../../interfaces/MatriculasInt";
import { useEffect, useState } from "react";
import { EstadoTypeEnum } from "../../../../shared/utils/types/EstadoTypeEnum";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/types/StatusEnumTypes";
import { sortByOrder } from "../../../../shared/utils/functions/sortBy";
import { LeccionInt, ModulosInt } from "../../../../interfaces/CursosInt";

export const Lecciones = () => {
    const params = useParams()
    const { user } = useAuthContext();
    const { cursoSlug } = useParams();
    const { cursoProgress, setCursoProgress, refreshProgress } = useProgressContext();
    const toast = useToast()
    const navigate = useNavigate()
    const [search] = useSearchParams()
    const [matriculaActual, setMatriculaActual] = useState<MatriculasInt>();

    const { data: matriculas } = useData({
        endpoint: EndpointTypes.MATRICULAS_ALUMNOS,
        ignoreRequest: !user?.auth || user?.role?.nombre !== UserRolEnum.ALUMNO,
        query: {
            limit: 100
        }
    });

    const { data: dataCurso, loading, error: errorCurso, status: statusCurso } = useDataId({
        endpoint: EndpointTypes.CURSOS,
        id: cursoSlug,
    })

    useEffect(() => {
        if (errorCurso) {
            if (statusCurso === 403) {
                navigate("/aprendizaje")

                toastNotify(toast, StatusEnumTypes.ERROR, "No tienes acceso a este curso")
            }
        }
    }, [errorCurso])

    useEffect(() => {
        if (!params["*"]) {
            const modulos: ModulosInt[] = sortByOrder(dataCurso?.modulos)
            const lecciones: LeccionInt[] = (modulos && modulos?.length !== 0) ? sortByOrder(modulos[0]?.lecciones) : []

            if (lecciones && lecciones?.length !== 0) {
                const leccion: LeccionInt = lecciones[0]
                navigate(`${leccion?.slug}?grupo=${search.get("grupo")}`)
            }
        }
    }, [dataCurso])

    useEffect(() => {
        if (
            !search.has("grupo") ||
            !matriculas?.data ||
            user?.role?.nombre === UserRolEnum.TUTOR
        ) return;

        const grupoSlug = search.get("grupo");
        const matriculaActual = matriculas.data.find(
            (matricula: MatriculasInt) => matricula?.grupo?.slug === grupoSlug
        );

        if (!matriculaActual || matriculaActual.estado === EstadoTypeEnum.INACTIVO) {
            navigateAndNotify();
            return;
        }

        setMatriculaActual(matriculaActual);

    }, [search, matriculas])

    useEffect(() => {
        if (
            !matriculaActual ||
            !dataCurso ||
            user?.role?.nombre === UserRolEnum.TUTOR
        ) return;

        setCursoProgress((prev: any) => ({
            ...prev,
            matriculaId: matriculaActual?.id,
            cursoId: dataCurso?.id,
            cursoSlug: cursoSlug,
            cursoNombre: dataCurso?.nombre,
            totalLecciones: dataCurso?.meta?.totalLecciones,
        }))

        refreshProgress();

    }, [matriculaActual, dataCurso])

    const navigateAndNotify = () => {
        navigate(`/aprendizaje`);
        toastNotify(toast, StatusEnumTypes.ERROR, 'La matrícula no está activa.');
    }

    return (
        <Flex
            alignItems="start"
        >
            <AccordionContenido
                curso={dataCurso}
            />

            <Flex
                w="100%"
                direction="column"
            >
                <HeaderLeccion
                    curso={dataCurso}
                    progresoCurso={cursoProgress?.porcentajeCurso || 0}
                    loading={loading}
                />

                <Routes>
                    <Route
                        path="/:slug"
                        element={
                            <LeccionesSlug
                                curso={dataCurso}
                                matriculaActual={matriculaActual}
                            />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
}