import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import * as Sentry from "@sentry/react";
import { ColorModeScript } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import "./ui/styles/index.css"
import * as Ably from 'ably';
import { AblyProvider } from 'ably/react';

const client = new Ably.Realtime({ key: import.meta.env.VITE_ABLY_API_KEY });

if (import.meta.env.VITE_SENTRY_APIKEY)
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_APIKEY,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [
          /^https:\/\/campus\.imaginaformacion\.com/,
          /^https:\/\/[\w-]+\.proacademy\.app/
        ],
        maskAllText: false,
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^https:\/\/campus\.imaginaformacion\.com/,
      /^https:\/\/[\w-]+\.proacademy\.app/
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

const queryClient = new QueryClient({})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AblyProvider client={client}>
    <QueryClientProvider client={queryClient}>
      <ColorModeScript />
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </QueryClientProvider>
  </AblyProvider>
)
