import { Badge, Flex, Icon, Text, useColorMode } from '@chakra-ui/react'
import { DateTime } from 'luxon';
import { CustomColumnProps } from '../../../shared/components/Table/Table';
import { EntregablesInt } from '../../../interfaces/Entregables';
import { EntregableEstadoEnum } from '../../../shared/utils/types/EntregableEstadoEnum';
import { BiEdit, BiHourglass } from 'react-icons/bi';
import { RiCheckFill, RiCloseFill } from 'react-icons/ri';

interface Props {
    onRowClick?: (row: any) => void;
}

export const CorreccionesColumns = ({ onRowClick }: Props): CustomColumnProps[] => {
    const { colorMode } = useColorMode();

    const columns: CustomColumnProps[] = [
        {
            header: 'Estado de Entrega',
            field: 'estado',
            key: 'estadoEntrega',
            sortable: true,
            body: (rowData: EntregablesInt) => (
                <Flex
                >
                    <Flex
                        w="100px"
                        align="center"
                        p="4px"
                        pr="8px"
                        rounded="5px"
                        fontSize="14px"
                        fontWeight="500"
                        textTransform="capitalize"
                        border="1px solid"
                        borderColor={colorMode === "dark" ? "border_variant" : "border"}
                        color={colorMode === "dark" ? "purewhite" : "font"}
                        bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                        gap="6px"
                    >
                        <Icon
                            as={rowData?.estado === EntregableEstadoEnum.ERROR ? RiCloseFill
                                : rowData?.estado === EntregableEstadoEnum.CORRECTO ? RiCheckFill
                                    : BiHourglass}
                            color={rowData?.estado === EntregableEstadoEnum.ERROR ? "fail"
                                : rowData?.estado === EntregableEstadoEnum.CORRECTO ? "#14804A"
                                    : "#FF784B"}
                        />
                        {
                            rowData?.estado === EntregableEstadoEnum.ERROR ? 'incorrecta'
                                : rowData?.estado === EntregableEstadoEnum.CORRECTO ? 'correcto'
                                    : "pendiente"
                        }

                    </Flex>
                </Flex>
            ),
        },
        {
            header: 'alumno',
            field: 'alumno',
            key: 'alumno',
            sortable: true,
            body: (rowData: EntregablesInt) => (
                <Flex direction="column">
                    <Text
                        color={colorMode === "dark" ? "purewhite" : "dark_blue"}
                        fontSize="14px"
                        fontWeight="500"
                    >
                        {rowData?.user?.nombre} {rowData?.user?.apellidos}
                    </Text>
                    <Text
                        color="#7A7D81"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        {rowData?.matricula?.grupo?.nombre}
                    </Text>
                </Flex>
            ),
        },
        {
            header: 'Nota',
            field: 'nota',
            key: 'nota',
            body: (rowData: any) => (
                <Flex
                    bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                    color={rowData?.estado === EntregableEstadoEnum.ERROR ? "#FA6868"
                        : rowData?.estado === EntregableEstadoEnum.CORRECTO ? "#14804A"
                            : "font"}

                    fontSize="14px"
                    fontWeight="500"
                    p="5px"
                    ml="10px"
                >
                    {rowData?.correccion?.puntuacion ? rowData?.correccion?.puntuacion : "-"}
                </Flex>
            ),
        },
        {
            header: 'Contenido',
            field: 'contenido',
            key: 'contenido',
            body: (rowData: any) => (
                <Flex direction="column">
                    <Text
                        color={colorMode === "dark" ? "purewhite" : "dark_blue"}
                        fontSize="14px"
                        fontWeight="500"
                    >
                        {rowData?.leccion?.nombre}
                    </Text>
                    <Text
                        color="#7A7D81"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        {rowData?.curso?.nombre}
                    </Text>
                </Flex>
            ),
        },
        {
            header: 'Estado de Grupo',
            field: 'estado',
            key: 'estadoGrupo',
            body: (rowData: any) => (
                <Flex
                    color={rowData?.matricula?.estado == "0" ? "#14804A" : "#FA6868"}
                    bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                    border="1px solid"
                    borderColor={rowData?.matricula?.estado == "0" ? "#14804A" : "#FA6868"}
                    fontSize="14px"
                    fontWeight="500"
                    letterSpacing="0.2px"
                    textTransform="capitalize"
                    rounded="full"
                    p="7px 12px"
                    ml="20px"
                >
                    {rowData?.matricula?.estado == "0" ? "Activo" : "Inactivo"}
                </Flex>
            ),
        },
        {
            header: 'Fecha de entrega',
            field: 'createdAt',
            key: 'createdAt',
            sortable: true,
            body: (rowData: any) => (
                <Flex
                    alignItems="center"
                    gap="5px"
                    color={colorMode === "dark" ? "purewhite" : "dark_blue"}
                    fontSize="14px"
                    fontWeight="400"
                >
                    <Text>{DateTime.fromISO(rowData?.createdAt).toFormat('dd/MM/yyyy HH:mm')}</Text>
                </Flex>
            )
        },
        {
            header: '',
            field: '',
            key: '',
            body: (rowData: any) => (
                <Flex
                    onClick={() => onRowClick && onRowClick(rowData)}
                >
                    <Icon
                        boxSize="22px"
                        as={BiEdit}
                        cursor="pointer"
                        ml="50px"
                    />
                </Flex>
            )
        },
    ]
    return columns
}
