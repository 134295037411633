import { Flex, Grid, Text, useToast, IconButton } from "@chakra-ui/react";
import { MatriculasInt } from "../../../../../interfaces/MatriculasInt";
import { useAuthContext } from "../../../../../shared/context/user.context";
import { EstadoTypeEnum } from "../../../../../shared/utils/types/EstadoTypeEnum";
import { useEffect, useState } from "react";
import { useData } from "../../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../../shared/utils/types/EndpointTypes";
import { SkeletonAprendizajeMatriculas } from "../../../components/skeletons/SkeletonAprendizajeMatriculas";
import { CardSinInformacion, TypeCardSinInformacion } from "../../../../../shared/components/Cards/CardSinInformacion";
import { handleUnauthorized } from "../../../../../shared/utils/functions/handleErrors";
import { useNavigate } from "react-router-dom";
import { UserRolEnum } from "../../../../../shared/utils/types/UserRolEnum";
import CardCursoProximos from "../../../components/Aprendizaje/CardCursoProximo";
import CarruselCursos from "../../../components/Aprendizaje/CarruselCursos";
import NewCardCurso from "../../../components/Aprendizaje/NewCardCurso";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

export const AprendizajeMatriculas = () => {
    const { logout, user } = useAuthContext();
    const navigate = useNavigate()
    const toast = useToast()

    const { data: _matriculas, loading, error, errorsList, status } = useData({
        endpoint: EndpointTypes.MATRICULAS_ALUMNOS,
        ignoreRequest: !user?.auth || user?.role?.nombre !== UserRolEnum.ALUMNO,
        query: {
            limit: 100
        }
    })

    const matriculas = _matriculas?.data?.filter((matricula: MatriculasInt) => matricula?.estado === EstadoTypeEnum.ACTIVO)
    const matriculasProximas = _matriculas?.data?.filter((matricula: MatriculasInt) => matricula?.estado === EstadoTypeEnum.PROXIMO)
    const matriculasInactivas = _matriculas?.data?.filter((matricula: MatriculasInt) => matricula?.estado === EstadoTypeEnum.INACTIVO)

    const [activeIndexActivos, setActiveIndexActivos] = useState(0);
    const [activeIndexInactivos, setActiveIndexInactivos] = useState(0);

    const handleNext = (currentIndex: number, setIndex: (index: number) => void, totalItems: number) => {
        if (currentIndex + 3 < totalItems) {
            setIndex(currentIndex + 3);
        }
    };

    const handlePrev = (currentIndex: number, setIndex: (index: number) => void) => {
        if (currentIndex - 3 >= 0) {
            setIndex(currentIndex - 3);
        }
    };

    useEffect(() => {
        if (error) {
            handleUnauthorized({
                logout: logout,
                navigate: navigate,
                toast: toast
            })
        }
    }, [error])

    return (
        loading ?
            <SkeletonAprendizajeMatriculas />
            :

            <Flex
                direction="column"
                w="full"
            >

                {_matriculas?.meta?.total !== 0 ?
                    <Flex
                        gap="40px"
                        direction="column"
                    >

                        {matriculas?.length > 0 &&
                            <CarruselCursos
                                matriculas={matriculas}
                            />
                        }

                        <Flex
                            direction="column"
                            gap="40px"
                        >
                            {matriculas?.length > 0 &&
                                <Flex direction="column" w="full" gap="20px">
                                    <Flex justify="space-between" align="center">
                                        <Text fontSize="18px" fontWeight="700" color="font">
                                            Cursos Activos
                                        </Text>

                                        <Flex gap="10px">
                                            <IconButton
                                                aria-label="Previous"
                                                icon={<ChevronLeftIcon />}
                                                display={activeIndexActivos === 0 ? 'none' : 'flex'}
                                                onClick={() => handlePrev(activeIndexActivos, setActiveIndexActivos)}
                                            />
                                            <IconButton
                                                aria-label="Next"
                                                icon={<ChevronRightIcon />}
                                                display={activeIndexActivos + 3 >= matriculas.length ? 'none' : 'flex'}
                                                onClick={() => handleNext(activeIndexActivos, setActiveIndexActivos, matriculas.length)}
                                            />
                                        </Flex>
                                    </Flex>

                                    <Grid templateColumns="repeat(3, 1fr)" gap="20px" w="full">
                                        {matriculas
                                            .slice(activeIndexActivos, activeIndexActivos + 3)
                                            .map((matricula: MatriculasInt, index: number) => (
                                                <NewCardCurso
                                                    key={index}
                                                    curso={matricula?.grupo?.curso}
                                                    grupoSlug={matricula?.grupo?.slug}
                                                    matricula={matricula}
                                                />
                                            ))}
                                    </Grid>
                                </Flex>
                            }

                            {matriculasInactivas?.length > 0 &&
                                <Flex direction="column" w="full" gap="20px" pb="30px">
                                    <Flex justify="space-between" align="center">
                                        <Text fontSize="18px" fontWeight="700" color="font">
                                            Cursos finalizados
                                        </Text>

                                        <Flex gap="10px">
                                            <IconButton
                                                aria-label="Previous"
                                                icon={<ChevronLeftIcon />}
                                                display={activeIndexInactivos === 0 ? 'none' : 'flex'}
                                                onClick={() => handlePrev(activeIndexInactivos, setActiveIndexInactivos)}
                                            />
                                            <IconButton
                                                aria-label="Next"
                                                icon={<ChevronRightIcon />}
                                                display={activeIndexInactivos + 3 >= matriculasInactivas.length ? 'none' : 'flex'}
                                                onClick={() => handleNext(activeIndexInactivos, setActiveIndexInactivos, matriculasInactivas.length)}
                                            />
                                        </Flex>
                                    </Flex>

                                    <Grid templateColumns="repeat(3, 1fr)" gap="20px" w="full">
                                        {matriculasInactivas
                                            .slice(activeIndexInactivos, activeIndexInactivos + 3)
                                            .map((matricula: MatriculasInt, index: number) => (
                                                <NewCardCurso
                                                    key={index}
                                                    curso={matricula?.grupo?.curso}
                                                    grupoSlug={matricula?.grupo?.slug}
                                                    matricula={matricula}
                                                />
                                            ))}
                                    </Grid>
                                </Flex>
                            }

                            {matriculasProximas?.length > 0 &&
                                <Flex
                                    direction="column"
                                    gap="20px"
                                    mb="30px"
                                >
                                    <Text
                                        fontSize="18px"
                                        fontWeight="700"
                                        color="font"
                                    >
                                        Próximos cursos
                                    </Text>

                                    <Flex
                                        w="100%"
                                        direction="column"
                                        gap="20px"
                                    >
                                        {matriculasProximas?.map((matricula: MatriculasInt, index: number) => (
                                            <CardCursoProximos
                                                key={index}
                                                curso={matricula?.grupo?.curso}
                                                matricula={matricula}
                                            />
                                        ))
                                        }
                                    </Flex>
                                </Flex>
                            }
                        </Flex>
                    </Flex>
                    :
                    <CardSinInformacion type={TypeCardSinInformacion.CURSOS} />
                }
            </Flex>
    );
}