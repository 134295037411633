import { CorreccionInt, UpdateCorreccionInt } from "../../interfaces/Entregables";
import tenantInstance from "../services/tenantInstance.service";
import _axios from "../services/http.service";

export const addCorreccion = async (data: CorreccionInt) => {
    const { tenant, client } = await tenantInstance();
    const form = new FormData()
    const today = new Date().toISOString()

    form.append('adjunto', data?.adjunto)
    form.append('fechaEntrega', today)
    form.append('entregableId', data?.entregableId)
    form.append('texto', data?.texto)
    form.append('puntuacion', data?.puntuacion?.toString())

    return await _axios.post(
        `${tenant}/${client}/correcciones`,
        form,
        {}
    ).then((response: any) => response);
};

export const updateCorreccion = async ({ id, data }: { id: string, data: UpdateCorreccionInt }) => {
    const { tenant, client } = await tenantInstance();

    return await _axios.put(
        `${tenant}/${client}/correcciones/${id}`,
        data,
        {}
    ).then((response: any) => response);
};

export const uploadCorreccionEntregables = async ({ id, adjunto }: { id: string, adjunto: File }) => {
    const { tenant, client } = await tenantInstance();

    const form = new FormData()

    form.append('adjunto', adjunto)

    return await _axios.put(
        `${tenant}/${client}/correcciones/${id}`,
        form,
        {}
    ).then((response: any) => response);
};