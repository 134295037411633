import { Button, Flex, Skeleton, Text, useColorMode } from '@chakra-ui/react'
import { PreguntaForoInt } from '../../../interfaces/ForoInt'
import MarkdownPreview from '@uiw/react-markdown-preview';
import { DateTime } from 'luxon';
import { useRef } from 'react';

interface Props {
    pregunta: PreguntaForoInt;
}

export default function InfoPregunta({ pregunta }: Props) {
    const { colorMode } = useColorMode()
    const bottomRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <Flex
            direction="column"
            gap="24px"
            w="100%"
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            p="32px"
            rounded="10px"
            border="1px solid"
            borderColor={colorMode === "dark" ? "transparent" : "border"}
        >
            <Flex
                direction="column"
                gap="15px"
            >
                {pregunta?.tema?.curso?.nombre ?
                    <Text
                        fontSize="13px"
                        fontWeight="700"
                        color={colorMode === "dark" ? "#FFFFFF" : "main"}
                        bg={colorMode === "dark" ? "main" : "main_variant"}
                        p="6px 12px"
                        rounded="5px"
                        w="fit-content"
                    >
                        {pregunta?.tema?.curso?.nombre}
                    </Text>
                    :
                    <Skeleton
                        w="110px"
                        h="32px"
                        rounded="8px"
                    />
                }


                {pregunta?.titulo ?
                    <Text
                        fontSize="28px"
                        fontWeight="600"
                    >
                        {pregunta?.titulo}
                    </Text>
                    :
                    <Skeleton
                        w="400px"
                        h="42px"
                        rounded="8px"
                    />
                }


                <Text
                    fontSize="14px"
                    fontWeight="600"
                    color="#A5A8B3"
                >
                    Subido por <Text as="span" color="main">{pregunta?.user?.nombreCompleto}</Text> {DateTime.fromISO(pregunta?.createdAt!).toRelative({ locale: 'es' })}
                </Text>
            </Flex>

            <Flex
                w="full"
                bg={colorMode === "dark" ? "border_variant" : "border"}
                h="1px"
            />

            <Flex
                direction="column"
                gap="24px"
            >
                {pregunta?.contenido ?
                    <MarkdownPreview
                        source={pregunta?.contenido}
                        style={{
                            fontSize: "15px",
                            fontWeight: "400",
                            color: colorMode === "dark" ? "#FFFFFF" : "#515561",
                            lineHeight: "140%",
                            width: '100%',
                            maxWidth: '100%',
                            backgroundColor: "transparent"
                        }}
                        wrapperElement={{ "data-color-mode": colorMode === "dark" ? "dark" : "light" }}
                    />
                    :
                    <Skeleton
                        w="full"
                        h="21px"
                        rounded="8px"
                    />
                }

            </Flex>

            <Flex
                w="full"
                bg={colorMode === "dark" ? "border_variant" : "border"}
                h="1px"
            />

            <Button
                bg={colorMode === "dark" ? "tra" : "main"}
                color="purewhite"
                border={colorMode === "dark" ? "1px solid" : "none"}
                borderColor={colorMode === "dark" ? "purewhite" : "none"}
                rounded="10px"
                p="16px"
                w="fit-content"
                onClick={scrollToBottom}
            >
                Responder
            </Button>

            <div ref={bottomRef} />
        </Flex>
    )
}
