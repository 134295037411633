import { Button, Flex, Icon, List, ListItem, Select, Skeleton, Text, useColorMode } from "@chakra-ui/react";
import { BiChevronLeft, BiChevronRight, BiChevronsLeft, BiChevronsRight } from "react-icons/bi";

interface Props {
    total: number;
    limit: number;
    setLimit: (action: any) => void;
    page: number;
    setPage: (action: any) => void;
    loading: boolean;
    lastPage: number;
    firstPage: number;
}
export const Pagination = ({
    total,
    limit,
    setLimit,
    page,
    loading,
    setPage,
    lastPage,
    firstPage

}: Props) => {
    const { colorMode } = useColorMode();

    const count: Array<string | number> = [1];

    const paginate = (page: number, max: number) => {
        if (!page || !max) {
            return null;
        }

        if (page === 1 && max === 1) {
            return { count };
        }
        if (page > 3) {
            count.push('...');
        }

        const dots = 1;
        const firstDot = page - dots;
        const lastDot = page + dots;

        for (let i = firstDot > 2 ? firstDot : 2; i <= Math.min(max, lastDot); i++) {
            count.push(i);
        }

        if (lastDot + 1 < max) {
            count.push('...');
        }

        if (lastDot < max) {
            count.push(max);
        }

        return { count };
    };

    const pageNumbers = paginate(page, lastPage)?.count?.map((item, index) => (
        <Flex
            key={index}
            display="flex"
            onClick={() => {
                item === "..."
                    ? null
                    : setPage(item)
            }}
            color={page === item ? "purewhite" : 'main'}
            bg={page === item ? colorMode === "dark" ? "main" : 'secondary' : 'transparent'}
            justifyContent="center"
            alignItems="center"
            w="30px"
            h="30px"
            rounded="100%"
            fontSize="14px"
            fontWeight="500"
            cursor="pointer"
        >
            {item}
        </Flex>
    ))

    return (
        <Flex
            w="100%"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
        >
            {!loading ?
                <Text
                    fontSize="13px"
                    color="font"
                    textTransform="uppercase"
                >
                    <Text as="strong">{total}</Text> resultados encontrados
                </Text>
                :
                <Skeleton w="200px" h="20px" rounded="4px" />
            }

            <Flex
                alignItems="center"
                gap="10px"
            >
                <Flex
                    alignItems="center"
                    gap="10px"
                >
                    <Button
                        p="0"
                        h="fit-content"
                        w="fit-content"
                        bg="transparent"
                        _hover={{ bg: "transparent" }}
                        _active={{ transform: "scale(0.85)" }}
                        cursor="pointer"
                        onClick={() => setPage(firstPage)}
                        isDisabled={page === firstPage || loading}
                    >
                        <Icon boxSize="22px" color={colorMode === "dark" ? "purewhite" : "secondary"} as={BiChevronsLeft} />
                    </Button>

                    <Button
                        p="0"
                        h="fit-content"
                        w="fit-content"
                        bg="transparent"
                        _hover={{ bg: "transparent" }}
                        _active={{ transform: "scale(0.85)" }}
                        cursor="pointer"
                        onClick={() => setPage((prev: number) => prev - 1)}
                        isDisabled={page === firstPage || loading}
                    >
                        <Icon boxSize="22px" color={colorMode === "dark" ? "purewhite" : "secondary"} as={BiChevronLeft} />
                    </Button>
                </Flex>

                {loading ?
                    <Skeleton w="30px" h="30px" rounded="100%" />
                    :
                    <Flex
                        gap="10px"
                        alignItems="center"
                    >
                        {pageNumbers}
                    </Flex>
                }

                <Flex
                    alignItems="center"
                    gap="10px"
                >
                    <Button
                        p="0"
                        h="fit-content"
                        w="fit-content"
                        bg="transparent"
                        _hover={{ bg: "transparent" }}
                        _active={{ transform: "scale(0.85)" }}
                        cursor="pointer"
                        onClick={() => setPage((prev: number) => prev + 1)}
                        isDisabled={page === lastPage || loading}
                    >
                        <Icon boxSize="22px" color={colorMode === "dark" ? "purewhite" : "secondary"} as={BiChevronRight} />
                    </Button>

                    <Button
                        p="0"
                        h="fit-content"
                        w="fit-content"
                        bg="transparent"
                        _hover={{ bg: "transparent" }}
                        _active={{ transform: "scale(0.85)" }}
                        cursor="pointer"
                        onClick={() => setPage(lastPage)}
                        isDisabled={page === lastPage || loading}
                    >
                        <Icon boxSize="22px" color={colorMode === "dark" ? "purewhite" : "secondary"} as={BiChevronsRight} />
                    </Button>
                </Flex>
            </Flex>

            <Select
                borderColor={colorMode === "dark" ? "border_variant" : "#DFE2E6"}
                color={colorMode === "dark" ? "purewhite" : "secondary"}
                fontSize={"14px"}
                fontWeight={"500"}
                w="fit-content"
                value={limit}
                onChange={(e) => setLimit(parseInt(e.target.value))}
            >
                {[10, 15, 20, 25, 50, 75, 100]?.map((item, index) => (
                    <option
                        key={index}
                        value={item}
                        selected={limit === item}
                    >
                        {item}
                    </option>
                ))}
            </Select>
        </Flex>
    );
}