import { Flex, Text } from '@chakra-ui/react'
import CardRequisito from './CardRequisito'
import { MatriculasInt } from '../../../../interfaces/MatriculasInt'
import { CursosInt } from '../../../../interfaces/CursosInt';

interface Props {
    matriculaActual?: MatriculasInt;
    curso?: CursosInt;
}

export default function RequisitosCurso({ matriculaActual, curso }: Props) {

    return (
        <Flex
            direction="column"
            gap="16px"
            pb="30px"
        >
            <Text
                fontSize="14px"
                fontWeight="700"
                color="font"
            >
                {matriculaActual?.grupo?.fundae ? "Requisitos del curso" : "Progreso del curso"}
            </Text>

            <Flex
                direction="column"
                gap="16px"
            >
                {curso?.tutorizado &&
                    <CardRequisito
                        stat={parseInt((matriculaActual?.puntuacionMedia || 0)?.toFixed(0))}
                        isPuntuacion
                        isFundae={matriculaActual?.grupo?.fundae}
                    />
                }

                <CardRequisito
                    stat={parseInt((matriculaActual?.progresoLecciones || 0)?.toFixed(0))}
                    isActividades
                    isFundae={matriculaActual?.grupo?.fundae}
                />

                {curso?.tutorizado &&
                    <CardRequisito
                        stat={parseInt((matriculaActual?.progresoEntregables || 0)?.toFixed(0))}
                        isEjercicios
                        isFundae={matriculaActual?.grupo?.fundae}
                    />
                }
            </Flex>

            <Flex
                direction="column"
                gap="16px"
                mt="16px"
            >
                <Text
                    fontSize="14px"
                    fontWeight="700"
                    color="font"
                >
                    Recomendaciones
                </Text>

                {curso?.tutorizado &&
                    <CardRequisito
                        stat={parseInt((matriculaActual?.tiempoAcceso || 0)?.toFixed(0))}
                        isTiempo
                        isFundae={matriculaActual?.grupo?.fundae}
                        matricula={matriculaActual}
                    />
                }
            </Flex>
        </Flex>
    )
}
