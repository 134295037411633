import { Flex, Skeleton, Text } from "@chakra-ui/react"

export const SkeletonAprendizajeMatriculas = () => {
    return (
        <Flex
            gap="40px"
            w={"100%"}
            direction="column"
        >
            <Flex>
                <Skeleton
                    h="292px"
                    w="full"
                    rounded="20px"
                />
            </Flex>

            <Flex
                direction="column"
                gap="40px"
            >
                <Flex
                    direction="column"
                    w="full"
                    gap="20px"
                >

                    <Text
                        fontSize="18px"
                        fontWeight="700"
                        color="font"
                    >
                        Cursos Activos
                    </Text>

                    <Flex
                        w="full"
                    >
                        <Flex
                            w="100%"
                            gap="20px"
                        >
                            {Array.from({ length: 3 }, (_, i) => i)?.map((item: number) => (
                                <Skeleton
                                    key={item}
                                    h="270px"
                                    w="394px"
                                    rounded="20px"
                                />
                            ))}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex
                direction="column"
                gap="40px"
            >
                <Flex
                    direction="column"
                    w="full"
                    gap="20px"
                >

                    <Text
                        fontSize="18px"
                        fontWeight="700"
                        color="font"
                    >
                        Cursos Finalizados
                    </Text>

                    <Flex
                        w="full"
                    >
                        <Flex
                            w="100%"
                            gap="20px"
                        >
                            {Array.from({ length: 3 }, (_, i) => i)?.map((item: number) => (
                                <Skeleton
                                    key={item}
                                    h="270px"
                                    w="394px"
                                    rounded="20px"
                                />
                            ))}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}