import { Flex, Text, useColorMode } from "@chakra-ui/react";
import { ProgressBar } from "../../../../shared/components/Progress/ProgressBar";
import { fmtSnds, fmtSndsHourMin } from "../../../../shared/utils/functions/formatters";
import { MatriculasInt } from "../../../../interfaces/MatriculasInt";

interface Props {
  stat: any;
  isPuntuacion?: boolean;
  isActividades?: boolean;
  isEjercicios?: boolean;
  isFundae?: boolean;
  isTiempo?: boolean;
  matricula?: MatriculasInt;
}

export default function CardRequisito({ stat, isPuntuacion, isActividades, isEjercicios, isFundae = true, isTiempo = false, matricula }: Props) {
  const { colorMode } = useColorMode();

  const timeToProgress = (time: number): number => {
    if (!time) return 0;

    const timeArray = fmtSnds(time)?.split(':');
    const hours = Number(timeArray[0]);
    const minutes = Number(`0.${timeArray[1]}`);

    const totalTime = (hours + minutes)

    return totalTime
  }

  const timeToProgressMin = (time: number): number => {
    if (!time) return 0;

    const horasTotal = time / 3600;
    
    if (horasTotal >= 45) return 100;
    
    const porcentaje = (horasTotal / 45) * 100;

    return porcentaje;
  }

  return (
    <Flex
      bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
      rounded="10px"
      p="20px"
      direction="column"
      gap="8px"
      border="1px solid"
      borderColor={colorMode === "dark" ? "#576776" : "border"}
      h="fit-content"
    >
      <Flex
        justify="space-between"
        align="center"
        mt="-7px"
      >
        <Text
          fontSize="13px"
          fontWeight="600"
          color="font"
          textTransform="uppercase"
        >
          {isPuntuacion && (isFundae ? "Tener más de un 75% de nota final" : "Nota final")}
          {isActividades && (isFundae ? "Completar el 75% de las actividades" : "Progreso total de Actividades")}
          {isEjercicios && (isFundae ? "Superar el 75% de los ejercicios" : "Progreso total de Ejercicios")}
          {isTiempo && (isFundae ? "Tener el 75% de la duración del curso" : "Progreso total de Tiempo")}
        </Text>

        <Text
          fontSize="20px"
          fontWeight="700"
          color="font"
        >
          {isTiempo ? `${fmtSndsHourMin(stat)}` : `${stat}%`}
        </Text>
      </Flex>

      <ProgressBar
        progress={isTiempo ? (stat > 3600 ? timeToProgress(stat) : timeToProgressMin(stat)) : stat}
        bg={colorMode === "dark" ? "#424242" : "#F0F0F3"}
        bgProgress="main"
        height="8px"
        max={isTiempo ? (matricula?.grupo?.curso?.duracion || 0) : 100}
      />

      {
        isTiempo &&
        <Text
          fontSize="12px"
          fontStyle="italic"
          fontWeight="400"
        >
          *IMPORTANTE: El tiempo de conexión únicamente será contabilizado dentro de las<Text as="span" fontWeight="700"> lecciones del curso, foro y mensajería</Text>.
        </Text>
      }
    </Flex>
  )
}
