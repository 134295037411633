import { Flex, Text, useColorMode } from '@chakra-ui/react'
import { CursosInt, LeccionInt } from '../../../../interfaces/CursosInt';
import { MatriculasInt } from '../../../../interfaces/MatriculasInt';
import imageCurso from "../../../../assets/img/cardTest.png"
import { ProgressBar } from '../../../../shared/components/Progress/ProgressBar';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { useNavigate } from 'react-router-dom';
import { useData } from '../../../../shared/hooks/useData';
import { EndpointTypes } from '../../../../shared/utils/types/EndpointTypes';
import { useDataId } from '../../../../shared/hooks/useDataId';
import { format } from '../../../../shared/utils/functions/formatDate';

interface Props {
    curso: CursosInt;
    grupoSlug: string | undefined;
    matricula: MatriculasInt;
}

export default function NewCardCurso({ curso, grupoSlug, matricula }: Props) {
    const navigate = useNavigate()
    const { colorMode } = useColorMode()

    const url = grupoSlug ? `/aprendizaje/${curso?.slug}?grupo=${grupoSlug}` : `/aprendizaje/${curso?.slug}`

    const { data: progreso } = useData({
        endpoint: EndpointTypes.PROGRESOS,
        query: {
            curso: matricula?.grupo?.cursoId!,
            matricula: matricula?.id!
        },
        ignoreRequest: !matricula
    })

    const { data: infoCurso } = useDataId({
        endpoint: EndpointTypes.CURSOS,
        id: curso?.slug
    })

    const totalProgreso = (progreso?.data[0]?.leccionesCompletadas / infoCurso?.meta?.totalLecciones) * 100

    return (
        <Flex
            p="20px"
            rounded="16px"
            direction="column"
            backgroundImage={curso?.imagen?.url ? curso?.imagen?.url : imageCurso}
            backgroundPosition="center"
            bgSize="cover"
            w="full"
            h="fit-content"
            gap="80px"
            onClick={() => navigate(url)}
            position="relative"
            _hover={{
                transform: "scale(1.01)",
            }}
            transition="all 0.2s"
            cursor="pointer"
            _before={{
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: "linear-gradient(0deg, rgba(0,0,0,1) 10%, rgba(0,0,0,0.7) 30%, rgba(0,0,0,0) 60%)",
                zIndex: 1,
                rounded: "15px"
            }}
        >
            <Flex
                justify="space-between"
            >
                <Text
                    fontSize="12px"
                    fontWeight="500"
                    bg={colorMode === "dark" ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.8)"}
                    color="font"
                    rounded="16px"
                    p="10px"
                    w="fit-content"
                >
                    {infoCurso?.modulos?.length} módulos
                </Text>

                <Text
                    fontSize="12px"
                    fontWeight="500"
                    bg={colorMode === "dark" ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.8)"}
                    color="font"
                    rounded="16px"
                    p="10px"
                    w="fit-content"
                >
                    {format({ date: matricula?.fechaInicio, customFormat: "dd/MM/yyyy" })} - {format({ date: matricula?.fechaFin, customFormat: "dd/MM/yyyy" })}
                </Text>
            </Flex>

            <Flex
                direction="column"
                gap="10px"
                zIndex={2}
            >
                <Text
                    fontSize="20px"
                    fontWeight="600"
                    color={"purewhite"}
                >
                    {curso?.nombre}
                </Text>

                <Flex
                    align="center"
                    direction="column"
                    gap="10px"
                >
                    <Flex
                        w="full"
                        align="center"
                        gap="20px"
                    >
                        <Text
                            fontSize="12px"
                            whiteSpace="nowrap"
                            color={"purewhite"}
                        >
                            {curso?.duracion} horas
                        </Text>

                        <ProgressBar
                            progress={parseInt((totalProgreso || 0)?.toFixed(0))}
                            bg={colorMode === "dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(255, 255, 255, 0.4)"}
                            bgProgress="main"
                            height="6px"
                        />
                    </Flex>

                    <MarkdownPreview
                        source={curso?.descripcion || ''}
                        style={{
                            fontSize: '14px',
                            fontWeight: "300",
                            color: "#FFF",
                            lineHeight: "140%",
                            width: '100%',
                            maxWidth: '100%',
                            backgroundColor: "transparent",
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden'
                        }}
                        wrapperElement={{
                            "data-color-mode": "light"
                        }}
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}
