import { Box, Flex } from "@chakra-ui/react"
import Confetti from 'react-confetti'
import { ExamenAprobado } from "../../../Evaluaciones/components/ExamenAprobado";
import { ExamenSuspendido } from "../../../Evaluaciones/components/ExamenSuspendido";
import { useTenantInfo } from "../../../../shared/hooks/useTenantInfo";

interface Props {
    examen: any;
    aprobado: boolean | undefined;
    onStartExamen: () => void;
    isLoading?: boolean;
    outExamen: () => void;
}

export const EndQuiz = ({ examen, aprobado, onStartExamen, isLoading, outExamen }: Props) => {
    const { handleGetInfo } = useTenantInfo();
    const colores = handleGetInfo({ key: "colores" });

    return (
        <Flex
            w="100%"
            h="100%"
            justifyContent="center"
        >
            {isLoading || aprobado === undefined
                ? <Box />
                :
                aprobado
                    ?
                    <Flex
                        w="fit-content"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Confetti
                            colors={[colores?.secondary, colores?.main, colores?.variant]}
                        />

                        <ExamenAprobado
                            examen={examen}
                            outExamen={outExamen}
                        />
                    </Flex>
                    :
                    <Flex
                        w="fit-content"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <ExamenSuspendido
                            onStartExamen={onStartExamen}
                            examen={examen}
                        />
                    </Flex>
            }
        </Flex>
    )
}