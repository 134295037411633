import { NavigateFunction } from "react-router-dom";
import { toastNotify } from "./toastNotify";
import { StatusEnumTypes } from "../types/StatusEnumTypes";

type AdonisErrorInt = {
    field: string;
    message: string;
    rule: string;
};

export const handleErrors = (
    errors: AdonisErrorInt[] = [],
    status: number
): { message: string }[] => {
    const response: { message: string }[] = [];

    if (errors?.length === 0) {
        response.push({
            message:
                "Error inesperado. Por favor, actualice la página o contacte con soporte",
        });
    } else {
        switch (status) {
            case 401:
                response.push({
                    message:
                        "Error de autenticación. Compruebe sus credenciales o contacte con soporte",
                });
                break;
            case 422:
                for (const error of errors) {

                    if (error.message.includes("unique validation failed")) {
                        response.push({
                            message: `Error ya existe un campo con el valor de ${error.field}`,
                        });
                    } else if (error.message.includes("required validation failed")) {
                        response.push({
                            message: `Error el campo ${error.field} tiene un formato incorrecto`,
                        });
                    } else if (error.message.includes("regex validation failed")) {
                        error.field === "telefono"
                            ?
                            response.push({
                                message: `Error el campo ${error.field} debe tener el siguiente formato: 656236542`,
                            })
                            :
                            response.push({
                                message: `Error el campo ${error.field} tiene un formato incorrecto`,
                            })
                    } else
                        response.push({
                            message:
                                "Error de validación. Verifique la información o contacte con soporte",
                        });
                }
                break;
            case 500:
                response.push({
                    message:
                        "Ha fallado el servidor. Por favor, actualice la página o contacte con soporte",
                });
                break;
            default:
                response.push({
                    message:
                        "Error inesperado. Por favor, actualice la página o contacte con soporte",
                });
                break;
        }
    }

    return response;
};

export const handleUnauthorized = ({
    logout,
    navigate,
    toast
}: {
    logout: (message: string, navigate: NavigateFunction, toast: any) => void;
    navigate: NavigateFunction;
    toast: any;
}) => logout("Su Token ha caducado, vuelva a iniciar sesion", navigate, toast)