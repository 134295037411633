const formSelectStyles = {
    container: (styles: any, { isDisabled }: any) => ({
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "16px",
    }),
    singleValue: (styles: any) => ({
        ...styles,
        color: '#000',
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "16px",
    }),
    indicatorSeparator: (styles: any) => ({ display: 'none' }),
    control: (styles: any) => ({
        ...styles,
        borderRadius: '10px',
        borderColor: '#E2E8F0',
        backgroundColor: '#FFFFFF',
        padding: "1.5px",
        border: '1px solid dark_grey',
    }),
    option: (styles: any, { isFocused }: any) => ({
        ...styles,
        cursor: 'pointer',
        textAlign: 'left',
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "16px",
        color: '#000',
        backgroundColor: isFocused ? '#CCC' : '#FFF',
    }),
    placeholder: (styles: any) => ({
        ...styles,
        color: "#8C909C",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "16px",
        letterSpacing: "-0.078px",
    }),
};

const formSelectStylesDark = {
    container: (styles: any, { isDisabled }: any) => ({
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "16px",
    }),
    singleValue: (styles: any) => ({
        ...styles,
        color: '#F8F8F8',
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "16px",
    }),
    indicatorSeparator: (styles: any) => ({ display: 'none' }),
    menu: (styles: any) => ({
        ...styles,
        backgroundColor: '#191D23',
    }),
    control: (styles: any) => ({
        ...styles,
        borderRadius: '10px',
        borderColor: '#576776',
        backgroundColor: '#191D23',
        padding: "1.5px",
        border: '1px solid #576776',
    }),
    option: (styles: any, { isFocused }: any) => ({
        ...styles,
        cursor: 'pointer',
        textAlign: 'left',
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "16px",
        color: '#F8F8F8',
        backgroundColor: isFocused ? '#262C36' : '#191D23',
    }),
    placeholder: (styles: any) => ({
        ...styles,
        color: "#8C909C",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "16px",
        letterSpacing: "-0.078px",
    }),
};

export { formSelectStyles, formSelectStylesDark };