// React 
import { Navigate, Outlet, useNavigate } from "react-router-dom";
// Hook
import { useAuthContext } from "../../context/user.context";
import { UserRolEnum } from "../../utils/types/UserRolEnum";
import { useToast } from "@chakra-ui/react";
import { EndpointTypes } from "../../utils/types/EndpointTypes";
import { useData } from "../../hooks/useData";
import { useEffect } from "react";
import { handleUnauthorized } from "../../utils/functions/handleErrors";

interface Props {
    isRoleAllowed: boolean;
}

export const RequireAuth = ({ isRoleAllowed = true }: Props) => {
    const { user, logout, authToken, setAuthToken } = useAuthContext();
    const navigate = useNavigate()
    const toast = useToast()

    const { data, error } = useData({
        endpoint: EndpointTypes.CHECK_TOKEN,
        ignoreRequest: !user?.auth,
        retry: 0
    })

    useEffect(() => {
        if (!user?.auth) return

        if (error && !data) {
            setAuthToken(false)

            handleUnauthorized({
                logout: logout,
                navigate: navigate,
                toast: toast
            })
        } else if (data === "ok") {
            setAuthToken(true)
        }
    }, [data, error, user?.auth])

    return (
        authToken &&
        (
            !user?.auth
                ? <Navigate to={"/login"} />
                : (user?.auth && isRoleAllowed)
                    ?
                    <Outlet />
                    :
                    <Navigate
                        to={user?.role?.nombre === UserRolEnum.TUTOR ? "/correcciones" : "/dashboard"}
                    />
        )
    )
};