import { Box, Flex, useDisclosure, useColorMode, useToast } from "@chakra-ui/react"
import { useCountdown } from "../../../shared/hooks/useCountdown";
import { useEffect, useState } from "react";
import { HeaderQuiz } from "./Items/HeaderQuiz";
import { StartQuiz } from "./Items/StartQuiz";
import { ProgressQuiz } from "./Items/ProgressQuiz";
import { EndQuiz } from "./Items/EndQuiz";
import { endExamen } from "../../../shared/middlewares/examenes.middleware";
import { AxiosResponse } from "axios";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../shared/utils/types/StatusEnumTypes";
import { EndpointTypes } from "../../../shared/utils/types/EndpointTypes";
import { useDataId } from "../../../shared/hooks/useDataId";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useData } from "../../../shared/hooks/useData";
import { UserRolEnum } from "../../../shared/utils/types/UserRolEnum";
import { useAuthContext } from "../../../shared/context/user.context";
import { MatriculasInt } from "../../../interfaces/MatriculasInt";

export enum MODE {
    START = "start",
    PROGRESS = "progress",
    END = "end"
}

export const Quiz = () => {
    const toast = useToast()
    const { user } = useAuthContext()
    const { slug } = useParams()
    const { colorMode } = useColorMode()
    const { onOpen: onOpenAlert, onClose: onCloseAlert, isOpen: isOpenAlert } = useDisclosure();
    const [estado, setEstado] = useState<MODE>(MODE.START)
    const [aprobado, setAprobado] = useState<boolean>()
    const [loadingResultado, setLoadingResultado] = useState<boolean>(false)
    const [preguntaActualIndex, setPreguntaActualIndex] = useState<number>(1);
    const [respuestas, setRespuestas] = useState<any[]>([]);
    const [respuestaActual, setRespuestaActual] = useState<any>();
    const [matriculaId, setMatriculaId] = useState<string>()
    const [search] = useSearchParams()
    const navigate = useNavigate()

    const { data: examen } = useDataId({
        endpoint: EndpointTypes.EXAMENES,
        id: slug
    })

    const { data: matriculas } = useData({
        endpoint: EndpointTypes.MATRICULAS_ALUMNOS,
        ignoreRequest: !user?.auth || user?.role?.nombre !== UserRolEnum.ALUMNO,
        query: {
            limit: 100
        }
    })

    useEffect(() => {
        if (matriculas) {
            if (search.has("matricula")) {
                const getMatricula = search.get("matricula")
                const matricula = matriculas?.data?.find((matricula: MatriculasInt) => matricula?.id === getMatricula)

                if (matricula) return setMatriculaId(matricula?.id)

                navigate(-1)
                toastNotify(toast, StatusEnumTypes.ERROR, "No se encontró la matrícula")
                return
            } else if (examen?.curso?.id) {
                const matricula = matriculas?.data?.find((matricula: MatriculasInt) => matricula?.grupo?.curso?.id === examen?.curso?.id)

                if (matricula) return setMatriculaId(matricula?.id)

                navigate(-1)
                toastNotify(toast, StatusEnumTypes.ERROR, "No se encontró la matrícula")
                return
            } else {
                navigate(-1)

                toastNotify(toast, StatusEnumTypes.ERROR, "No se encontró el examen de la matrícula")
            }
        }
    }, [matriculas])

    const [
        progress,
        minutes,
        seconds,
        secs,
        decrementSecs,
        restartCountdown
    ] = useCountdown((examen?.duracion || 0) / 60);

    useEffect(() => {
        if (estado !== MODE.START) setEstado(MODE.START)
        setAprobado(undefined)
    }, [])

    const onStartExamen = () => {
        if (!examen) return;
        restartCountdown()
        setEstado(MODE.PROGRESS)
    }

    const onEndExamen = (respuestas: string[]) => {
        if (
            !examen?.id ||
            !respuestas ||
            respuestas?.length === 0 ||
            !matriculaId
        ) return;

        setLoadingResultado(true)

        endExamen({
            id: examen?.id,
            respuestas,
            matriculaId
        })
            .then((response: AxiosResponse) => {
                setAprobado(response?.data?.data?.aprobado)
                setEstado(MODE.END)
            })
            .catch(() => {
                toastNotify(toast, StatusEnumTypes.ERROR, "Hubo un error al finalizar el examen")

                setAprobado(undefined)
                setEstado(MODE.START)
            })
            .finally(() => {
                setPreguntaActualIndex(1)
                setLoadingResultado(false)
            })

    }

    const outExamen = () => {
        setEstado(MODE.START)
        setAprobado(undefined)
        setRespuestaActual(undefined);
        setRespuestas([]);
        setPreguntaActualIndex(1);
    }

    return (
        <Flex
            bg={colorMode === "dark" ? "bg_dark" : "#FFFFFF"}
            w="100%"
        >
            <Flex
                direction="column"
                w="100%"
            >
                <HeaderQuiz
                    examen={examen}
                    estado={estado}
                    preguntaActualIndex={preguntaActualIndex}
                    minutes={minutes}
                    seconds={seconds}
                    outExamen={outExamen}
                    onOpenAlert={onOpenAlert}
                />

                <Box
                    px="100px"
                    h="calc(100vh - 120px)"
                >
                    {
                        estado === MODE.START &&
                        <StartQuiz
                            onStartExamen={onStartExamen}
                        />
                    }

                    {
                        estado === MODE.PROGRESS &&
                        <ProgressQuiz
                            examen={examen}
                            preguntaActualIndex={preguntaActualIndex}
                            setPreguntaActualIndex={setPreguntaActualIndex}
                            respuestaActual={respuestaActual}
                            setRespuestaActual={setRespuestaActual}
                            onEndExamen={onEndExamen}
                            isLoading={loadingResultado}
                            decrementSecs={decrementSecs}
                            secs={secs}
                            respuestas={respuestas}
                            setRespuestas={setRespuestas}
                            onClose={onCloseAlert}
                            isOpen={isOpenAlert}
                            outExamen={outExamen}
                        />
                    }

                    {
                        estado === MODE.END &&
                        <EndQuiz
                            examen={examen}
                            aprobado={aprobado}
                            onStartExamen={onStartExamen}
                            isLoading={loadingResultado}
                            outExamen={outExamen}
                        />
                    }
                </Box>
            </Flex>
        </Flex>
    )
}