import { Box, Flex, Icon, Text, useColorMode } from "@chakra-ui/react"
import { Dispatch, SetStateAction } from "react"
import { FilterDateInput } from "@imaginagroup/bit-components.ui.filters.filter-date-input"
import { jsDateFormat } from "../../../shared/utils/functions/formatDate";
import { BiTrashAlt } from "react-icons/bi";
import "../../../ui/sass/FilterDateInput.scss"

interface Props {
    query: {
        fechaPagoInicio?: string;
        fechaPagoFin?: string;
    };
    setQuery: Dispatch<SetStateAction<{ fechaPagoInicio?: string; fechaPagoFin?: string; }>>;
}

export const Filter = ({
    query,
    setQuery
}: Props) => {
    const { colorMode } = useColorMode();

    return (
        <Flex
            className={colorMode === "dark" ? "calendar-dark" : "calendar-light"}
            rounded="16px"
            border="1px solid"
            borderColor={colorMode === "dark" ? "border_variant" : "#DFE1E6"}
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            boxShadow="0px 1px 2px 0px rgba(228, 229, 231, 0.24)"
            p="20px"
            gap="15px"
            direction="column"
            justifyContent="start"
        >
            <Text
                color="#666D80"
                fontSize="14px"
                fontWeight="500"
            >
                Fechas
            </Text>

            <Flex
                alignItems="center"
                w="100%"
                gap="15px"
            >
                <Box
                    flex="1"
                    pos="relative"
                >
                    <FilterDateInput
                        locale="es"
                        value={query?.fechaPagoInicio ? jsDateFormat({ date: query?.fechaPagoInicio, format: "yyyy-MM-dd" }) as string : ""}
                        onChange={(e) => {
                            const newQuery = {
                                ...query,
                                fechaPagoInicio: jsDateFormat({ date: e, format: "yyyy-MM-dd" }) as string
                            }

                            setQuery(newQuery)
                        }}
                    />

                    <Flex
                        position="absolute"
                        right="27px"
                        top="10px"
                        zIndex="999"
                        boxSize="40px"
                        alignItems="center"
                        justifyContent="center"
                        roundedTopRight="6px"
                        roundedBottomRight="6px"
                        display={query?.fechaPagoInicio ? "block" : "none"}
                        cursor={query?.fechaPagoInicio ? "pointer" : "not-allowed"}
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation()

                            let newQuery = {}

                            if (query?.fechaPagoFin) {
                                newQuery = { fechaPagoFin: query?.fechaPagoFin }
                            }

                            setQuery(newQuery)
                        }}
                    >
                        <Icon
                            as={BiTrashAlt}
                            color="font"
                            boxSize="16px"
                        />
                    </Flex>
                </Box>

                <Text
                    color="#515561"
                    fontSize="14px"
                    fontWeight="500"
                >
                    -
                </Text>

                <Box
                    flex="1"
                    pos="relative"
                >
                    <FilterDateInput
                        locale="es"
                        value={query?.fechaPagoFin ? jsDateFormat({ date: query?.fechaPagoFin, format: "yyyy-MM-dd" }) as string : ""}
                        onChange={(e) => {
                            const newQuery = {
                                ...query,
                                fechaPagoFin: jsDateFormat({ date: e, format: "yyyy-MM-dd" }) as string
                            }

                            setQuery(newQuery)
                        }}
                    />

                    <Flex
                        position="absolute"
                        right="27px"
                        top="10px"
                        zIndex="999"
                        boxSize="40px"
                        alignItems="center"
                        justifyContent="center"
                        roundedTopRight="6px"
                        roundedBottomRight="6px"
                        display={query?.fechaPagoFin ? "block" : "none"}
                        cursor={query?.fechaPagoFin ? "pointer" : "not-allowed"}
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation()

                            let newQuery = {}

                            if (query?.fechaPagoInicio) {
                                newQuery = { fechaPagoInicio: query?.fechaPagoInicio }
                            }

                            setQuery(newQuery)
                        }}
                    >
                        <Icon
                            as={BiTrashAlt}
                            color="font"
                            boxSize="16px"
                        />
                    </Flex>
                </Box>
            </Flex>
        </Flex >
    )
}