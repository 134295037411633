import { Flex, Skeleton, Text, useColorMode, useToast } from "@chakra-ui/react"
import { Topbar } from "../../../../shared/components/Topbar/Topbar"
import { useEffect, useState } from "react";
import { MatriculasInt } from "../../../../interfaces/MatriculasInt";
import { useData } from "../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes";
import { useAuthContext } from "../../../../shared/context/user.context";
import { UserRolEnum } from "../../../../shared/utils/types/UserRolEnum";
import { useNavigate, useSearchParams } from "react-router-dom";
import SelectorCurso from "../../../Entregables/components/SelectorCurso";
import { CardFundae } from "../../components/CardFundae";
import { CardMensajes } from "../../components/CardMensajes";
import { CardContador } from "../../components/CardContador";
import { CardProgreso, TypeProgresoEnum } from "../../components/CardProgresos";
import { CardContinuar } from "../../components/CardContinuar";
import { CardFinalizacion } from "../../components/CardFinalizacion";
import { CardEntregables } from "../../components/CardEntregables";
import { CardContadorFinalizado } from "../../components/CardContadorFinalizado";
import { CardMatriculaInactiva } from "../../components/CardMatriculaInactiva";
import { CardCursoFinalizado } from "../../components/CardCursoFinalizado";
import { CardForo } from "../../components/CardForo";
import { EstadoTypeEnum } from "../../../../shared/utils/types/EstadoTypeEnum";
import { CardMatriculaProxima } from "../../components/CardMatriculaProxima";
import { CardCursoProximo } from "../../components/CardCursoProximo";
import { CardContadorSinMatricula } from "../../components/SinMatriculas/CardContadorSinMatricula";
import { CardActividadSinMatricula } from "../../components/SinMatriculas/CardActividadSinMatricula";
import { CardEntregablesSinMatricula } from "../../components/SinMatriculas/CardEntregablesSinMatricula";
import { CardFinalizacionSinMatricula } from "../../components/SinMatriculas/CardFinalizacionSinMatricula";
import { CardSinMatricula } from "../../components/SinMatriculas/CardSinMatricula";
import { CardProgresosSinMatricula } from "../../components/SinMatriculas/CardProgresosSinMatricula";
import { CardSinFecha } from "../../components/CardSinFecha";

export const Dashboard = () => {
    const { user, logout } = useAuthContext()
    const navigate = useNavigate();
    const toast = useToast()
    const [search, setSearch] = useSearchParams();
    const { colorMode } = useColorMode()

    const { data: matriculas, loading, error, errorsList, status } = useData({
        endpoint: EndpointTypes.DASHBOARD,
        ignoreRequest: !user?.auth || user?.role?.nombre !== UserRolEnum.ALUMNO,
    })

    const [selectedMatricula, setSelectedMatricula] = useState<MatriculasInt>();

    useEffect(() => {
        if (!matriculas || loading || matriculas.length === 0) return;

        const matriculaId = search.get('curso');

        if (matriculaId) {
            const matriculaEncontrada = matriculas.find((m: MatriculasInt) => m?.id?.toString() === matriculaId);

            if (matriculaEncontrada) {
                setSelectedMatricula(matriculaEncontrada);
            }
        } else {
            const matriculaSeleccionada = matriculas[0];
            setSelectedMatricula(matriculaSeleccionada);
            setSearch({ curso: matriculaSeleccionada.id.toString() });
        }
    }, [matriculas, loading, search]);

    const handleSelectMatricula = (matricula: MatriculasInt) => {
        if (!matricula) return;
        setSelectedMatricula(matricula);
        setSearch((prevSearch) => ({ ...prevSearch, curso: matricula?.id?.toString() }));
    }

    return (
        <Topbar
            title="Resumen"
            searchBar
        >
            <Flex
                pt="20px"
                px="30px"
                gap="20px"
                direction="column"
                w="100%"
            >
                <Flex
                    w="100%"
                    alignItems="center"
                >
                    {loading ?
                        <Skeleton
                            h="70px"
                            w="full"
                            rounded="12px"
                        />
                        :
                        <SelectorCurso
                            onSelectMatricula={handleSelectMatricula}
                            matriculas={matriculas}
                            selectedMatricula={selectedMatricula}
                        />
                    }
                </Flex>

                <Flex
                    gap="24px"
                    pb="30px"
                >
                    <Flex
                        w="70%"
                        gap="20px"
                        direction="column"
                    >
                        <Flex
                            p="20px"
                            border="1px solid"
                            borderColor={colorMode === "dark" ? "border_variant" : "#DFE1E6"}
                            rounded="16px"
                            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                            gap="16px"
                        >
                            {[TypeProgresoEnum.ACTIVIDADES, TypeProgresoEnum.EJERCICIOS, TypeProgresoEnum.NOTA].map((tipo) => (
                                (loading ||
                                    (matriculas && matriculas?.length > 0)
                                ) ?
                                    <CardProgreso
                                        key={tipo}
                                        matricula={selectedMatricula}
                                        typeProgreso={tipo}
                                    />
                                    :
                                    <CardProgresosSinMatricula
                                        key={tipo}
                                        typeProgreso={tipo}
                                    />
                            ))}
                        </Flex>

                        <Flex
                            gap="20px"
                        >
                            {(loading ||
                                (matriculas && matriculas?.length > 0)
                            ) ?
                                (
                                    (selectedMatricula?.progresoLecciones && selectedMatricula?.progresoLecciones > 99) ?
                                        <CardCursoFinalizado
                                            matricula={selectedMatricula}
                                        />
                                        : selectedMatricula?.estado === EstadoTypeEnum.INACTIVO ?
                                            <CardMatriculaInactiva
                                                matricula={selectedMatricula}
                                            />
                                            : selectedMatricula?.estado === EstadoTypeEnum.PROXIMO ?
                                                <CardMatriculaProxima
                                                    matricula={selectedMatricula}
                                                />
                                                :
                                                <CardContinuar
                                                    matricula={selectedMatricula}
                                                />
                                )
                                :
                                <CardSinMatricula />
                            }

                            {(loading ||
                                (matriculas && matriculas?.length > 0)
                            ) ?
                                <CardFinalizacion
                                    matricula={selectedMatricula}
                                />
                                :
                                <CardFinalizacionSinMatricula />
                            }
                        </Flex>

                        {(loading ||
                            (matriculas && matriculas?.length > 0)
                        ) ?
                            <CardEntregables
                                matricula={selectedMatricula}
                            />
                            :
                            <CardEntregablesSinMatricula />
                        }
                    </Flex>

                    <Flex
                        w="30%"
                        gap="20px"
                        direction="column"
                    >
                        {(loading ||
                            (matriculas && matriculas?.length > 0)
                        ) ?
                            (
                                selectedMatricula?.estado === EstadoTypeEnum.INACTIVO ?
                                    <CardContadorFinalizado />
                                    :
                                    selectedMatricula?.estado === EstadoTypeEnum.PROXIMO ?
                                        <CardCursoProximo />
                                        :
                                        selectedMatricula?.fechaFin ?
                                            <CardContador
                                                matricula={selectedMatricula}
                                            />
                                            :
                                            <CardSinFecha />
                            )
                            :
                            <CardContadorSinMatricula />
                        }

                        {(loading ||
                            (matriculas && matriculas?.length > 0)
                        ) ?
                            (
                                !selectedMatricula?.grupo?.fundae ?
                                    <CardForo
                                        matricula={selectedMatricula}
                                    />
                                    :
                                    <CardFundae />
                            )
                            :
                            <CardActividadSinMatricula />
                        }

                        <CardMensajes
                            loadingMatriculas={loading}
                        />

                    </Flex>
                </Flex>
            </Flex>
        </Topbar>
    )
}
