import { Box, Image, Text, Flex, Icon, Skeleton, useColorMode } from '@chakra-ui/react';
import { CursosInt } from '../../../../interfaces/CursosInt';
import { MatriculasInt } from '../../../../interfaces/MatriculasInt';
import { format } from '../../../../shared/utils/functions/formatDate';
import imageCurso from "../../../../assets/img/cardTest.png"

interface Props {
  curso: CursosInt;
  matricula: MatriculasInt;
}

export const CardCursoProximos = ({
  curso,
  matricula,
}: Props) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      p="20px"
      rounded="16px"
      direction="column"
      backgroundImage={curso?.imagen?.url ? curso?.imagen?.url : imageCurso}
      backgroundPosition="center"
      bgSize="cover"
      w="full"
      h="235px"
      gap="80px"
      position="relative"
      _hover={{
        transform: "scale(1.01)",
      }}
      transition="all 0.2s"
      cursor="pointer"
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "linear-gradient(0deg, rgba(0,0,0,1) 10%, rgba(0,0,0,0.7) 30%, rgba(0,0,0,0) 60%)",
        zIndex: 1,
        rounded: "15px"
      }}
      maxW="390px"
    >
      <Text
        fontSize="12px"
        fontWeight="500"
        bg={colorMode === "dark" ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.8)"}
        color="font"
        rounded="16px"
        p="10px"
        w="fit-content"
      >
        {curso?.meta?.totalModulos} módulos
      </Text>

      <Flex
        direction="column"
        gap="5px"
        zIndex={2}
      >
        <Text
          fontSize="20px"
          fontWeight="600"
          color={"purewhite"}
        >
          {curso?.nombre}
        </Text>

        <Flex
          align="center"
          direction="column"
          gap="10px"
        >
          <Flex
            w="full"
            align="center"
          >
            <Text
              fontSize="12px"
              whiteSpace="nowrap"
              color={"purewhite"}
            >
              {curso?.duracion} horas
            </Text>
          </Flex>
        </Flex>

        <Flex
          alignItems="center"
          gap="35px"
        >
          <Flex
            gap="10px"
            alignItems="center"
          >
            <Text
              fontSize="12px"
              fontWeight="400"
              color="main"
            >
              Fecha inicio
            </Text>

            <Text
              fontSize="14px"
              fontWeight="400"
              color="font"
            >
              {format({ date: matricula?.fechaInicio, customFormat: "dd/MM/yyyy" })}
            </Text>
          </Flex>

          <Flex
            gap="10px"
            alignItems="center"
          >
            <Text
              fontSize="12px"
              fontWeight="400"
              color="main"
            >
              Fecha fin
            </Text>

            <Text
              fontSize="14px"
              fontWeight="400"
              color="font"
            >
              {format({ date: matricula?.fechaFin, customFormat: "dd/MM/yyyy" })}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CardCursoProximos;