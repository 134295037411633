export enum TipoCategoriaProfesional {
    DIRECTIVO = 'directivo',
    MANDO_INTERMEDIO = 'mando_intermedio',
    TECNICO = 'tecnico',
    TRABAJADOR_BAJA_CUALIFICACION = 'trabajador_baja_cualificacion',
    TRABAJADOR_CUALIFICADO = 'trabajador_cualificado',
}

export const CategoriaProfesionalOptions = [
    { value: TipoCategoriaProfesional.DIRECTIVO, label: 'Directivo' },
    { value: TipoCategoriaProfesional.MANDO_INTERMEDIO, label: 'Mando Intermedio' },
    { value: TipoCategoriaProfesional.TECNICO, label: 'Técnico' },
    { value: TipoCategoriaProfesional.TRABAJADOR_BAJA_CUALIFICACION, label: 'Trabajador Baja Cualificación' },
    { value: TipoCategoriaProfesional.TRABAJADOR_CUALIFICADO, label: 'Trabajador Cualificado' }
]