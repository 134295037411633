import { Flex, Image, Text, useColorMode } from "@chakra-ui/react"

export const CardFundae = () => {
    const { colorMode } = useColorMode()

    return (
        <Flex
            rounded="16px"
            bg={colorMode === "dark" ? "bg_dark_light" : "#F4F0FF"}
            px="24px"
            py="24px"
            direction="column"
            alignItems="start"
            justifyContent="space-between"
            h="190px"
            overflow="auto"
            position="relative"
            borderWidth="1px"
            borderStyle="solid"
            borderColor={colorMode === "dark" ? "border_variant" : "border"}
            borderLeftWidth="3px"
            borderLeftColor="#EFB047"
        >
            <Text
                color="font"
                fontSize="12px"
                fontWeight="400"
                textTransform="uppercase"
                zIndex="99"
            >
                Anuncio
            </Text>

            <Flex
                direction="column"
                alignItems="start"
                gap="8px"
                zIndex="99"
            >
                <Text
                    color="font"
                    fontSize="24px"
                    fontWeight={"600"}
                >
                    Curso Fundae
                </Text>

                <Text
                    color="font"
                    fontSize="12px"
                    fontWeight="400">
                    Si tu curso se realiza a través de Fundae, recuerda descargar y revisar la guía didáctica antes de comenzar.
                </Text>
            </Flex>
        </Flex>
    )
}