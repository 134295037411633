import { Flex, Skeleton } from "@chakra-ui/react"
import { Topbar } from "../../../../shared/components/Topbar/Topbar"
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes"
import { useData } from "../../../../shared/hooks/useData"
import { RutaInt } from "../../../../interfaces/RutasInt"
import { CardRuta } from "../../components/CardRuta"
import { CardSinInformacion, TypeCardSinInformacion } from "../../../../shared/components/Cards/CardSinInformacion"

export const Rutas = () => {
    const { data: rutas, loading } = useData({
        endpoint: EndpointTypes.RUTAS
    })

    return (
        <Topbar
            title="Rutas"
            searchBar
        >
            <Flex
                py="20px"
                px="30px"
                direction="column"
                gap="20px"
                w="full"
            >
                {rutas?.data !== 0 ?

                    loading ?
                        Array.from({ length: 3 }, (_, i) => i).map((_, index: number) => (
                            <Skeleton
                                key={index}
                                minH="230px"
                                h="230px"
                                w="full"
                                rounded="20px"
                            />
                        ))
                        :
                        rutas?.data?.map((r: RutaInt) => (
                            <CardRuta
                                key={r?.id}
                                ruta={r}
                            />
                        ))
                    :
                    <CardSinInformacion
                        type={TypeCardSinInformacion.RUTAS}
                        fullRounded
                        withBorder
                    />
                }

            </Flex>
        </Topbar>
    )
}