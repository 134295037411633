import { Route, Routes } from "react-router-dom"
import NuevasMatriculas from "./views/Index/Index"

export const NuevasMatriculasRouter = () => {
    return (
        <Routes>
            <Route
                path="/:hash/:numMatriculas"
                element={
                    <NuevasMatriculas />
                }
            />
        </Routes>
    )
}