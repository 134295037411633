import { Flex, Skeleton, Text, useColorMode } from "@chakra-ui/react"
import { useData } from "../../../shared/hooks/useData"
import { EndpointTypes } from "../../../shared/utils/types/EndpointTypes"
import { MatriculasInt } from "../../../interfaces/MatriculasInt"
import { useNavigate } from "react-router-dom";

interface Props {
    matricula: MatriculasInt | undefined;
}

export const CardForo = ({ matricula }: Props) => {
    const navigate = useNavigate();
    const { data: preguntas, loading } = useData({
        endpoint: EndpointTypes.PREGUNTAS,
        query: {
            grupo: matricula?.grupoId!
        },
        ignoreRequest: !matricula
    })
    const { colorMode } = useColorMode()

    return (
        (matricula && !loading) ?
            <Flex
                rounded="16px"
                bg={colorMode === "dark" ? "bg_dark_light" : "#F4F0FF"}
                px="24px"
                py="24px"
                direction="column"
                alignItems="start"
                justifyContent="space-between"
                h="190px"
                overflow="auto"
                position="relative"
                borderWidth="1px"
                borderStyle="solid"
                borderColor={colorMode === "dark" ? "border_variant" : "border"}
                borderLeftWidth="3px"
                borderLeftColor="#EFB047"
                onClick={() => navigate("/foro")}
                cursor="pointer"
                transition="all 0.3s ease"
                _hover={{
                    borderLeftColor: "main"
                }}
            >
                <Text
                    color="font"
                    fontSize="12px"
                    fontWeight="400"
                    textTransform="uppercase"
                    zIndex="99"
                >
                    Foro
                </Text>

                <Flex
                    direction="column"
                    alignItems="start"
                    gap="8px"
                    zIndex="99"
                >
                    <Text
                        color="font"
                        fontSize="24px"
                        fontWeight={"600"}
                    >
                        {preguntas?.meta?.total === 0
                            ? "No hay actividad nueva"
                            : (preguntas?.meta?.total === 1 ? `${preguntas?.meta?.total} pregunta` : `${preguntas?.meta?.total} preguntas`)
                        }
                    </Text>

                    <Text
                        color="font"
                        fontSize="12px"
                        fontWeight="400">
                        Contribuye al debate
                    </Text>
                </Flex>
            </Flex>
            :
            <Skeleton
                h="190px"
                w="100%"
                rounded="16px"
            />
    )
}