import { debounce, truncate } from 'lodash';
import { useCallback, useState } from 'react';
import { Box, Flex, Icon, Image, Input, InputGroup, InputLeftElement, InputRightElement, Spinner, Text, Tooltip, useColorMode } from '@chakra-ui/react';
import { BiChevronLeft, BiSearch } from 'react-icons/bi';
import { loadSearchData } from '../../middlewares/search.middleware';
import { SearchInt } from '../../../interfaces/SearchInt';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { HiOutlineAnnotation, HiOutlineBookOpen, HiOutlineDocumentText } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { useData } from '../../hooks/useData';
import { EndpointTypes } from '../../utils/types/EndpointTypes';
import { UserRolEnum } from '../../utils/types/UserRolEnum';
import { useAuthContext } from '../../context/user.context';
import { MatriculasInt } from '../../../interfaces/MatriculasInt';

interface Props {
    placeholder?: string;
}

export const CustomSearch = ({
    placeholder = 'Escribe para buscar',
}: Props) => {

    const navigate = useNavigate()
    const { user } = useAuthContext()
    const [inputValue, setInputValue] = useState('');
    const [menu, setMenu] = useState<boolean>(false);
    const [searchOptions, setSearchOptions] = useState<SearchInt[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { colorMode } = useColorMode()

    const { data: matriculas } = useData({
        endpoint: EndpointTypes.MATRICULAS_ALUMNOS,
        ignoreRequest: !user?.auth || user?.role?.nombre !== UserRolEnum.ALUMNO,
        query: {
            limit: 100
        }
    });

    const debouncedLoadOptions = useCallback(
        debounce(async (inputValue: string) => {
            if (inputValue && inputValue !== '') {
                setIsLoading(true);
                const { data } = await loadSearchData(inputValue);
                const options: SearchInt[] = data?.hits

                setSearchOptions(options);
                setIsLoading(false);
            }
            else
                setSearchOptions(null);
        }, 300),
        []
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        setInputValue(value);
        debouncedLoadOptions(value);
    };

    const handleClick = (data: SearchInt) => {
        const { ref, id, cursoId, tipo } = data?.document

        debouncedLoadOptions('')
        setInputValue('')
        setMenu(false)

        if (tipo === "Curso") {
            const matriculaActual = matriculas?.data?.find((m: MatriculasInt) => m?.grupo?.cursoId === cursoId)
            const grupoSlug = matriculaActual?.grupo?.slug

            navigate(`/aprendizaje/${ref}?grupo=${grupoSlug}`)
        }
        else if (tipo === "Modulo") {
            const matriculaActual = matriculas?.data?.find((m: MatriculasInt) => m?.grupo?.cursoId === cursoId)
            const cursoSlug = matriculaActual?.grupo?.curso?.slug
            const grupoSlug = matriculaActual?.grupo?.slug

            navigate(`/aprendizaje/${cursoSlug}?grupo=${grupoSlug}`)
        }
        else if (tipo === "Leccion") {
            const matriculaActual = matriculas?.data?.find((m: MatriculasInt) => m?.grupo?.cursoId === cursoId)
            const cursoSlug = matriculaActual?.grupo?.curso?.slug
            const grupoSlug = matriculaActual?.grupo?.slug

            navigate(`/aprendizaje/${cursoSlug}/lecciones/${ref}?grupo=${grupoSlug}`)
        }
        if (tipo === "Pregunta") return navigate(`/foro/${id}`)
        else if (tipo === "Respuesta") return navigate(`/foro/${ref}`)

    }

    const getContentToDisplay = (item: SearchInt) => {
        if (item?.highlight?.contenido) {
            const fullContent = item?.document?.contenido
                .replace(/\/embed\?token=[A-Za-z0-9]{8}\s/g, '').replace('null', "")

            const markValue = fullContent?.replace(new RegExp(inputValue, 'gi'), match => `<mark>${match}</mark>`)
            return truncate(markValue, { length: 200 })
        }

        return truncate(item?.document?.contenido?.replace(/\/embed\?token=[A-Za-z0-9]{8}\s/g, '').replace('null', ""), { length: 200 })
    }

    const disabled = !matriculas || matriculas?.length === 0

    const getCursoIcon = (cursoId: string) => {
        const cursoMatricula = matriculas?.data?.find((m: MatriculasInt) => m?.grupo?.cursoId === cursoId)

        return cursoMatricula?.grupo?.curso?.icono
    }

    return (
        <Box
            position="relative"
        >
            <Tooltip
                label="Sin matrículas activas para realizar busquedas"
                hasArrow
                placement="bottom"
                fontSize="14px"
                fontWeight="400"
                color="purewhite"
                bg="pureblack"
                rounded="8px"
                textAlign="center"
                p="10px"
                isDisabled={!disabled}
            >
                <InputGroup>
                    <Input
                        color='#A5A8B3'
                        fontSize="16px"
                        fontWeight="400"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder={placeholder}
                        onFocus={() => setMenu(true)}
                        onBlur={() =>
                            setTimeout(() => {
                                setMenu(false)
                            }, 200)
                        }
                        bg={colorMode === "dark" ? "#262C36" : "purewhite"}
                        isDisabled={disabled}
                    />

                    <InputLeftElement>
                        <Icon
                            as={BiSearch}
                            boxSize="20px"
                            opacity={disabled ? 0.2 : 1}
                            cursor={disabled ? "not-allowed" : "default"}
                        />
                    </InputLeftElement>

                    <InputRightElement>
                        <Icon
                            as={BiChevronLeft}
                            boxSize="24px"
                            transition={'transform 0.3s ease'}
                            transform={menu ? 'rotate(-90deg)' : 'rotate(0deg)'}
                            opacity={disabled ? 0.2 : 1}
                            cursor={disabled ? "not-allowed" : "default"}
                        />
                    </InputRightElement>
                </InputGroup>
            </Tooltip>

            <Box
                position="absolute"
                zIndex={999}
                maxH={300}
                overflowY="scroll"
                css={{
                    '&::-webkit-scrollbar': {
                        width: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: "#E2E8F0",
                        borderRadius: '20px',
                    },
                }}
                top="115%"
                left="0"
                w="100%"
                bg={colorMode === "dark" ? "#262C36" : "purewhite"}
                border="1px solid"
                borderColor={colorMode === "dark" ? "border_variant" : "#E2E8F0"}
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
                rounded="8px"
                display={menu ? 'block' : 'none'}
                padding="12px 8px"
            >
                {searchOptions ?
                    <Flex
                        direction="column"
                        gap="10px"
                    >
                        {searchOptions && searchOptions?.length !== 0 ?
                            searchOptions?.map((item: SearchInt, index: number) => {
                                const icono = getCursoIcon(item?.document?.cursoId)

                                return (
                                    <Flex
                                        key={index}
                                        cursor="pointer"
                                        gap={"15px"}
                                        alignItems={"start"}
                                        _hover={{ bg: colorMode === "dark" ? "border_variant" : "light_grey" }}
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation()

                                            handleClick(item)
                                        }}
                                        p="8px"
                                    >
                                        {icono?.url ?
                                            <Image
                                                src={icono?.url}
                                                alt={icono?.nombre}
                                                boxSize="24px"
                                            />
                                            :
                                            <Icon
                                                as={
                                                    item?.document?.tipo === "Curso" ? HiOutlineBookOpen
                                                        : (item?.document?.tipo === "Leccion" || item?.document?.tipo === "Modulo")
                                                            ? HiOutlineDocumentText
                                                            : HiOutlineAnnotation
                                                }
                                                boxSize="24px"
                                                color="font"
                                            />
                                        }

                                        <Flex
                                            direction="column"
                                            gap="5px"
                                        >
                                            <MarkdownPreview
                                                source={
                                                    item?.highlight?.nombre
                                                        ? item?.highlight?.nombre?.snippet
                                                        : item?.document?.nombre
                                                }
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                    color: colorMode === "dark" ? "purewhite" : "#121625",
                                                    width: '100%',
                                                    maxWidth: '100%',
                                                    backgroundColor: "transparent",
                                                }}
                                                wrapperElement={{ "data-color-mode": colorMode === "dark" ? "dark" : "light" }}
                                            />

                                            <MarkdownPreview
                                                source={getContentToDisplay(item)}
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                    color: colorMode === "dark" ? "#EAEAEA" : "#515561",
                                                    width: '100%',
                                                    maxWidth: '100%',
                                                    backgroundColor: "transparent"
                                                }}
                                                wrapperElement={{ "data-color-mode": colorMode === "dark" ? "dark" : "light" }}
                                            />
                                        </Flex>
                                    </Flex>
                                )
                            })
                            :
                            <Text
                                fontSize="14px"
                                fontWeight="400"
                                color="secondary_font"
                            >
                                No hay resultados
                            </Text>

                        }
                    </Flex>
                    :
                    !isLoading ?
                        <Text
                            textAlign="center"
                        >
                            Escribe para mostrar opciones...
                        </Text>
                        :
                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            w="100%"
                        >
                            <Spinner
                                size="sm"
                                color="secondary"
                                thickness="2px"
                                speed="0.65s"
                                emptyColor="light_grey"
                            />
                        </Flex>
                }
            </Box>
        </Box>

    );
};