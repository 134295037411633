import { Box, Image, Text, Flex, Icon, useColorMode } from '@chakra-ui/react';
import { FaListUl, FaClock } from 'react-icons/fa';
import { CursosInt } from '../../../../interfaces/CursosInt';
import cursoPortada from "../../../../assets/img/CursoPortada.png"
import { useNavigate } from 'react-router-dom';


interface Props {
  curso: CursosInt;
}

export const CardCursoTutor = ({
  curso,
}: Props) => {
  const navigate = useNavigate();
  const { colorMode } = useColorMode();

  return (
    <Flex
      borderWidth="1px"
      rounded="20px"
      bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
      p="18px"
      display="flex"
      gap="20px"
      cursor="pointer"
      transition="all 0.2s"
      _hover={{
        transform: "scale(1.01)",
      }}
      onClick={() => navigate(`/aprendizaje/${curso?.slug}`)}
      w="full"
      h="155px"
      overflow="auto"
    >

      <Image
        src={curso?.imagen?.url ? curso?.imagen?.url : cursoPortada}
        alt={curso?.nombre}
        w="250px"
        objectFit="cover"
        borderRadius="md"
      />

      <Box
        ml={4}
        flex="1"
      >
        <Flex
          justify="space-between"
          direction="column"
        >

          <Flex
            direction="column"
            gap="5px"
          >

            <Text
              fontSize="18px"
              fontWeight="600"
            >
              {curso?.nombre}
            </Text>

          </Flex>

          <Flex
            justifyContent="space-between"
            gap="40px"
            mt={3}
          >

            <Flex
              align="center"
            >
              <Icon
                as={FaListUl}
                mr={2}
              />
              <Text
                fontSize="14px"
                fontWeight="400"
                color="font_grey"
              >
                {curso?.meta?.totalModulos} módulos
              </Text>
              <Icon
                as={FaClock}
                ml={4}
                mr={2}
              />
              <Text
                fontSize="14px"
                fontWeight="400"
                color="font_grey"
              >
                {curso?.duracion} h
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default CardCursoTutor;