import { Flex, IconButton } from '@chakra-ui/react'
import { MatriculasInt } from '../../../../interfaces/MatriculasInt'
import { CarruselCard } from './CarruselCard'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

interface Props {
  matriculas: MatriculasInt[]
}

export default function CarruselCursos({ matriculas }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [direction, setDirection] = useState(0)

  const handlePrevious = () => {
    setDirection(-1)
    setCurrentIndex((prev) => (prev === 0 ? matriculas.length - 1 : prev - 1))
  }

  const handleNext = () => {
    setDirection(1)
    setCurrentIndex((prev) => (prev === matriculas.length - 1 ? 0 : prev + 1))
  }

  const slideVariants = {
    enter: (direction: number) => ({
      x: direction > 0 ? '100%' : '-100%'
    }),
    center: {
      x: 0
    },
    exit: (direction: number) => ({
      x: direction < 0 ? '100%' : '-100%'
    })
  }

  return (
    <Flex
      position="relative"
      w="full"
      align="center"
      overflow="hidden"
      h="342px"
      rounded="16px"
    >
      {matriculas?.length > 1 &&
        <IconButton
          aria-label="Previous"
          icon={<ChevronLeftIcon boxSize={8} />}
          position="absolute"
          left="10px"
          top="50%"
          transform="translateY(-100%)"
          zIndex={2}
          onClick={handlePrevious}
          bg="transparent"
          color="white"
          _hover={{ bg: 'rgba(0,0,0,0.2)' }}
        />
      }

      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={currentIndex}
          custom={direction}
          variants={slideVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "tween", duration: 0.3 }
          }}
          style={{
            width: '100%',
            position: 'absolute',
            height: 'calc(100% - 40px)',
            top: 0
          }}
        >
          <CarruselCard
            curso={matriculas[currentIndex]?.grupo?.curso}
            matricula={matriculas[currentIndex]}
          />
        </motion.div>
      </AnimatePresence>

      {matriculas?.length > 1 &&
        <IconButton
          aria-label="Next"
          icon={<ChevronRightIcon boxSize={8} />}
          position="absolute"
          right="10px"
          top="50%"
          transform="translateY(-100%)"
          zIndex={2}
          onClick={handleNext}
          bg="transparent"
          color="white"
          _hover={{ bg: 'rgba(0,0,0,0.2)' }}
        />
      }

      <Flex
        position="absolute"
        bottom="20px"
        w="full"
        justify="center"
        gap={2}
        zIndex={2}
      >
        {matriculas.map((_, index) => (
          <Flex
            key={index}
            w="8px"
            h="8px"
            rounded="full"
            bg={currentIndex === index ? 'white' : 'rgba(255,255,255,0.5)'}
            cursor="pointer"
            onClick={() => {
              setDirection(index > currentIndex ? 1 : -1)
              setCurrentIndex(index)
            }}
          />
        ))}
      </Flex>
    </Flex>
  )
}
