import { Flex, Image, Text, useColorMode } from "@chakra-ui/react";
import IconFullStack from "../../../assets/img/IconFullStack.png"
import { fmtSndsHourMin } from "../../../shared/utils/functions/formatters";

interface Props {
    ruta: any;
    totalTiempoAcceso: number;
    progresoTotal: number;
    matriculasAcabadas: number;
}

export default function RutaDescription({ ruta, totalTiempoAcceso, progresoTotal, matriculasAcabadas }: Props) {
    const { colorMode } = useColorMode()
    
    return (
        <Flex
            direction="column"
            gap="20px"
            border="1px solid" 
            borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
            rounded="20px"
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            h="516.5px"
            w="100%"
        >
            <Flex
                direction="column"
                gap="32px"
                p="32px"
            >
                <Flex
                    alignItems="center"
                    gap="24px"
                >
                    {/* <Flex
                        bg="#DCF7FF"
                        rounded="10px"
                    >
                        <Image
                            src={IconFullStack}
                        />
                    </Flex> */}

                    <Flex
                        direction="column"
                        gap="8px"
                        alignItems="start"
                    >
                        <Text
                            fontSize="24px"
                            fontWeight="700"
                            color={colorMode === "dark" ? "#FFFFFF" : "#242529"}
                        >
                            {ruta?.nombre}
                        </Text>
                        <Text
                            fontSize="16px"
                            fontWeight="600"
                            color={colorMode === "dark" ? "#FFF" : "#242529"}
                        >
                            {ruta?.cursos?.length} cursos
                        </Text>
                    </Flex>
                </Flex>

                <Text
                    fontSize="15px"
                    fontWeight="400"
                    color={colorMode === "dark" ? "#FFFFFF" : "#515561"}
                >
                    {ruta?.descripcion}
                </Text>
            </Flex>

            <Flex
                direction="column"
                gap="32px"
                p="32px"
                bg={colorMode === "dark" ? "#262C36" : "main"}
                borderBottomRadius="20px"
            >
                <Flex
                    direction="column"
                >
                    <Text
                        color="purewhite"
                        fontSize="24px"
                        fontWeight="700"
                    >
                        {progresoTotal}%
                    </Text>

                    <Text
                        color="purewhite"
                        fontSize="16px"
                        fontWeight="600"
                    >
                        Completo
                    </Text>
                </Flex>

                <Flex
                    direction="column"
                >
                    <Text
                        color="purewhite"
                        fontSize="24px"
                        fontWeight="700"
                    >
                        {matriculasAcabadas === undefined ? 0 : matriculasAcabadas}/{ruta?.cursos?.length}
                    </Text>

                    <Text
                        color="purewhite"
                        fontSize="16px"
                        fontWeight="600"
                    >
                        Cursos Completados
                    </Text>
                </Flex>

                <Flex
                    direction="column"
                >
                    <Text
                        color="purewhite"
                        fontSize="24px"
                        fontWeight="700"
                    >
                        {fmtSndsHourMin(totalTiempoAcceso)}
                    </Text>

                    <Text
                        color="purewhite"
                        fontSize="16px"
                        fontWeight="600"
                    >
                        Tiempo Total Invertido
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}
