import { CircularProgress, CircularProgressLabel, Flex, Skeleton, Text, useColorMode } from "@chakra-ui/react";
import { MatriculasInt } from "../../../interfaces/MatriculasInt"
import { DateTime } from "luxon";
import { useState, useEffect } from "react";
import { format } from "../../../shared/utils/functions/formatDate";

export enum TypeTimeEnum {
    TIME = "time",
    MONTHS = "months",
    DAYS = "days",
    HOURS = "hours",
    MINUTES = "minutes",
    SECONDS = "seconds",
}

interface Props {
    matricula: MatriculasInt | undefined;
}

export const CardContador = ({
    matricula,
}: Props) => {
    const { colorMode } = useColorMode();

    const [time, setTime] = useState<{
        time: number
        months: number
        days: number
        hours: number
        minutes: number
        seconds: number,
        totalDays: number
    }>()

    useEffect(() => {
        if (!matricula?.fechaFin) return
        const t = getCount(matricula.fechaFin);

        setTime(t);

    }, [matricula])

    const diasTotalesGrupo = matricula?.fechaInicio && matricula?.fechaFin
        ? DateTime.fromISO(matricula.fechaFin).diff(DateTime.fromISO(matricula.fechaInicio), 'days').days
        : 0;

    const diasAvanzadosGrupo = matricula?.fechaInicio
        ? DateTime.now().diff(DateTime.fromISO(matricula.fechaInicio), 'days').days
        : 0;

    const getCount = (fechaFin: any) => {
        if (!fechaFin) return;

        const inicio: any = new Date();
        const fin: any = new Date(fechaFin);
        const time: any = (fin - inicio + 1000) / 1000;

        const totalDays: number = Math.floor(time / (24 * 60 * 60));
        const months: number = Math.floor(time / (30 * 24 * 60 * 60));
        const days: number = Math.floor((time % (30 * 24 * 60 * 60)) / (24 * 60 * 60));
        const hours: number = Math.floor((time % (24 * 60 * 60)) / (60 * 60));
        const minutes: number = Math.floor((time % (60 * 60)) / 60);
        const seconds: number = Math.floor(time % 60);

        return {
            time,
            months,
            days,
            hours,
            minutes,
            seconds,
            totalDays
        };
    };

    return (
        <>
            {matricula ?
                <Flex
                    p="20px"
                    border="1px solid"
                    borderColor={colorMode === "dark" ? "border_variant" : "#DFE1E6"}
                    rounded="16px"
                    bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                    gap="20px"
                    direction="column"
                    h="356px"
                >
                    <Text
                        color="font"
                        fontSize="16px"
                        fontWeight="500"
                    >
                        Tiempo restante
                    </Text>

                    <Flex
                        direction="column"
                        align="center"
                        gap="10px"
                    >
                        <Flex
                            align="center"
                        >
                            <CircularProgress
                                capIsRound
                                value={(diasAvanzadosGrupo / diasTotalesGrupo) * 100}
                                color="main"
                                trackColor={colorMode === "dark" ? "#424242" : "#EAECFF"}
                                size="180px"
                                thickness="4px"
                            >
                                <CircularProgressLabel>
                                    <Flex
                                        direction="column"
                                        align="center"
                                    >
                                        <Text
                                            fontSize="32px"
                                            fontWeight="700"
                                            color={colorMode === "dark" ? "purewhite" : "font"}
                                            mb="-10px"
                                        >
                                            {time?.totalDays === 0 ? time?.hours : time?.totalDays}
                                        </Text>

                                        {time?.totalDays !== 0 && (
                                            <Text
                                                fontSize="21px"
                                                fontWeight="700"
                                                color={colorMode === "dark" ? "purewhite" : "font"}
                                            >
                                                {time?.totalDays === 1 ? "Día" : time?.totalDays === 0 ? "Horas" : "Días"}
                                            </Text>
                                        )}

                                        {time?.totalDays === 0 && (
                                            <Text
                                                fontSize="21px"
                                                fontWeight="700"
                                                color={colorMode === "dark" ? "purewhite" : "font"}
                                            >
                                                Horas
                                            </Text>
                                        )}
                                    </Flex>
                                </CircularProgressLabel>
                            </CircularProgress>

                        </Flex>

                        <Flex
                            display={matricula?.fechaFin ? "flex" : "none"}
                            direction="column"
                            align="center"
                            gap="8px"
                        >
                            <Text
                                fontSize="11px"
                                fontWeight="600"
                            >
                                Fecha de Finalización
                            </Text>

                            <Text
                                fontSize="24px"
                                color={colorMode === "dark" ? "purewhite" : "font"}
                                bg={colorMode === "dark" ? "#262C36" : "#F8F8F8"}
                                rounded="12px"
                                p="8px"
                            >
                                {matricula?.fechaFin && format({ date: matricula?.fechaFin, customFormat: "dd/MM/yyyy" })}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
                :
                <Skeleton
                    w="100%"
                    h="356px"
                    rounded="16px"
                />
            }
        </>

    )
}