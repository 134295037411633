import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useColorMode } from "@chakra-ui/react";

interface Props {
    onClose: () => void;
    isOpen: boolean;
}

export const OutExamenModal = (props: Props) => {
    const { isOpen, onClose } = props;
    const { colorMode } = useColorMode()

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            isCentered
            size="xl"
        >
            <ModalOverlay />

            <ModalContent
                p="40px"
                rounded="20px"
                bg={colorMode === "dark" ? "bg_dark" : "purewhite"}
            >
                <ModalHeader
                    fontSize="24px"
                    fontWeight="700"
                    color="font"
                >
                    ¡Atención!
                </ModalHeader>

                <ModalBody>
                    <Text
                        fontSize="15px"
                        fontWeight="400"
                        color="font"
                    >
                        Has salido de la ventana, te hemos descontado 3 minutos del tiempo del examen.
                    </Text>
                </ModalBody>

                <ModalFooter>
                    <Button
                        color="#FFF"
                        fontSize="18px"
                        fontWeight="700"
                        lineHeight="28px"
                        bg="main"
                        h="fit-content"
                        w="fit-content"
                        px="24px"
                        py="10px"
                        rounded="14px"
                        cursor="pointer"
                        _hover={{ bg: "variant" }}
                        onClick={onClose}
                    >
                        Aceptar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}