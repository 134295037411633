import { Badge, Flex, Progress, Text, useColorMode } from "@chakra-ui/react";
import { CustomColumnProps } from "../../../../../shared/components/Table/Table";
import { format } from "../../../../../shared/utils/functions/formatDate";
import { LiquidacionesInt } from "../../../../../interfaces/LiquidacionesInt";

export const AsignacionesColumns = (): CustomColumnProps[] => {
    const { colorMode } = useColorMode()

    const columns: CustomColumnProps[] = [
        {
            header: 'Creación',
            field: 'matriculas.created_at',
            key: 'createdAt',
            body: (rowData: LiquidacionesInt) =>
                <Flex
                    p="16px"
                >
                    <Text
                        fontSize="12px"
                        fontWeight="400"
                        color="font"
                    >
                        {rowData?.createdAt ?
                            format({
                                date: rowData?.createdAt,
                                customFormat: "dd MMM yyyy"
                            })
                            :
                            "--"
                        }
                    </Text>
                </Flex>
        },
        {
            header: 'Empresa Factura',
            field: 'formacion',
            key: 'formacion',
            sortable: true,
            body: (rowData: any) =>
                <Flex>
                    <Badge
                        color="purewhite"
                        bg={rowData?.empresaFactura?.nombre === "Procademy" ? "secondary" : "main"}
                        fontSize="10px"
                        fontWeight="500"
                        letterSpacing="0.2px"
                        textTransform="uppercase"
                        borderRadius="5px"
                        p="5px 10px"
                        maxW="140px"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                    >
                        {rowData?.asignacion ? rowData?.asignacion?.empresaFactura?.nombre : "-"}
                    </Badge>
                </Flex>
        },
        {
            header: 'alumno',
            field: 'nombre',
            key: 'nombre',
            body: (rowData: LiquidacionesInt) =>
                <Text
                    p="16px"
                    fontFamily="inter"
                    color="font"
                    fontSize="14px"
                    fontWeight="400"
                    fontStyle="normal"
                    lineHeight="150%"
                >
                    {rowData?.user?.nombre} {rowData?.user?.apellidos}
                </Text>
        },
        {
            header: 'Matricula',
            field: 'estado',
            key: 'estado',
            body: (rowData: any) => (
                <Flex direction="column" alignItems="flex-start">
                    <Badge
                        color={
                            rowData?.estado == "2" ? "dark_blue" :
                                rowData?.estado == "1" ? "purewhite"
                                    : "#FFF"
                        }
                        bg={
                            rowData?.estado == "2" ? "#E6E6EA" :
                                rowData?.estado == "1" ? "black"
                                    : "main"
                        }
                        fontSize="11px"
                        fontWeight="500"
                        letterSpacing="0.2px"
                        textTransform="uppercase"
                        borderRadius="5px"
                        p="2px 5px"
                    >
                        {
                            rowData?.estado == "2" ? "Inactivo" :
                                rowData?.estado == "1" ? "Próximo"
                                    : "Activo"
                        }
                    </Badge>

                    {(rowData?.fechaInicio && rowData?.fechaFin) &&
                        <Flex
                            alignItems="center"
                            gap="5px"
                            color={colorMode === "dark" ? "purewhite" : "font"}
                            fontSize="13px"
                            fontWeight="400"
                        >
                            <Text>{format({ date: rowData?.fechaInicio, customFormat: "dd/MM/yyyy" })}</Text>
                            <Text mb="2px">-</Text>
                            <Text>{format({ date: rowData?.fechaFin, customFormat: "dd/MM/yyyy" })}</Text>
                        </Flex>}
                </Flex>
            )
        },
        {
            header: 'grupo',
            field: 'grupo',
            key: 'grupo',
            body: (rowData: any) => (
                <Flex
                    direction="column"
                    ml="10px"
                >
                    <Text
                        color="font"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        {rowData?.grupo?.nombre}
                    </Text>
                    <Text
                        color="#7A7D81"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        {rowData?.grupo?.curso?.nombre}
                    </Text>
                </Flex>
            )
        },
        {
            header: 'Importe',
            field: 'importe',
            key: 'importe',
            body: (rowData: LiquidacionesInt) =>
                <Text
                    p="16px"
                    fontFamily="inter"
                    color="font"
                    fontSize="14px"
                    fontWeight="400"
                    fontStyle="normal"
                    lineHeight="150%"
                >
                    {rowData?.asignacion?.importe || 0} €
                </Text>
        },
        {
            header: 'Progreso',
            field: 'progresoLecciones',
            key: 'progresoLecciones',
            style: { width: "200px" },
            body: (rowData: any) => (
                <Flex
                    gap="5px"
                    direction="column"
                    alignItems="start"
                    w="100%"
                >
                    <Text
                        color="font"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        {parseInt(rowData?.progresoLecciones) || 0} %
                    </Text>

                    <Flex
                        w="100%"
                    >
                        <Progress value={rowData?.progresoLecciones || 0} flex="1" h="7px" rounded="10px" sx={{ '& > div': { background: "main" } }} />
                    </Flex>
                </Flex>
            )
        },
    ]

    return columns
}