export enum TipoNivelEstudios {
    MENOS_QUE_PRIMARIA = 'menos_que_primaria',
    EDUCACION_PRIMARIA = 'educacion_primaria',
    PRIMERA_ETAPA_SECUNDARIA = 'primera_etapa_secundaria',
    SEGUNDA_ETAPA_SECUNDARIA = 'segunda_etapa_secundaria',
    POSTSECUNDARIA_NO_SUPERIOR = 'postsecundaria_no_superior',
    TECNICO_SUPERIOR = 'tecnico_superior',
    UNIVERSITARIO_PRIMER_CICLO = 'universitario_primer_ciclo',
    UNIVERSITARIO_SEGUNDO_CICLO = 'universitario_segundo_ciclo',
    UNIVERSITARIO_TERCER_CICLO = 'universitario_tercer_ciclo',
    OTRAS_TITULACIONES = 'otras_titulaciones'
}

export const NivelEstudiosOptions = [
    { value: TipoNivelEstudios.MENOS_QUE_PRIMARIA, label: 'Menos que primaria' },
    { value: TipoNivelEstudios.EDUCACION_PRIMARIA, label: 'Educación primaria' },
    { value: TipoNivelEstudios.PRIMERA_ETAPA_SECUNDARIA, label: 'Primera etapa de la educación secundaria' },
    { value: TipoNivelEstudios.SEGUNDA_ETAPA_SECUNDARIA, label: 'Segunda etapa de la educación secundaria' },
    { value: TipoNivelEstudios.POSTSECUNDARIA_NO_SUPERIOR, label: 'Educación postsecundaria no superior' },
    { value: TipoNivelEstudios.TECNICO_SUPERIOR, label: 'Técnico superior FP/FP grado superior o equivalentes' },
    { value: TipoNivelEstudios.UNIVERSITARIO_PRIMER_CICLO, label: 'Estudios universitarios 1er ciclo (diplomatura -grado)' },
    { value: TipoNivelEstudios.UNIVERSITARIO_SEGUNDO_CICLO, label: 'Estudios universitarios 2º ciclo (licenciatura -máster)' },
    { value: TipoNivelEstudios.UNIVERSITARIO_TERCER_CICLO, label: 'Estudios universitarios 3er ciclo (doctorado)' },
    { value: TipoNivelEstudios.OTRAS_TITULACIONES, label: 'Otras titulaciones' }
]