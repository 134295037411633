import { Flex, Image, Text, useColorMode } from "@chakra-ui/react"
import sinEntregables from "../../../../assets/img/noData6.png"
import sinEntregablesDark from "../../../../assets/img/noData-Dark6.png"

export const CardEntregablesSinMatricula = () => {
    const { colorMode } = useColorMode()
    
    return (
        <Flex
            p="20px"
            border="1px solid"
            borderColor={colorMode === "dark" ? "border_variant" : "border"}
            rounded="16px"
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            gap="16px"
            direction="column"
            minH="364px"
        >
            <Text
                color="font"
                fontSize="16px"
                fontWeight="500"
            >
                Últimos entregables
            </Text>

            <Flex
                direction="column"
                h="100%"
            >
                <Flex
                    py="8px"
                    px="16px"
                    bg={colorMode === "dark" ? "#262C36" : "#F8F9FB"}
                    w="100%"
                    rounded="12px"
                >
                    <Text
                        w="40%"
                        color={colorMode === "dark" ? "#DADEED" : "#666D80"}
                        fontSize="14px"
                        fontWeight="500"
                    >
                        Lección
                    </Text>

                    <Text
                        w="20%"
                        color={colorMode === "dark" ? "#DADEED" : "#666D80"}
                        fontSize="14px"
                        fontWeight="500"
                    >
                        Nota
                    </Text>

                    <Text
                        w="30%"
                        color={colorMode === "dark" ? "#DADEED" : "#666D80"}
                        fontSize="14px"
                        fontWeight="500"
                    >
                        Estado
                    </Text>

                    <Flex
                        w="10%"
                    />
                </Flex>

                <Flex
                    w="100%"
                    h="100%"
                    justifyContent="center"
                    alignItems="center"
                    gap="20px"
                    direction="column"
                >
                    <Image
                        src={colorMode === "dark" ? sinEntregablesDark : sinEntregables}
                        w="200px"
                        h="98px"
                    />

                    <Text
                        textAlign="center"
                        color="font"
                        fontSize="15px"
                        fontWeight="400"
                    >
                        Aquí verás tus entregables cuando estés matriculado en un curso. Esta sección te mostrará información importante sobre tus tareas y progreso una vez que comiences tu aprendizaje.
                    </Text>
                </Flex>

            </Flex>
        </Flex>
    )
}