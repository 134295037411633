import { Flex, Icon, Text, useColorMode } from "@chakra-ui/react";
import { BiChevronDown, BiFile } from "react-icons/bi";
import { BsDownload } from "react-icons/bs";
import { CorreccionInt } from "../../../../../interfaces/Entregables";
import MarkdownPreview from '@uiw/react-markdown-preview';
import { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"

interface Props {
    correccion: CorreccionInt | null;
}


export const CorreccionTutor = ({
    correccion
}: Props) => {
    const { colorMode } = useColorMode();
    const [detailsOpen, setDetailsOpen] = useState<boolean>(false)

    return (
        <Flex
            direction="column"
            w="100%"
            gap="10px"
        >

            <Flex
                alignItems="center"
                justifyContent="space-between"
            >
                <Text
                    fontSize="18px"
                    fontWeight="700"
                    color="font"
                >
                    Corrección de la entrega:
                </Text>

                <Icon
                    as={BiChevronDown}
                    boxSize="30px"
                    color="font"
                    cursor="pointer"
                    transform={detailsOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
                    transition="transform 0.3s ease"
                    _active={{ transform: "scale(0.9)" }}
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()

                        setDetailsOpen((prev: boolean) => !prev)
                    }}
                />
            </Flex>

            <AnimatePresence>
                {detailsOpen && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{
                            height: { duration: 0.4, ease: "easeInOut" },
                            opacity: { duration: 0.25, ease: "easeInOut" }
                        }}
                        style={{ overflow: "hidden" }}
                    >
                        <Flex
                            direction="column"
                            gap="25px"
                        >
                            <MarkdownPreview
                                source={correccion?.texto || 'El tutor ha revisado tu entrega. No se han dejado comentarios adicionales en esta corrección.'}
                                style={{
                                    fontFamily: "Inter",
                                    fontWeight: "400",
                                    fontSize: '13px',
                                    width: '100%',
                                    maxWidth: '100%',
                                    color: colorMode === "dark" ? "#FFFFFF" : "#121625",
                                    background: "transparent"
                                }}
                                wrapperElement={{ "data-color-mode": colorMode === "dark" ? "dark" : "light" }}
                            />

                            <Flex
                                direction="column"
                                p="15px"
                                bg={colorMode === "dark" ? "bg_dark_light" : "#F8F8F9"}
                                rounded="15px"
                                display={correccion?.adjunto ? "flex" : "none"}
                            >
                                <Flex
                                    w="100%"
                                    rounded="15px"
                                    position="relative"
                                    direction="column"
                                    gap="10px"
                                >
                                    <Flex
                                        h="80px"
                                        p="20px"
                                        border="1px solid"
                                        borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                                        rounded="10px"
                                        justifyContent="space-between"
                                    >
                                        <Flex
                                            alignItems="center"
                                            gap="10px"
                                        >
                                            <Icon
                                                as={BiFile}
                                                boxSize="24px"
                                                color={colorMode === "dark" ? "purewhite" : "secondary"}
                                            />

                                            <Flex
                                                direction="column"
                                                gap="4px"
                                            >
                                                <Text
                                                    fontSize="14px"
                                                    fontWeight="600"
                                                    lineHeight="140%"
                                                >
                                                    {correccion?.adjunto?.name}
                                                </Text>

                                                <Text
                                                    color="#8C909C"
                                                    fontSize="13px"
                                                    fontWeight="500"
                                                    lineHeight="16px"
                                                    letterSpacing={"-0.78px"}
                                                >
                                                    {correccion?.adjunto?.size} KB
                                                </Text>
                                            </Flex>
                                        </Flex>

                                        <Flex
                                            p="6px"
                                            justifyContent="center"
                                            alignItems="center"
                                            w="fit-content"
                                            h="fit-content"
                                            bg="purewhite"
                                            rounded="8px"
                                            cursor="pointer"
                                            _active={{ transform: "scale(0.9)" }}

                                            onClick={(e: React.MouseEvent) => {
                                                e.stopPropagation()

                                                if (correccion?.adjunto?.url) {
                                                    window.open(correccion.adjunto.url, '_blank')
                                                }
                                            }}
                                        >
                                            <Icon
                                                as={BsDownload}
                                                color="#8C909C"
                                                boxSize="24px"
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </motion.div>
                )}
            </AnimatePresence>
        </Flex>
    )
}