import { Flex, Icon, Text, useColorMode } from "@chakra-ui/react";
import { EntregablesInt } from "../../../../../interfaces/Entregables";
import { CustomColumnProps } from "../../../../../shared/components/Table/Table";
import { EntregableEstadoEnum } from "../../../../../shared/utils/types/EntregableEstadoEnum";
import { HiOutlineAnnotation, HiOutlineCheck, HiOutlineX } from "react-icons/hi";
import MarkdownPreview from "@uiw/react-markdown-preview"
import { format } from "../../../../../shared/utils/functions/formatDate";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

interface Props {
    redirectTo: (data: EntregablesInt) => string | null;
}

export const EntregableColumns = ({ redirectTo }: Props): CustomColumnProps[] => {
    const navigate = useNavigate()
    const [openComentarios, setOpenComentarios] = useState<string | null>(null);
    const { colorMode } = useColorMode();

    const columns: CustomColumnProps[] = [
        {
            field: 'leccion',
            header: 'Tarea',
            key: 'leccion',
            body: (rowData: EntregablesInt) =>
                <Text
                    p="16px"
                    fontFamily="inter"
                    color="font"
                    fontSize="14px"
                    fontWeight="400"
                    fontStyle="normal"
                    lineHeight="150%"
                    cursor={redirectTo(rowData) !== null ? "pointer" : "default"}
                    onClick={(e) => {
                        e.stopPropagation()
                        const url = redirectTo(rowData)
                        if (url) {
                            navigate(url)
                        }
                    }}
                >
                    {rowData?.leccion?.nombre}
                </Text>
        },
        {
            field: "estado",
            header: "Estado",
            key: "estado",
            body: (rowData: EntregablesInt) =>
                <Flex
                    p="16px"
                    justify="center"
                    cursor={redirectTo(rowData) !== null ? "pointer" : "default"}
                    onClick={(e) => {
                        e.stopPropagation()
                        const url = redirectTo(rowData)
                        if (url) {
                            navigate(url)
                        }
                    }}
                >
                    <Text
                        fontFamily="inter"
                        p="10px 15px"
                        rounded="full"
                        fontSize="12px"
                        fontWeight="500"
                        textTransform="capitalize"
                        border="1px solid"
                        borderColor={
                            rowData?.estado === EntregableEstadoEnum.ERROR ? 'fail'
                                : rowData?.estado === EntregableEstadoEnum.CORRECTO ? 'success_variant'
                                    : '#027DB2'
                        }
                        color={
                            rowData?.estado === EntregableEstadoEnum.ERROR ? 'fail'
                                : rowData?.estado === EntregableEstadoEnum.CORRECTO ? 'success_variant'
                                    : '#027DB2'
                        }
                    >
                        {
                            rowData?.estado === EntregableEstadoEnum.ERROR ? 'incorrecta'
                                : rowData?.estado === EntregableEstadoEnum.CORRECTO ? 'correcto'
                                    : "pendiente"
                        }
                    </Text>
                </Flex>
        },
        {
            field: "observaciones",
            header: "Observaciones",
            key: "observaciones",
            style: {
                width: "20%"
            },
            body: (rowData: EntregablesInt) =>
                <Flex
                    p="16px"
                    justify="center"
                    align="center"
                    gap="10px"
                    onClick={() => {
                        if (rowData?.correccion?.texto) {
                            if (openComentarios === rowData?.id) {
                                setOpenComentarios(null)
                            } else {
                                setOpenComentarios(rowData?.id || null)
                            }
                        }
                    }}
                    position="relative"
                >
                    <AnimatePresence>
                        {rowData?.id === openComentarios && (
                            <motion.div
                                initial={{ opacity: 0, height: 0, width: 0 }}
                                animate={{ opacity: 1, height: 'auto', width: 'auto' }}
                                exit={{ opacity: 0, height: 0, width: 0 }}
                                transition={{ duration: 0.3 }}
                                style={{
                                    position: 'fixed',
                                    top: 0,
                                    bottom: 0,
                                    right: 0,
                                    height: '100vh',
                                    zIndex: 10,
                                    backgroundColor: colorMode === "dark" ? "bg_dark_light" : "purewhite",
                                    borderRadius: '8px 0 0 8px',
                                    boxShadow: '-4px 0 6px rgba(0, 0, 0, 0.1)',
                                    overflow: 'hidden',
                                }}
                            >
                                <Flex
                                    bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                                >
                                    <Icon
                                        as={HiOutlineX}
                                        boxSize="20px"
                                        color="font"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setOpenComentarios(null)
                                        }}
                                        mt="20px"
                                        ml="20px"
                                        cursor="pointer"
                                    />
                                </Flex>

                                <Flex
                                    direction="column"
                                    px="40px"
                                    pt="20px"
                                    pb="40px"
                                    w="550px"
                                    h="100vh"
                                    overflowY="auto"
                                    bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                                    css={{
                                        '&::-webkit-scrollbar': {
                                            width: '8px',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            background: 'transparent',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            background: '#A3AED0',
                                            borderRadius: '4px',
                                        },
                                    }}
                                >
                                    <MarkdownPreview
                                        source={rowData?.correccion?.texto}
                                        style={{
                                            backgroundColor: colorMode === "dark" ? "#191D23" : "#FFFFFF",
                                            fontFamily: "Inter",
                                            color: colorMode === "dark" ? "#FFFFFF" : "#252F40",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "150%",
                                        }}
                                        wrapperElement={{ "data-color-mode": colorMode === "dark" ? "dark" : "light" }}
                                    />
                                </Flex>
                            </motion.div>
                        )}
                    </AnimatePresence>

                    <Icon
                        as={HiOutlineAnnotation}
                        boxSize="16px"
                        color={colorMode === "dark" ? "purewhite" : "secondary"}

                    />

                    <Text
                        cursor={rowData?.correccion?.texto ? "pointer" : "default"}
                        fontSize="14px"
                        fontWeight="400"
                        lineHeight="150%"
                    >
                        {rowData?.correccion?.texto ? 'Ver comentarios' : 'Aún no tienes observaciones'}
                    </Text>


                </Flex >
        },
        {
            field: "puntuacion",
            header: "Nota media",
            key: "puntuacion",
            body: (rowData: EntregablesInt) =>
                rowData?.correccion?.puntuacion ?
                    <Flex
                        p="16px"
                        textAlign="center"
                        justify="center"
                        align="center"
                        rounded="5px"
                        fontFamily="inter"
                        fontSize="14px"
                        fontWeight="400"
                        gap="3px"
                        color={colorMode === "dark" ? "purewhite" : "dark_grey"}
                        cursor={redirectTo(rowData) !== null ? "pointer" : "default"}
                        onClick={(e) => {
                            e.stopPropagation()
                            const url = redirectTo(rowData)
                            if (url) {
                                navigate(url)
                            }
                        }}
                    >
                        <Icon
                            as={rowData?.estado === EntregableEstadoEnum.CORRECTO ? HiOutlineCheck
                                : rowData?.estado === EntregableEstadoEnum.ERROR ? HiOutlineX
                                    : undefined
                            }
                            color={rowData?.estado === EntregableEstadoEnum.CORRECTO ? "success"
                                : rowData?.estado === EntregableEstadoEnum.ERROR ? "fail"
                                    : ""}


                        />
                        {rowData?.correccion?.puntuacion ? `${rowData?.correccion?.puntuacion}/100` : "0/100"}
                    </Flex>
                    :
                    <Text
                        p="16px"
                        fontFamily="inter"
                        fontSize="12"
                        fontWeight="700"
                        lineHeight="150%"
                        textAlign="center"
                    >
                        ---
                    </Text>
        },
        {
            field: "fechaEntrega",
            header: "Fecha de entrega",
            key: "fechaEntrega",
            body: (rowData: EntregablesInt) =>
                <Flex
                    p="16px"
                >
                    <Text
                        fontSize="12px"
                        fontWeight="400"
                        color="font"
                    >
                        {
                            format({
                                date: rowData?.fechaEntrega,
                                customFormat: "dd MMM yyyy HH:mm"
                            })
                        }
                    </Text>
                </Flex>
        },
    ]

    return columns
}