import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { RequireAuth } from "../RequireAuth/RequireAuth";
import { isRoleAllowed } from "../../utils/functions/validateRol";
import { useAuthContext } from "../../context/user.context";
import { UserRolEnum } from "../../utils/types/UserRolEnum";
import { Login } from "../../../pages/Login/Login";
import { EntregablesRoutes } from "../../../pages/Entregables/Entregables";
import { EvaluacionesRoutes } from "../../../pages/Evaluaciones/Evaluaciones";
import { LeccionesRoutes } from "../../../pages/Lecciones/Lecciones";
import ForoRoutes from "../../../pages/Foro/Foro";
import Mensajeria from "../../../pages/Mensajeria/views/Index";
import { PerfilRoutes } from "../../../pages/Perfil/Perfil";
import { NotasRoutes } from "../../../pages/Notas/Notas";
import { RutaRouter } from "../../../pages/Ruta/Ruta";
import { CursosPublicosRouter } from "../../../pages/CursosPublicos/CursosPublicos";
import { CuestionarioRoutes } from "../../../pages/Cuestionario/Cuestionario";
import { DashboardRoutes } from "../../../pages/Dashboard/Dashboard";
import { Recovery } from "../../../pages/Recovery/Recovery";
import MatriculasRoutes from "../../../pages/Matriculas/Matriculas";
import CorreccionesRoutes from "../../../pages/Correcciones/Correcciones";
import FacturacionRoutes from "../../../pages/Facturacion/Facturacion";
import AprendizajeRoutes from "../../../pages/Aprendizaje/Aprendizaje";
import { NuevasMatriculasRouter } from "../../../pages/NuevasMatriculas/NuevasMatriculas";
import { Quiz } from "../../../pages/Examenes/Quiz/Quiz";

export const RouterController = () => {
    const { user } = useAuthContext()
    const [search, _setSearch] = useSearchParams();

    return (
        <Routes>
            <Route
                path="/public/cursos/*"
                element={
                    <CursosPublicosRouter />
                }
            />

            <Route
                path="/public/nuevasMatriculas/*"
                element={
                    <NuevasMatriculasRouter />
                }
            />

            <Route
                path='recovery/*'
                element={
                    !user?.auth
                        ? <Recovery />
                        : user?.role?.nombre === UserRolEnum.TUTOR
                            ? <Navigate to={"/correcciones"} />
                            : <Navigate to={"/dashboard"} />
                }
            />

            <Route
                path='login/*'
                element={
                    (search.has("loginById") && search.has("token"))
                        ? <Login />
                        : !user?.auth
                            ? <Login />
                            : user?.role?.nombre === UserRolEnum.TUTOR
                                ? <Navigate to={"/correcciones"} />
                                : <Navigate to={"/dashboard"} />
                }
            />

            <Route
                path='*'
                element={
                    !user?.auth
                        ? <Navigate to={"/login"} />
                        : user?.role?.nombre === UserRolEnum.TUTOR
                            ? <Navigate to={"/correcciones"} />
                            : <Navigate to={"/dashboard"} />
                }
            />

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)
                        }
                    />
                }
            >
                <Route path='dashboard/*' element={<DashboardRoutes />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.TUTOR,
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)}
                    />
                }
            >
                <Route path='perfil/*' element={<PerfilRoutes />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)}
                    />
                }
            >
                <Route path='rutas/*' element={<RutaRouter />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.TUTOR,
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)}
                    />
                }
            >
                <Route path='aprendizaje/*' element={<AprendizajeRoutes />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.TUTOR,
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)}
                    />
                }
            >
                <Route path='aprendizaje/:cursoSlug/lecciones/*' element={<LeccionesRoutes />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)}
                    />
                }
            >
                <Route path='entregables/*' element={<EntregablesRoutes />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.TUTOR,
                        ], user?.role?.nombre) && (user?.tutorFreelance || false)}
                    />
                }
            >
                <Route path='facturacion/*' element={<FacturacionRoutes />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.TUTOR,
                        ], user?.role?.nombre)}
                    />
                }
            >
                <Route path='matriculas/*' element={<MatriculasRoutes />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.TUTOR,
                        ], user?.role?.nombre)}
                    />
                }
            >
                <Route path='correcciones/*' element={<CorreccionesRoutes />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.TUTOR,
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)}
                    />
                }
            >
                <Route path='mensajeria/*' element={<Mensajeria />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.TUTOR,
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)}
                    />
                }
            >

                <Route path='foro/*' element={<ForoRoutes />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)}
                    />
                }
            >

                <Route path='evaluaciones/*' element={<EvaluacionesRoutes />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)}
                    />
                }
            >

                <Route path='examenes/:slug' element={<Quiz />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ADMIN,
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)}
                    />
                }
            >

                <Route path='notas/*' element={<NotasRoutes />} />
            </Route>

            <Route
                element={
                    <RequireAuth
                        isRoleAllowed={isRoleAllowed([
                            UserRolEnum.ALUMNO,
                        ], user?.role?.nombre)}
                    />
                }
            >
                <Route path='cuestionario/*' element={<CuestionarioRoutes />} />
            </Route>
        </Routes>
    );
};