import { Box, Button, Flex, Icon, Image, Text, useColorMode } from "@chakra-ui/react"
import { ExamenInt } from "../../../interfaces/ExamenInt";
import { BiArrowBack, BiAward, BiDownload } from "react-icons/bi";

interface Props {
    outExamen: () => void;
    examen: ExamenInt;
}

export const ExamenAprobado = ({ outExamen, examen }: Props) => {
    const { colorMode } = useColorMode()
    
    // const { user } = useAuthContext();

    // const matriculaActual = {
    //     grupo: {
    //         curso: {
    //             nombre: "Curso de prueba"
    //         },
    //         modalidad: "presencial",
    //         fechaInicio: new Date().toISOString(),
    //         fechaFin: new Date().toISOString(),
    //         accionFormativa: "123456",
    //         codigoGrupo: "123456"
    //     }
    // }

    return (
        <Flex
            direction="column"
            gap="40px"
            alignItems="center"
            justifyContent="center"
            h="full"
        >
            {examen?.curso?.icono?.url &&
                <Flex
                    position="relative"
                >
                    <Flex
                        h="125px"
                        w="125px"
                        rounded="20px"
                        overflow="hidden"
                        bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                        boxShadow="-4px -4px 20px 0px rgba(0, 0, 0, 0.01), 0px 4px 20px 0px rgba(0, 0, 0, 0.01)"
                    >
                        <Image
                            objectFit={"cover"}
                            src={examen?.curso?.icono?.url}
                        />
                    </Flex>

                    <Flex
                        rounded="100%"
                        boxSize="55px"
                        bg="main"
                        position="absolute"
                        justifyContent="center"
                        alignItems="center"
                        border="5px solid"
                        borderColor={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                        bottom="-22px"
                        right="-22px"
                        zIndex="999"
                    >
                        <Icon
                            as={BiAward}
                            color="white"
                            boxSize="30px"
                        />
                    </Flex>
                </Flex>
            }

            <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                gap="20px"
            >
                <Text
                    fontSize="24px"
                    lineHeight="29.05px"
                    fontWeight="700"
                >
                    ¡Has superado la prueba!
                </Text>

                <Text
                    fontSize="15px"
                    lineHeight="29.05px"
                    fontWeight="500"
                >
                    ¡Enhorabuena! Has logrado superar la prueba para obtener la certificación de {examen?.curso?.nombre}.
                </Text>
            </Flex>

            {/* <Flex
                direction="column"
                gap="20px"
            >
                <Flex
                    gap="50px"
                    alignItems={"center"}
                    bg="purewhite"
                    border="1px solid"
                    borderColor="#E6E6EA"
                    p="20px"
                    rounded="20px"
                    boxShadow="-4px -4px 20px 0px rgba(0, 0, 0, 0.04), 0px 4px 20px 0px rgba(0, 0, 0, 0.04)"
                >
                    <Flex
                        direction="column"
                        gap="15px"
                    >
                        <Flex
                            gap="10px"
                        >
                            <Text color="font" fontSize="15px" fontWeight="400">Nombre:</Text>
                            <Text color="font" fontSize="15px" fontWeight="700">{user?.nombre} {user?.apellidos}</Text>
                        </Flex>

                        <Flex
                            gap="10px"
                        >
                            <Text color="font" fontSize="15px" fontWeight="400">NIF:</Text>
                            <Text color="font" fontSize="15px" fontWeight="700">{user?.dni}</Text>
                        </Flex>

                        <Flex
                            gap="10px"
                        >
                            <Text color="font" fontSize="15px" fontWeight="400">Empresa:</Text>
                            <Text color="font" fontSize="15px" fontWeight="700">{user?.empresa?.nombre}</Text>
                        </Flex>

                        <Flex
                            gap="10px"
                        >
                            <Text color="font" fontSize="15px" fontWeight="400">CIF:</Text>
                            <Text color="font" fontSize="15px" fontWeight="700">{user?.empresa?.cif}</Text>
                        </Flex>
                    </Flex>

                    <Flex
                        direction="column"
                        gap="15px"
                    >
                        <Flex
                            gap="10px"
                        >
                            <Text color="font" fontSize="15px" fontWeight="400">Fecha de inicio:</Text>
                            <Text color="font" fontSize="15px" fontWeight="700">{matriculaActual?.grupo?.fechaInicio ? format({ date: matriculaActual?.grupo?.fechaInicio, customFormat: "dd/MM/yyyy" }) : "---"}</Text>
                        </Flex>

                        <Flex
                            gap="10px"
                        >
                            <Text color="font" fontSize="15px" fontWeight="400">Fecha de fin:</Text>
                            <Text color="font" fontSize="15px" fontWeight="700">{matriculaActual?.grupo?.fechaFin ? format({ date: matriculaActual?.grupo?.fechaFin, customFormat: "dd/MM/yyyy" }) : "---"}</Text>
                        </Flex>

                        <Flex
                            gap="10px"
                        >
                            <Text color="font" fontSize="15px" fontWeight="400">Código AF:</Text>
                            <Text color="font" fontSize="15px" fontWeight="700">{matriculaActual?.grupo?.accionFormativa}</Text>
                        </Flex>

                        <Flex
                            gap="10px"
                        >
                            <Text color="font" fontSize="15px" fontWeight="400">Código Grupo:</Text>
                            <Text color="font" fontSize="15px" fontWeight="700">{matriculaActual?.grupo?.codigoGrupo}</Text>
                        </Flex>
                    </Flex>
                </Flex>

                <Text
                    color="secondary"
                    fontSize="18px"
                    fontWeight="700"
                >
                    ATENCIÓN: <Text as="span" color="font" fontSize="16px" fontWeight="400"> Revisa tus datos antes de generar el diploma definitivo.</Text>
                </Text>
            </Flex> */}

            <Flex
                gap="20px"
            >
                <Button
                    color="#FFF"
                    fontSize="18px"
                    fontWeight="700"
                    bg={colorMode === "dark" ? "main" : "secondary"}
                    w="fit-content"
                    h="fit-content"
                    rounded="14px"
                    py="15px"
                    px="24px"
                    _hover={{ bg: colorMode === "dark" ? "variant" : "secondary" }}
                    cursor="pointer"
                    leftIcon={
                        <Icon
                            as={BiArrowBack}
                            boxSize="24px"
                        />
                    }
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()

                        outExamen()
                    }}
                >
                    Salir
                </Button>
            </Flex>
        </Flex>
    )
}