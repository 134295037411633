import { As, Flex, Icon, Skeleton, Text, useColorMode } from "@chakra-ui/react";
import { MatriculasInt } from "../../../interfaces/MatriculasInt";
import { PiGraduationCap, PiCertificate } from "react-icons/pi";
import { BiBookAlt } from "react-icons/bi";

export enum TypeProgresoEnum {
    ACTIVIDADES,
    EJERCICIOS,
    NOTA,
}

interface Props {
    matricula: MatriculasInt | undefined;
    typeProgreso: TypeProgresoEnum;
}

export const CardProgreso = ({
    matricula,
    typeProgreso,
}: Props) => {
    const { colorMode } = useColorMode()
    const data: Record<TypeProgresoEnum, {
        label: string;
        value: number;
        icon: As;
        isAllow: boolean;
    }> = {
        [TypeProgresoEnum.ACTIVIDADES]: {
            label: "Actividades completas",
            value: parseInt((matricula?.progresoLecciones || 0)?.toFixed(0)),
            icon: PiGraduationCap,
            isAllow: true
        },
        [TypeProgresoEnum.EJERCICIOS]: {
            label: "Ejercicios completados",
            value: parseInt((matricula?.progresoEntregables || 0).toFixed(0)),
            icon: PiCertificate,
            isAllow: matricula?.grupo?.curso?.tutorizado ? true : false
        },
        [TypeProgresoEnum.NOTA]: {
            label: "Nota media",
            value: parseInt((matricula?.puntuacionMedia || 0)?.toFixed(0)),
            icon: BiBookAlt,
            isAllow: matricula?.grupo?.curso?.tutorizado ? true : false
        },
    }

    return (
        matricula ?
            <Flex
                display={data[typeProgreso]?.isAllow ? "flex" : "none"}
                p="12px"
                bg={colorMode === "dark" ? "bg_dark_light" : "#F8F9FB"}
                rounded="12px"
                gap="12px"
                alignItems="center"
                flex="1"
            >
                <Flex
                    h="fit-content"
                    w="fit-content"
                    p="8px"
                    bg="main"
                    rounded="8px"
                >
                    <Icon
                        as={data[typeProgreso]?.icon}
                        boxSize="25px"
                        color="purewhite"
                    />
                </Flex>

                <Flex
                    direction="column"
                >
                    <Text
                        color="font"
                        fontSize="20px"
                        fontWeight={"600"}
                    >
                        {data[typeProgreso]?.value}%
                    </Text>

                    <Text
                        color={colorMode === "dark" ? "#DADEED" : "#666D80"}
                        fontSize="14px"
                        fontWeight="400"
                        letterSpacing="-0.5px"
                    >
                        {data[typeProgreso]?.label}
                    </Text>
                </Flex>
            </Flex>
            :

            <Skeleton
                w="100%"
                h="75px"
                flex="1"
                rounded="12px"
            />
    )
}