import { Drawer, DrawerBody, DrawerOverlay, DrawerContent, Flex, Icon, Text, Tooltip } from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { useData } from '../../hooks/useData';
import { EndpointTypes } from '../../utils/types/EndpointTypes';
import { NotificacionesInt } from '../../../interfaces/NotificacionesInt';
import { HiOutlineAnnotation, HiOutlineBookOpen, HiOutlineDocumentText } from 'react-icons/hi';
import { format } from '../../utils/functions/formatDate';
import { useAuthContext } from '../../context/user.context';
import { UserRolEnum } from '../../utils/types/UserRolEnum';
import { GrupoInt, MatriculasInt } from '../../../interfaces/MatriculasInt';
import { BsFillCheckSquareFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { EstadoTypeEnum } from '../../utils/types/EstadoTypeEnum';
import { getData } from '../../middlewares/middlewares';
import tenantInstance from '../../services/tenantInstance.service';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export default function NotificacionesDrawer({
    isOpen,
    onClose
}: Props) {
    const { user } = useAuthContext()
    const navigate = useNavigate()
    const { data: notificaciones } = useData({
        endpoint: EndpointTypes.NOTIFICACIONES
    })

    const { data: matriculas } = useData({
        endpoint: EndpointTypes.MATRICULAS_ALUMNOS,
        ignoreRequest: !user?.auth || user?.role?.nombre !== UserRolEnum.ALUMNO,
        query: {
            limit: 100
        }
    });

    const { data: grupos } = useData({
        endpoint: EndpointTypes.GRUPOS_TUTOR,
        ignoreRequest: !user?.auth || user?.role?.nombre !== UserRolEnum.TUTOR,
    })

    const notificacionesGroup = notificaciones?.data?.reduce(
        (acc: Record<string, { notificaciones: NotificacionesInt[], grupo: GrupoInt }>, n: NotificacionesInt) => {
            let curso;

            if (user?.role?.nombre === UserRolEnum.ALUMNO) {
                const matricula = matriculas?.data?.find((m: MatriculasInt) => m?.grupoId === n?.objeto?.grupoId)
                curso = matricula?.grupo?.curso?.nombre

                if (!acc[curso]) {
                    acc[curso] = {
                        notificaciones: [],
                        grupo: matricula?.grupo
                    }
                }
            } else {
                const grupo = grupos?.find((g: GrupoInt) => g?.id === n?.objeto?.grupoId)
                curso = grupo?.curso?.nombre

                if (!acc[curso]) {
                    acc[curso] = {
                        notificaciones: [],
                        grupo: grupo
                    }
                }
            }

            acc[curso]?.notificaciones?.push(n)
            return acc
        }, {}
    )

    const handleClick = async (n: NotificacionesInt) => {

        const matricula = matriculas?.data?.find((m: MatriculasInt) => m?.grupoId === n?.objeto?.grupoId);

        if (matricula?.estado !== EstadoTypeEnum.ACTIVO) return;

        if (n.objeto.tipo === "Curso") {
            navigate(`/aprendizaje/${n.objeto.slug}`);
        } else if (n.objeto.tipo === "Entregable") {
            const { tenant, client } = await tenantInstance()
            const { data } = await getData(`${tenant}/${client}/entregables/${n?.objeto?.id}`)
            const leccionSlug = data?.data?.leccion?.slug

            navigate(`/aprendizaje/${matricula?.grupo?.curso?.slug}/lecciones/${leccionSlug}?grupo=${matricula?.grupo?.slug}`);
        }
    }

    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            size="lg"
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerBody
                    p="20px"
                >
                    <Tabs>
                        <TabList
                            display="flex"
                            alignItems="center"
                        >
                            <Tab
                                color="#6F6F6F"
                                fontSize="16px"
                                fontWeight="600"
                                lineHeight="155%"
                                _selected={{ color: "main", borderColor: "main" }}
                            >
                                Todas
                            </Tab>

                            {/* <Tab
                                color="#6F6F6F"
                                fontSize="16px"
                                fontWeight="600"
                                lineHeight="155%"
                                _selected={{ color: "main", borderColor: "main" }}
                            >
                                No leídas
                            </Tab>

                            <Flex
                                ml="auto"
                                gap="12px"
                                alignItems="center"
                                color="main"
                                cursor="pointer"
                                _hover={{
                                    textDecoration: "underline"
                                }}
                            >
                                <Icon
                                    boxSize="18px"
                                    as={BsFillCheckSquareFill}
                                />

                                <Text
                                    fontSize="14px"
                                    fontWeight="400"
                                    lineHeight="155%"
                                >
                                    Marcar como leídas
                                </Text>
                            </Flex> */}
                        </TabList>

                        <TabPanels>
                            <TabPanel
                                display="flex"
                                flexDirection="column"
                                p="0"
                                mt="20px"
                                gap="20px"
                            >
                                {Object.entries(notificacionesGroup || {})?.map(([key, value], iKey: number) => (
                                    <Flex
                                        direction="column"
                                        gap="12px"
                                        key={iKey}
                                    >
                                        <Text
                                            color="font"
                                            fontSize="14px"
                                            fontWeight="500"
                                            lineHeight="155%"
                                        >
                                            {key}
                                        </Text>

                                        <Flex
                                            direction="column"
                                            gap="16px"
                                        >
                                            {(value as { notificaciones: NotificacionesInt[] })?.notificaciones?.map((n: NotificacionesInt, iValue: number) => {
                                                const matriculaActual = matriculas?.data?.find((m: MatriculasInt) => m?.grupoId === n?.objeto?.grupoId);
                                                const estaActiva = matriculaActual?.estado === EstadoTypeEnum.ACTIVO;

                                                return (
                                                    <Tooltip
                                                        label="Esta matrícula está inactiva"
                                                        isDisabled={estaActiva}
                                                    >
                                                        <Flex
                                                            key={iValue}
                                                            onClick={() => handleClick(n)}
                                                            cursor={estaActiva ? "pointer" : "default"}
                                                            opacity={estaActiva ? 1 : 0.5}
                                                        >
                                                            <Flex
                                                                gap="12px"
                                                                alignItems="center"
                                                            >
                                                                <Flex
                                                                    bg="main"
                                                                    rounded="100%"
                                                                    boxSize="48px"
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                >
                                                                    <Icon
                                                                        as={
                                                                            n?.objeto?.tipo === "Curso" ? HiOutlineBookOpen
                                                                                : n?.objeto?.tipo === "Entregable"
                                                                                    ? HiOutlineDocumentText
                                                                                    : HiOutlineAnnotation
                                                                        }
                                                                        boxSize="20px"
                                                                        color="purewhite"
                                                                    />
                                                                </Flex>

                                                                <Flex
                                                                    gap="4px"
                                                                    direction="column"
                                                                >
                                                                    <Text
                                                                        color="font"
                                                                        fontSize="15px"
                                                                        fontWeight="600"
                                                                        lineHeight="155%"
                                                                    >
                                                                        {
                                                                            n?.objeto?.tipo === "Curso" ? `Nueva inscripción al curso de ${(value as { grupo: GrupoInt })?.grupo?.curso?.nombre}`
                                                                                : n?.objeto?.tipo === "Pregunta" && n?.objeto?.numero > 1 ?
                                                                                    `${n?.objeto?.numero} nuevas respuestas a tu pregunta del curso ${(value as { grupo: GrupoInt })?.grupo?.curso?.nombre}`
                                                                                    : n?.objeto?.tipo === "Pregunta" && (!n?.objeto?.numero || n?.objeto?.numero === 1) ?
                                                                                        `Nueva respuesta a tu pregunta del curso ${(value as { grupo: GrupoInt })?.grupo?.curso?.nombre}`
                                                                                        : n?.objeto?.tipo === "Entregable" && n?.objeto?.numero > 1 ?
                                                                                            `Tienes correcciones realizadas sobre entregables del curso ${(value as { grupo: GrupoInt })?.grupo?.curso?.nombre}`
                                                                                            : `Nueva corrección de entrega del curso ${(value as { grupo: GrupoInt })?.grupo?.curso?.nombre}`
                                                                        }
                                                                    </Text>

                                                                    <Text
                                                                        color="#6F6F6F"
                                                                        fontSize="12px"
                                                                        fontWeight="500"
                                                                        lineHeight="155%"
                                                                    >
                                                                        {format({ date: n?.createdAt, customFormat: "dd/MM/yyyy HH:mm" })}
                                                                    </Text>
                                                                </Flex>
                                                            </Flex>
                                                        </Flex>
                                                    </Tooltip>
                                                )
                                            })}
                                        </Flex>
                                    </Flex>
                                ))}
                            </TabPanel>

                            <TabPanel
                                display="flex"
                                flexDirection="column"
                                p="0"
                                mt="20px"
                                gap="20px"
                            >
                                {Object.entries(notificacionesGroup || {})?.map(([key, value], iKey: number) => (
                                    <Flex
                                        direction="column"
                                        gap="12px"
                                        key={iKey}
                                    >
                                        <Text
                                            color="font"
                                            fontSize="14px"
                                            fontWeight="500"
                                            lineHeight="155%"
                                        >
                                            {key}
                                        </Text>

                                        <Flex
                                            direction="column"
                                            gap="16px"
                                        >
                                            {(value as { notificaciones: NotificacionesInt[] })?.notificaciones?.map((n: NotificacionesInt, iValue: number) => {
                                                const matriculaActual = matriculas?.data?.find((m: MatriculasInt) => m?.grupoId === n?.objeto?.grupoId);
                                                const estaActiva = matriculaActual?.estado === EstadoTypeEnum.ACTIVO;

                                                return (
                                                    <Tooltip
                                                        label="Esta matrícula está inactiva"
                                                        isDisabled={estaActiva}
                                                    >
                                                        <Flex
                                                            key={iValue}
                                                            onClick={() => handleClick(n)}
                                                            cursor={estaActiva ? "pointer" : "default"}
                                                            opacity={estaActiva ? 1 : 0.5}
                                                        >
                                                            <Flex
                                                                gap="12px"
                                                                alignItems="center"
                                                            >
                                                                <Flex
                                                                    bg="main"
                                                                    rounded="100%"
                                                                    boxSize="48px"
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                >
                                                                    <Icon
                                                                        as={
                                                                            n?.objeto?.tipo === "Curso" ? HiOutlineBookOpen
                                                                                : n?.objeto?.tipo === "Entregable"
                                                                                    ? HiOutlineDocumentText
                                                                                    : HiOutlineAnnotation
                                                                        }
                                                                        boxSize="20px"
                                                                        color="purewhite"
                                                                    />
                                                                </Flex>

                                                                <Flex
                                                                    gap="4px"
                                                                    direction="column"
                                                                >
                                                                    <Text
                                                                        color="font"
                                                                        fontSize="15px"
                                                                        fontWeight="600"
                                                                        lineHeight="155%"
                                                                    >
                                                                        {
                                                                            n?.objeto?.tipo === "Curso" ? `Nueva inscripción al curso de ${(value as { grupo: GrupoInt })?.grupo?.curso?.nombre}`
                                                                                : n?.objeto?.tipo === "Pregunta" && n?.objeto?.numero > 1 ?
                                                                                    `${n?.objeto?.numero} nuevas respuestas a tu pregunta del curso ${(value as { grupo: GrupoInt })?.grupo?.curso?.nombre}`
                                                                                    : n?.objeto?.tipo === "Pregunta" && (!n?.objeto?.numero || n?.objeto?.numero === 1) ?
                                                                                        `Nueva respuesta a tu pregunta del curso ${(value as { grupo: GrupoInt })?.grupo?.curso?.nombre}`
                                                                                        : n?.objeto?.tipo === "Entregable" && n?.objeto?.numero > 1 ?
                                                                                            `Tienes correcciones realizadas sobre entregables del curso ${(value as { grupo: GrupoInt })?.grupo?.curso?.nombre}`
                                                                                            : `Nueva corrección de entrega del curso ${(value as { grupo: GrupoInt })?.grupo?.curso?.nombre}`
                                                                        }
                                                                    </Text>

                                                                    <Text
                                                                        color="#6F6F6F"
                                                                        fontSize="12px"
                                                                        fontWeight="500"
                                                                        lineHeight="155%"
                                                                    >
                                                                        {format({ date: n?.createdAt, customFormat: "dd/MM/yyyy HH:mm" })}
                                                                    </Text>
                                                                </Flex>
                                                            </Flex>
                                                        </Flex>
                                                    </Tooltip>
                                                )
                                            })}
                                        </Flex>
                                    </Flex>
                                ))}
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
