import { CursosInt, LeccionInt, ModulosInt } from "../../../interfaces/CursosInt";
import { sortByOrder } from "./sortBy";

export const validateProgressModule = (cursoProgress: any, modulo: any) => {
    const totalLecciones = modulo?.lecciones?.length;
    let completed = 0;

    modulo?.lecciones?.forEach((leccion: any) => {
        if (Reflect.has(cursoProgress?.data || {}, leccion?.id)) {
            if (cursoProgress?.data[leccion?.id]?.estado === "finalizacion") {
                completed = completed + 1
            }
        }
    });

    if (completed > 0 && totalLecciones) {
        return (completed / totalLecciones) * 100

    } else {
        return 0;
    }
};

export const checkLessonComplete = (cursoProgress: any, leccionId: string) => {
    if (checkLessonStart(cursoProgress, leccionId))
        return cursoProgress[leccionId]?.estado === "finalizacion"

    return false
};

export const checkLessonStart = (cursoProgress: any, leccionId: string) => Reflect.has(cursoProgress || {}, leccionId)

export const onContinueLeccion = (cursoProgress: any, curso: CursosInt): {
    leccion: LeccionInt | undefined,
    modulo: ModulosInt | undefined,
} => {
    let leccion: LeccionInt | undefined;
    let selectedModulo: ModulosInt | undefined;
    let totalLecciones = 0;

    const notProgress = cursoProgress === undefined || Object.keys(cursoProgress || {}).length === 0;

    if (notProgress) {
        selectedModulo = curso?.modulos && curso?.modulos?.length !== 0 ? curso?.modulos[0] : undefined;
        leccion = selectedModulo?.lecciones && selectedModulo?.lecciones?.length !== 0 ? selectedModulo?.lecciones[0] : undefined;

        return {
            leccion: leccion,
            modulo: selectedModulo,
        }
    }

    let encontrado = false;
    sortByOrder(curso?.modulos)?.some((modulo: ModulosInt) => {
        if (encontrado) return true;
        
        return sortByOrder(modulo.lecciones)?.some((l: LeccionInt) => {
            totalLecciones = totalLecciones + 1;

            if (!checkLessonComplete(cursoProgress, l?.id)) {
                leccion = l;
                selectedModulo = modulo;
                encontrado = true;
                return true;
            }
            return false;
        });
    });

    if (!selectedModulo && !leccion) {
        selectedModulo = curso?.modulos?.[curso?.modulos?.length - 1];
        const todasLeccionesCompletadas = Object.keys(cursoProgress || {}).length === totalLecciones;
        
        leccion = selectedModulo?.lecciones?.[
            todasLeccionesCompletadas ? 
            (selectedModulo.lecciones.length - 1) : 0
        ];
    }

    return {
        leccion: leccion,
        modulo: selectedModulo,
    }
}