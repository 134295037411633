import { Box, Flex, useColorMode, useToast } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { BiNote } from "react-icons/bi"
import { GrCertificate } from "react-icons/gr";
import { isRoleAllowed } from "../../utils/functions/validateRol";
import { useAuthContext } from "../../context/user.context";
import { HiOutlineBookOpen, HiOutlineCalendar, HiOutlineChat, HiOutlineDocumentDuplicate, HiOutlineTerminal, HiOutlineUserGroup, HiTrendingUp } from "react-icons/hi";
import { LogoEmpresa } from "./components/LogoEmpresa";
import { useTenantInfo } from "../../hooks/useTenantInfo";
import { EndpointTypes } from "../../utils/types/EndpointTypes";
import { UserRolEnum } from "../../utils/types/UserRolEnum";
import { useData } from "../../hooks/useData";
import { NavLink } from "./components/NavLink";
import { UserActions } from "./components/UserActions";

export const Sidebar = () => {
    const { handleGetInfo } = useTenantInfo();
    const { user, authToken } = useAuthContext();
    const location = useLocation();
    const hideInPaths = location.pathname.includes("/lecciones") || location.pathname.includes("/public") || location.pathname.includes("/examenes")
    const { colorMode } = useColorMode()
    const { data: rutas } = useData({
        endpoint: EndpointTypes.RUTAS,
        ignoreRequest: !user?.auth || !authToken,
    })

    const icono = handleGetInfo({ key: "icono" });

    const activePath = (text: string): boolean => {
        if (text === "/") {
            if (location.pathname === "/")
                return true
            return false
        } else if (text === "/aprendizaje") {
            if (location.pathname.includes(text) || location.pathname.startsWith("/leccion"))
                return true
            return false
        } else {
            if (location.pathname.includes(text)) { return true }
            else { return false }
        }
    };

    return (
        !hideInPaths ?
            <Flex
                h="100vh"
                bg={colorMode === "dark" ? "bg_dark_variant" : "purewhite"}
                borderRight="1px solid"
                borderColor={colorMode === "dark" ? "#1C1B21" : "#E6E6EA"}
            >
                <Flex
                    direction="column"
                    gap="20px"
                >
                    <LogoEmpresa
                        data={icono}
                    />

                    <Flex
                        direction="column"
                        w="100%"
                        gap="7px"
                    >
                        {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                            <NavLink path={"/dashboard"} icon={HiTrendingUp} label={"Resumen"} active={activePath("/dashboard")} />
                        }


                        {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO, UserRolEnum.TUTOR], user?.role?.nombre) &&
                            <NavLink path={"/aprendizaje"} icon={HiOutlineBookOpen} label={"Cursos"} active={activePath("/aprendizaje")} />
                        }

                        {rutas?.meta?.total > 0 &&
                            isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                            <NavLink path={"/rutas"} icon={HiOutlineTerminal} label={"Rutas"} active={activePath("/rutas")} />
                        }

                        {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.TUTOR], user?.role?.nombre) &&
                            user?.tutorFreelance &&
                            <NavLink path={"/facturacion"} icon={HiOutlineDocumentDuplicate} label={"Facturación"} active={activePath("/facturacion")} />
                        }


                        {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.TUTOR], user?.role?.nombre) &&
                            <NavLink path={"/matriculas"} icon={HiOutlineUserGroup} label={"Matrículas"} active={activePath("/matriculas")} />
                        }

                        {user?.role?.nombre === UserRolEnum.ALUMNO ?
                            user?.campusTutorizado &&
                            isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                            <NavLink path={"/entregables"} icon={HiOutlineDocumentDuplicate} label={"Entregas"} active={activePath("/entregables")} />
                            :
                            isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                            <NavLink path={"/entregables"} icon={HiOutlineDocumentDuplicate} label={"Entregas"} active={activePath("/entregables")} />
                        }

                        {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.TUTOR], user?.role?.nombre) &&
                            <NavLink path={"/correcciones"} icon={HiOutlineCalendar} label={"Correcciones"} active={activePath("/correcciones")} />
                        }

                        {user?.role?.nombre === UserRolEnum.ALUMNO ?
                            user?.campusTutorizado &&
                            isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO, UserRolEnum.TUTOR], user?.role?.nombre) &&
                            <NavLink path={"/foro"} icon={HiOutlineUserGroup} label={"Foro"} active={activePath("/foro")} />
                            :
                            isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO, UserRolEnum.TUTOR], user?.role?.nombre) &&
                            <NavLink path={"/foro"} icon={HiOutlineUserGroup} label={"Foro"} active={activePath("/foro")} />

                        }

                        {user?.role?.nombre === UserRolEnum.ALUMNO ?
                            user?.campusTutorizado &&
                            isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO, UserRolEnum.TUTOR], user?.role?.nombre) &&
                            <NavLink path={"/mensajeria"} icon={HiOutlineChat} label={"Mensajería"} active={activePath("/mensajeria")} />
                            :
                            isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO, UserRolEnum.TUTOR], user?.role?.nombre) &&
                            <NavLink path={"/mensajeria"} icon={HiOutlineChat} label={"Mensajería"} active={activePath("/mensajeria")} />
                        }

                        {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                            <NavLink path={"/evaluaciones"} icon={GrCertificate} label={"Evaluaciones"} active={activePath("/evaluaciones")} />
                        }

                        {isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) &&
                            <NavLink path={"/notas"} icon={BiNote} label={"Notas"} active={activePath("/notas")} />
                        }

                    </Flex>

                    <UserActions />
                </Flex>
            </Flex>
            :

            <Box display="none" />
    );
};