import { Flex, Icon, Img, Text, Tooltip, useColorMode } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { IoTimeOutline } from "react-icons/io5";
import portadaSinCurso from "../../../assets/img/portadaSinCurso.png"
import { fromSecondToMin } from "../../../shared/utils/functions/formatters";
import { EvaluacionesInt } from "../../../interfaces/EvaluacionesInt";
import { BiBook, BiGroup, BiRevision } from "react-icons/bi";
import { LiaAwardSolid } from "react-icons/lia";

interface Props {
    evaluacion: EvaluacionesInt;
}

export default function CardEvaluaciones({ evaluacion }: Props) {
    const navigate = useNavigate();
    const { colorMode } = useColorMode()

    return (
        <Flex
            direction="column"
            display="flex"
            rounded="12px"
            border="1px solid"
            borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
            cursor="pointer"
            w="full"
            maxW="525px"
            transition="all 0.5s"
            _hover={{
                transform: "scale(1.01)",
            }}
            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            pt="20px"
            p="24px"
            gap="24px"
            position="relative"
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.stopPropagation();

                navigate(`/aprendizaje/${evaluacion?.matricula?.grupo?.curso?.slug}?grupo=${evaluacion?.matricula?.grupo?.slug}`);
            }}
        >
            <Flex
                direction="column"
                gap="20px"
            >
                <Text
                    fontSize="18px"
                    fontWeight="600"
                    color="font"
                >
                    {evaluacion?.examen?.nombre}
                </Text>

                <Flex
                    alignItems="center"
                    gap="20px"
                >
                    <Img
                        w="full"
                        boxSize="150px"
                        objectFit="cover"
                        rounded="12px"
                        src={evaluacion?.examen?.imagen?.url || evaluacion?.examen?.curso?.imagen?.url ? evaluacion?.examen?.imagen?.url || evaluacion?.examen?.curso?.imagen?.url : portadaSinCurso}
                    />

                    <Flex
                        direction="column"
                        gap="10px"
                    >
                        <Flex
                            alignItems="center"
                            gap="5px"
                        >
                            <Icon
                                as={BiBook}
                                color="font"
                                boxSize="20px"
                            />

                            <Text
                                color="font"
                                fontSize="14px"
                                fontWeight="400"
                            >
                                {evaluacion?.matricula?.grupo?.curso?.nombre}
                            </Text>
                        </Flex>

                        <Flex
                            alignItems="center"
                            gap="5px"
                        >
                            <Icon
                                as={BiGroup}
                                color="font"
                                boxSize="20px"
                            />

                            <Text
                                color="font"
                                fontSize="14px"
                                fontWeight="400"
                            >
                                {evaluacion?.matricula?.grupo?.nombre}
                            </Text>
                        </Flex>

                        <Flex
                            alignItems="center"
                            gap="5px"
                        >
                            <Icon
                                as={BiRevision}
                                color="font"
                                boxSize="20px"
                            />

                            <Text
                                color="font"
                                fontSize="14px"
                                fontWeight="400"
                            >
                                Intento {evaluacion?.numIntento} de {evaluacion?.examen?.numIntentos}
                            </Text>
                        </Flex>

                        <Flex
                            alignItems="center"
                            gap="5px"
                        >
                            <Icon
                                as={IoTimeOutline}
                                color="font"
                                boxSize="20px"
                            />

                            <Text
                                color="font"
                                fontSize="14px"
                                fontWeight="400"
                            >
                                {fromSecondToMin(evaluacion?.examen?.duracion || 0)}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Tooltip
                label={"Examen aprobado"}
                placement="right"
                hasArrow
                isDisabled={!evaluacion?.aprobado}
                py="5px"
                px="10px"
                bg="main"
                color="purewhite"
                fontSize="14px"
                fontWeight="500"
                rounded="6px"
                textAlign="center"
            >
                <Flex
                    display={evaluacion?.aprobado ? "flex" : "none"}
                    boxSize="40px"
                    bg="main"
                    rounded="full"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    top="10px"
                    right="10px"
                >
                    <Icon
                        as={LiaAwardSolid}
                        color="purewhite"
                        boxSize="28px"
                    />
                </Flex>
            </Tooltip>
        </Flex>
    )
}
