import { BiArrowBack, BiFile } from "react-icons/bi";
import { EntregablesInt } from "../../../../../interfaces/Entregables"
import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    Flex,
    Text,
    Icon,
    Box,
    useColorMode,
} from '@chakra-ui/react'
import { BsDownload } from "react-icons/bs";
import { format } from "../../../../../shared/utils/functions/formatDate";
import MarkdownPreview from '@uiw/react-markdown-preview';
import { EntregableEstadoEnum } from "../../../../../shared/utils/types/EntregableEstadoEnum";

interface Props {
    entregables: EntregablesInt[];
    isOpen: boolean;
    onClose: () => void;
}

export const HistorialEntregas = ({
    entregables,
    isOpen,
    onClose
}: Props) => {
    const { colorMode } = useColorMode();

    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            size="lg"
            onClose={onClose}
        >
            <DrawerOverlay />

            <DrawerContent
                py="20px"
                px="20px"
                overflow="auto"
                bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
            >
                <Flex
                    direction="column"
                    gap="20px"
                >
                    <Flex>
                        <Icon
                            as={BiArrowBack}
                            boxSize="24px"
                            color="font"
                            cursor="pointer"
                            _active={{ transform: "scale(0.9)" }}
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation()

                                onClose()
                            }}
                        />

                        <Text
                            w="100%"
                            textAlign="center"
                            fontSize="18px"
                            fontWeight="700"
                            color="font"
                        >
                            Historial de entregas
                        </Text>
                    </Flex>

                    {entregables?.map((entregable: EntregablesInt, index: number) => (
                        <Flex
                            direction="column"
                            gap="20px"
                            p="40px"
                            border="1px solid"
                            borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                            rounded="20px"
                            bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                            justifyContent="center"
                            key={index}
                        >
                            <Flex
                                direction="column"
                                gap="10px"
                            >
                                <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Text
                                        fontSize="16px"
                                        fontWeight="700"
                                        color="font"
                                    >
                                        Entrega
                                    </Text>

                                    <Text
                                        fontSize="12px"
                                        fontWeight="400"
                                        lineHeight="160%"
                                        color="#8C909C"
                                    >
                                        Fecha de entrega: <Text as="span" fontWeight="700" color="font">{format({ date: entregable?.fechaEntrega, customFormat: "dd/MM/yyyy" })}</Text>
                                    </Text>
                                </Flex>

                                <Flex
                                    direction="column"
                                    p="15px"
                                    bg={colorMode === "dark" ? "bg_dark_light" : "#F8F8F9"}
                                    rounded="15px"
                                >
                                    <Flex
                                        w="100%"
                                        rounded="15px"
                                        position="relative"
                                        direction="column"
                                        gap="10px"
                                    >
                                        <Flex
                                            h="80px"
                                            p="20px"
                                            border="1px solid"
                                            borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                                            rounded="10px"
                                            justifyContent="space-between"
                                        >
                                            <Flex
                                                alignItems="center"
                                                gap="10px"
                                            >
                                                <Icon
                                                    as={BiFile}
                                                    boxSize="24px"
                                                    color={colorMode === "dark" ? "purewhite" : "secondary"}
                                                />

                                                <Flex
                                                    direction="column"
                                                    gap="4px"
                                                >
                                                    <Text
                                                        fontSize="14px"
                                                        fontWeight="600"
                                                        lineHeight="140%"
                                                    >
                                                        {entregable?.adjunto?.name}
                                                    </Text>

                                                    <Text
                                                        color="#8C909C"
                                                        fontSize="13px"
                                                        fontWeight="500"
                                                        lineHeight="16px"
                                                        letterSpacing={"-0.78px"}
                                                    >
                                                        {entregable?.adjunto?.size} KB
                                                    </Text>
                                                </Flex>
                                            </Flex>

                                            <Flex
                                                p="6px"
                                                justifyContent="center"
                                                alignItems="center"
                                                w="fit-content"
                                                h="fit-content"
                                                bg="purewhite"
                                                rounded="8px"
                                                cursor="pointer"
                                                _active={{ transform: "scale(0.9)" }}

                                                onClick={(e: React.MouseEvent) => {
                                                    e.stopPropagation()

                                                    if (entregable?.adjunto?.url) {
                                                        window.open(entregable.adjunto.url, '_blank')
                                                    }
                                                }}
                                            >
                                                <Icon
                                                    as={BsDownload}
                                                    color="#8C909C"
                                                    boxSize="24px"
                                                />
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>

                            <Flex
                                direction="column"
                                gap="10px"
                            >
                                <Text
                                    fontSize="16px"
                                    fontWeight="700"
                                    color="font"
                                >
                                    Corrección
                                </Text>

                                <Flex
                                    alignItems="center"
                                    gap="5px"
                                >
                                    <Text
                                        fontWeight="400"
                                        fontSize='13px'
                                    >
                                        Nota:
                                    </Text>

                                    <Text
                                        py="4px"
                                        px="15px"
                                        rounded="8px"
                                        bg={
                                            entregable?.estado === EntregableEstadoEnum.ERROR ? "fail_bg" :
                                                entregable?.estado === EntregableEstadoEnum.CORRECTO ? "success_bg" : "#F0F0F3"
                                        }
                                        as="span"
                                        color={
                                            entregable?.estado === EntregableEstadoEnum.ERROR ? "fail" :
                                                entregable?.estado === EntregableEstadoEnum.CORRECTO ? "success" : "#515561"
                                        }
                                        fontSize="13px"
                                        fontWeight="400"
                                    >
                                        {entregable?.correccion?.puntuacion}
                                    </Text>
                                </Flex>

                                <MarkdownPreview
                                    source={entregable?.correccion?.texto || 'El tutor ha revisado tu entrega. No se han dejado comentarios adicionales en esta corrección.'}
                                    style={{
                                        fontFamily: "Inter",
                                        fontWeight: "400",
                                        fontSize: '13px',
                                        width: '100%',
                                        maxWidth: '100%',
                                        color: "#121625",
                                        background: "transparent"
                                    }}
                                    wrapperElement={{ "data-color-mode": "light" }}
                                />

                                <Flex
                                    direction="column"
                                    p="15px"
                                    bg="#F8F8F9"
                                    rounded="15px"
                                    display={entregable?.correccion?.adjunto ? "flex" : "none"}
                                >
                                    <Flex
                                        w="100%"
                                        rounded="15px"
                                        position="relative"
                                        direction="column"
                                        gap="10px"
                                    >
                                        <Flex
                                            h="80px"
                                            p="20px"
                                            border="1px solid"
                                            borderColor="#E6E6EA"
                                            rounded="10px"
                                            justifyContent="space-between"
                                        >
                                            <Flex
                                                alignItems="center"
                                                gap="10px"
                                            >
                                                <Icon
                                                    as={BiFile}
                                                    boxSize="24px"
                                                    color="secondary"
                                                />

                                                <Flex
                                                    direction="column"
                                                    gap="4px"
                                                >
                                                    <Text
                                                        fontSize="14px"
                                                        fontWeight="600"
                                                        lineHeight="140%"
                                                    >
                                                        {entregable?.correccion?.adjunto?.name}
                                                    </Text>

                                                    <Text
                                                        color="#8C909C"
                                                        fontSize="13px"
                                                        fontWeight="500"
                                                        lineHeight="16px"
                                                        letterSpacing={"-0.78px"}
                                                    >
                                                        {entregable?.correccion?.adjunto?.size} KB
                                                    </Text>
                                                </Flex>
                                            </Flex>

                                            <Flex
                                                p="6px"
                                                justifyContent="center"
                                                alignItems="center"
                                                w="fit-content"
                                                h="fit-content"
                                                bg="purewhite"
                                                rounded="8px"
                                                cursor="pointer"
                                                _active={{ transform: "scale(0.9)" }}

                                                onClick={(e: React.MouseEvent) => {
                                                    e.stopPropagation()

                                                    if (entregable?.correccion?.adjunto?.url) {
                                                        window.open(entregable?.correccion.adjunto.url, '_blank')
                                                    }
                                                }}
                                            >
                                                <Icon
                                                    as={BsDownload}
                                                    color="#8C909C"
                                                    boxSize="24px"
                                                />
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    ))}
                </Flex>
            </DrawerContent>
        </Drawer>
    )
}