import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useColorMode } from "@chakra-ui/react";

interface Props {
    onClose: () => void;
    isOpen: boolean;
    onConfirm: () => void;
}

export const ExitPreventAlertModal = ({ isOpen, onClose, onConfirm }: Props) => {
    const { colorMode } = useColorMode()
    
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            isCentered
            size="xl"
        >
            <ModalOverlay />

            <ModalContent
                p="40px"
                bg={colorMode === "dark" ? "bg_dark" : "purewhite"}
                rounded="20px"
            >
                <ModalHeader
                    fontSize="24px"
                    fontWeight="700"
                    color="font"
                >
                    ¿Estás seguro?
                </ModalHeader>

                <ModalBody>
                    <Text
                        fontSize="15px"
                        fontWeight="400"
                        color="font"
                    >
                        Si eliges abandonar la prueba contará como si hubieras utilizado un intento y se descontará de los intentos restantes del examen.
                    </Text>
                </ModalBody>

                <ModalFooter
                    display="flex"
                    gap="20px"
                >
                    <Button
                        color="pureblack"
                        fontSize="18px"
                        fontWeight="700"
                        bg="#E6E6EA"
                        py="14px"
                        px="24px"
                        cursor="pointer"
                        h="fit-content"
                        w="fit-content"
                        rounded="14px"
                        _hover={{ bg: "variant", color: "purewhite" }}
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>

                    <Button
                        color="#FFF"
                        bg="secondary"
                        fontSize="18px"
                        fontWeight="700"
                        h="fit-content"
                        w="fit-content"
                        py="14px"
                        px="24px"
                        rounded="14px"
                        _hover={{ bg: "variant" }}
                        onClick={onConfirm}
                    >
                        Salir
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}