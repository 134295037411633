import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../../../shared/context/user.context";
import { Flex, Image, Text, useColorMode, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MatriculasInt } from "../../../../interfaces/MatriculasInt";
import { useData } from "../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes";
import InfoCurso from "../../components/Information/InfoCurso";
import CardProgresoCurso from "../../components/Information/CardProgresoCurso";
import CardTutor from "../../components/Information/CardTutor";
import RequisitosCurso from "../../components/Information/RequisitosCurso";
import ContenidoCurso from "../../components/Information/ContenidoCurso";
import { Topbar } from "../../../../shared/components/Topbar/Topbar";
import { useDataId } from "../../../../shared/hooks/useDataId";
import { handleUnauthorized } from "../../../../shared/utils/functions/handleErrors";
import { SkeletonInformation } from "../../components/skeletons/SkeletonInformationCurso";
import { UserRolEnum } from "../../../../shared/utils/types/UserRolEnum";
import { useProgressContext } from "../../../../shared/context/progress.context";
import InfoCursoTutor from "../../components/Information/InfoCursoTutor";
import { SkeletonInformationTutor } from "../../components/skeletons/SkeletonInformationTutor";
import CardExamen from "../../components/Information/CardExamen";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/types/StatusEnumTypes";

export const InformationCurso = () => {
    const { slug } = useParams();
    const { cursoProgress, setCursoProgress, refreshProgress } = useProgressContext()
    const navigate = useNavigate()
    const toast = useToast()
    const { user, logout } = useAuthContext();
    const [matriculaActual, setMatriculaActual] = useState<MatriculasInt>()
    const { colorMode } = useColorMode();
    const { data: evaluaciones } = useData({
        endpoint: EndpointTypes.EVALUACIONES
    })

    const { data: matriculas, error, errorsList, status } = useData({
        endpoint: EndpointTypes.MATRICULAS_ALUMNOS,
        ignoreRequest: !user?.auth || user?.role?.nombre !== UserRolEnum.ALUMNO,
        query: {
            limit: 100
        }
    });

    const { data: curso, loading, error: errorCurso, status: statusCurso } = useDataId({
        endpoint: EndpointTypes.CURSOS,
        id: slug
    })

    useEffect(() => {
        if (errorCurso) {
            if (statusCurso === 403) {
                navigate("/aprendizaje")

                toastNotify(toast, StatusEnumTypes.ERROR, "No tienes acceso a este curso")
            }
        }
    }, [errorCurso])

    const { data: examen } = useDataId({
        endpoint: EndpointTypes.EXAMENES,
        id: curso?.examen?.slug
    })

    useEffect(() => {
        if (matriculas && slug) {
            const matriculaEncontrada = matriculas?.data?.find((matricula: MatriculasInt) => matricula?.grupo?.curso?.slug === slug);

            if (matriculaEncontrada) {
                setMatriculaActual(matriculaEncontrada);
            }
        }
    }, [matriculas, slug]);

    useEffect(() => {
        if (!matriculaActual || !curso) return;

        setCursoProgress((prev: any) => ({
            ...prev,
            matriculaId: matriculaActual?.id,
            cursoId: curso?.id,
            cursoSlug: slug,
            cursoNombre: curso?.nombre,
            totalLecciones: curso?.meta?.totalLecciones,
        }))

        refreshProgress();

    }, [matriculaActual, curso])

    useEffect(() => {
        if (error) {
            handleUnauthorized({
                logout: logout,
                navigate: navigate,
                toast: toast
            })
        }
    }, [error])

    const checkExamenCursoAprobado = () => {
        if (!matriculaActual?.evaluaciones || matriculaActual?.evaluaciones?.length === 0) return false;
        else return matriculaActual?.evaluaciones?.find((evaluacion: any) => evaluacion?.aprobado) ? true : false;
    }

    const evaluacionesFiltradas = evaluaciones?.data?.filter((evaluacion: any) => evaluacion.matriculaId === matriculaActual?.id);

    const isSuspendido = evaluacionesFiltradas?.length >= examen?.numIntentos;

    return (
        <Topbar
            buttonBack="/aprendizaje"
            searchBar
        >
            {(loading ||
                (user?.role?.nombre === UserRolEnum.ALUMNO && !matriculaActual)
            ) ?
                (user?.role?.nombre === UserRolEnum.ALUMNO ? <SkeletonInformation /> : <SkeletonInformationTutor />)
                :
                <Flex
                    w="full"

                >
                    <Flex
                        direction="column"
                        p="30px"
                        maxW="500px"
                        gap="30px"
                    >
                        {user?.role?.nombre === UserRolEnum.ALUMNO || user?.role?.nombre === UserRolEnum.ADMIN ?
                            <>
                                <CardProgresoCurso
                                    curso={curso}
                                    progreso={cursoProgress?.porcentajeCurso}
                                    matricula={matriculaActual}
                                />

                                <InfoCurso
                                    matricula={matriculaActual}
                                    curso={curso}
                                />

                                {user?.role?.nombre === UserRolEnum.ALUMNO && curso?.tutorizado &&
                                    <CardTutor
                                        tutor={matriculaActual?.tutor}
                                    />
                                }

                                {curso?.examen &&
                                    <Flex
                                        direction="column"
                                        gap="16px"
                                    >
                                        <Text
                                            fontSize="14px"
                                            fontWeight="700"
                                            color="font"
                                        >
                                            Examen del curso
                                        </Text>

                                        <CardExamen
                                            aprobado={checkExamenCursoAprobado()}
                                            examen={curso?.examen!}
                                            nombre={curso?.nombre}
                                            type="curso"
                                            disabled={(cursoProgress?.porcentajeCurso || 0) < 75 || isSuspendido}
                                            matriculaId={matriculaActual?.id}
                                            isSuspendido={isSuspendido}
                                        />
                                    </Flex>
                                }

                                {user?.role?.nombre === UserRolEnum.ALUMNO &&
                                    <RequisitosCurso
                                        matriculaActual={matriculaActual}
                                        curso={curso}
                                    />
                                }
                            </>
                            :
                            <InfoCursoTutor
                                curso={curso}
                            />
                        }
                    </Flex>

                    <Flex
                        w="1px"
                        h="100%"
                        bg={colorMode === "dark" ? "border_variant" : "border"}
                        py="40px"
                    />

                    <Flex
                        p="30px"
                        w="full"
                    >
                        <ContenidoCurso
                            curso={curso}
                            matricula={matriculaActual}
                        />
                    </Flex>
                </Flex>
            }
        </Topbar >
    )
};