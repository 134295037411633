import { Button, Heading, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useColorMode } from "@chakra-ui/react";

interface Props {
    onClose: () => void;
    isOpen: boolean;
}

export const OutAlertModal = (props: Props) => {
    const { isOpen, onClose } = props;
    const { colorMode } = useColorMode()

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            isCentered
            size="xl"
        >
            <ModalOverlay />

            <ModalContent
                p="40px"
                bg={colorMode === "dark" ? "bg_dark" : "purewhite"}
                rounded="20px"
            >
                <ModalHeader
                    fontSize="24px"
                    fontWeight="700"
                    color="font"
                >
                    ¿Estás seguro?
                </ModalHeader>

                <ModalBody>
                    <Text
                        fontSize="15px"
                        fontWeight="400"
                        color="font"
                    >
                        ¡Vuelve al examen! Si detectamos que cambias de ventana,
                        <Text as={'strong'}> te descontaremos 3 minutos del tiempo restante</Text>. Recuerda que si se te acaba el tiempo, enviaremos
                        las respuestas que hayas completado automáticamente.
                    </Text>
                </ModalBody>

                <ModalFooter>
                    <Button
                        color="#FFF"
                        fontSize="18px"
                        fontWeight="700"
                        lineHeight="28px"
                        bg="main"
                        h="fit-content"
                        w="fit-content"
                        px="24px"
                        py="10px"
                        rounded="14px"
                        cursor="pointer"
                        _hover={{ bg: "variant" }}
                        onClick={onClose}
                    >
                        Continuar examen
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}