import { Box, Flex, Text, Link, Button, Icon, useToast, useColorMode } from "@chakra-ui/react"
import { Topbar } from "../../../../shared/components/Topbar/Topbar"
import { Route, Link as RouterLink, Routes, useLocation } from "react-router-dom";
import { useAuthContext } from "../../../../shared/context/user.context";
import { BiCloud } from "react-icons/bi";
import { Formik, Form as FormikForm } from 'formik';
import { Cuenta } from "../Cuenta/Cuenta";
import React from "react";
import { cambioDatos } from "../../../../shared/middlewares/users.middleware";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { StatusEnumTypes } from "../../../../shared/utils/types/StatusEnumTypes";
import { UserInt } from "../../../../interfaces/UserInt";
import { JornadaLaboral } from "../JornadaLaboral/JornadaLaboral";
import { UserRolEnum } from "../../../../shared/utils/types/UserRolEnum";
import { isRoleAllowed } from "../../../../shared/utils/functions/validateRol";
import { JornadaLaboral as JornadaLaboralType } from "../../../../shared/utils/types/TipoJornadaLaboralEnum";
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes";
import { useData } from "../../../../shared/hooks/useData";
import { MatriculasInt } from "../../../../interfaces/MatriculasInt";

interface TabItem {
    label: string;
    path: string;
    active: boolean;
    allowed: boolean;
}

export interface InputItem {
    label: string;
    name: string;
    data: string | undefined | null;
}

export const Perfil = () => {
    const { user } = useAuthContext()
    const location = useLocation()
    const toast = useToast()
    const { colorMode } = useColorMode()

    const { data: matriculas } = useData({
        endpoint: EndpointTypes.MATRICULAS_ALUMNOS,
        ignoreRequest: !user?.auth || user?.role?.nombre !== UserRolEnum.ALUMNO,
        query: {
            limit: 100
        }
    })

    const tabs: TabItem[] = [
        {
            label: "Cuenta",
            path: "cuenta",
            active: location?.pathname.includes('cuenta'),
            allowed: true
        },
        {
            label: "Información jornada laboral",
            path: "jornada-laboral",
            active: location?.pathname.includes('jornada-laboral'),
            allowed: isRoleAllowed([UserRolEnum.ADMIN, UserRolEnum.ALUMNO], user?.role?.nombre) && matriculas?.data?.some((m: MatriculasInt) => m?.grupo?.fundae === true)
        }
    ]

    const inputsForm: InputItem[] = [
        { label: "Nombre", name: "nombre", data: user?.nombre },
        { label: "Apellidos", name: "apellidos", data: user?.apellidos },
        { label: "Teléfono", name: "telefono", data: user?.telefono },
        { label: "Localidad", name: "localidad", data: user?.localidad },
        { label: "Correo electrónico", name: "email", data: user?.email },
    ]

    const initialValues = {
        horarioLaboral: user?.horarioLaboral,
        ...inputsForm.reduce((acc, { name, data }) => ({
            ...acc,
            [name]: data
        }), {})
    }

    const onSubmit = (values: Record<string, string | JornadaLaboralType | null | undefined>) => {
        let cambios = {}

        Object.entries(values || {}).forEach(([key, value]) => {
            if (user && key in user) {
                const userValue = user[key as keyof UserInt];

                if (value !== userValue) {
                    cambios = {
                        ...cambios,
                        [key]: value
                    };
                }
            } else {
                cambios = {
                    ...cambios,
                    [key]: value
                };
            }
        });

        cambioDatos({
            data: cambios
        })
            .then(() => {
                toastNotify(toast, StatusEnumTypes.SUCCESS, "¡Solicitud enviada correctamente!")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    const isExistsChanges = (
        values: Record<string, string | JornadaLaboralType | null | undefined>
    ) => {
        if (
            values?.nombre !== user?.nombre ||
            values?.apellidos !== user?.apellidos ||
            values?.telefono !== user?.telefono ||
            values?.localidad !== user?.localidad ||
            values?.email !== user?.email ||
            JSON.stringify(values?.horarioLaboral) !== JSON.stringify(user?.horarioLaboral)
        ) return true

        return false
    }

    return (
        <Topbar
            buttonBack={-1}
            searchBar
        >
            <Formik
                onSubmit={onSubmit}
                enableReinitialize
                initialValues={initialValues}
            >
                {(formik) => {
                    const { handleSubmit, values } = formik;

                    return (
                        <FormikForm
                            onSubmit={handleSubmit}
                            style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}
                        >
                            <Flex
                                direction="column"
                                px="30px"
                                gap="10px"
                                flex="1"
                                overflow="auto"
                            >
                                <Flex
                                    px="40px"
                                    py="20px"
                                    gap="80px"
                                    flex="1"
                                >
                                    <Flex
                                        direction="column"
                                        gap="24px"
                                        w="242px"
                                        minW="242px"
                                    >
                                        <Text
                                            color="font"
                                            fontSize="24px"
                                            fontWeight="700"
                                        >
                                            Ajustes
                                        </Text>

                                        <Flex
                                            direction="column"
                                            gap="2px"
                                        >
                                            {tabs?.map((t: TabItem, index: number) => (
                                                t?.allowed &&
                                                <Link
                                                    key={index}
                                                    as={RouterLink}
                                                    to={t?.path}
                                                    py="10px"
                                                    px="14px"
                                                    color={t?.active ? colorMode === "dark" ? "purewhite" : "main" : "font"}
                                                    fontWeight="600"
                                                    fontSize="16px"
                                                    letterSpacing="-0.16px"
                                                    rounded="8px"
                                                    bg={t?.active ? colorMode === "dark" ? "main" : "variant" : "transparent"}
                                                    _hover={{
                                                        textDecoration: "none"
                                                    }}
                                                >
                                                    {t?.label}
                                                </Link>
                                            ))}
                                        </Flex>
                                    </Flex>

                                    <Box
                                        w="1px"
                                        bg={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                                        h="100%"
                                    />

                                    <Routes>
                                        <Route path="/cuenta" element={<Cuenta inputsForm={inputsForm} />} />

                                        <Route
                                            path="/jornada-laboral"
                                            element={
                                                <JornadaLaboral
                                                    setValue={(e) => formik?.setFieldValue("horarioLaboral", e)}
                                                    value={values?.horarioLaboral}
                                                />
                                            }
                                        />
                                    </Routes>
                                </Flex>

                                <Flex
                                    p="14px"
                                    w="100%"
                                    bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                                    border="1px solid"
                                    borderColor={colorMode === "dark" ? "border_variant" : "#E6E6EA"}
                                    rounded="20px"
                                    alignItems={"center"}
                                    justifyContent="space-between"
                                    mb="20px"
                                    mt="auto"
                                >
                                    <Flex
                                        alignItems="center"
                                        gap="10px"
                                    >
                                        <Icon
                                            as={BiCloud}
                                            boxSize="34px"
                                            color="#8C909C"
                                        />

                                        <Text
                                            color="font"
                                            fontSize="16px"
                                            fontWeight="600"
                                            letterSpacing="-0.16px"
                                        >
                                            Se enviará una solicitud de cambio de datos. ¿Deseas enviar los nuevos datos?
                                        </Text>
                                    </Flex>

                                    <Flex
                                        alignItems="center"
                                        gap="17px"
                                    >
                                        <Button
                                            h="fit-content"
                                            w="fit-content"
                                            px="16px"
                                            py="10px"
                                            fontSize="16px"
                                            fontWeight="700"
                                            lineHeight="22px"
                                            letterSpacing="-0.408px"
                                            bg="transparent"
                                            _hover={{ bg: "variant", color: "purewhite" }}
                                            isDisabled={!isExistsChanges(values)}
                                            onClick={(e: React.MouseEvent) => {
                                                e.stopPropagation()

                                                formik.resetForm()
                                            }}
                                        >
                                            Descartar cambios
                                        </Button>

                                        <Button
                                            h="fit-content"
                                            w="fit-content"
                                            px="16px"
                                            py="10px"
                                            fontSize="16px"
                                            fontWeight="700"
                                            lineHeight="22px"
                                            letterSpacing="-0.408px"
                                            color="purewhite"
                                            bg="main"
                                            type="submit"
                                            isDisabled={!isExistsChanges(values)}
                                        >
                                            Enviar solicitud
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </FormikForm>
                    );
                }}
            </Formik>
        </Topbar >
    )
}