import { Box, Button, Container, Flex, HStack, Image, Link, SimpleGrid, Skeleton, Text, useColorMode, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { CursosInt } from "../../../../interfaces/CursosInt";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useData } from "../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../shared/utils/types/EndpointTypes";
import { iconoTenant } from "../../../../shared/middlewares/tenant.middleware";
import { useTenantInfo } from "../../../../shared/hooks/useTenantInfo";
import { TenantHeaderSkeleton } from "../../components/TenantSkeleton";
import { CursosCardSkeleton } from "../../components/CursosCardSkeleton";
import { CardCursos } from "../../components/CardCursos";
import { Seo } from "../../../../shared/components/Seo/Seo";

export const CursosPublicos = () => {
    const { colorMode } = useColorMode();
    const navigate = useNavigate();
    const location = useLocation();
    const { handleSetInfo, handleGetInfo } = useTenantInfo();
    const [loading, setLoading] = useState<boolean>(true)
    const icono = handleGetInfo({ key: "icono" });
    const nombre = handleGetInfo({ key: "nombre" });
    const colores = handleGetInfo({ key: "colores" });

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [search, _setSeatch] = useSearchParams()
    const [lastPage, setLastPage] = useState<number>(1);

    const { data: cursos, loading: loadingCursos } = useData({
        endpoint: EndpointTypes.CURSOS,
        query: {
            limit: 6,
            page: currentPage
        },
        isPublic: true
    })

    useEffect(() => {
        setLoading(true)

        iconoTenant()
            .then(async (response) => {
                if (response?.icono && response?.icono !== icono)
                    handleSetInfo({ key: "icono", value: response?.icono })
                if (response?.nombre && response?.nombre !== nombre)
                    handleSetInfo({ key: "nombre", value: response?.nombre })
                if (response?.colores && response?.colores !== colores)
                    handleSetInfo({ key: "colores", value: response?.colores })
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        const pageFromUrl = parseInt(search.get('page') || '1', 10);

        setCurrentPage(pageFromUrl);
    }, [location.search]);

    useEffect(() => {
        if (cursos?.meta?.last_page) {
            setLastPage(cursos.meta.last_page);
        }
    }, [cursos?.meta?.last_page]);

    const handlePageChange = (newPage: number) => {
        navigate(`?page=${newPage}`);
    };

    return (
        <>
            <Seo
                title={`${nombre} - Explora Nuestros Cursos`}
                description="Descubre una amplia variedad de cursos diseñados para impulsar tu carrera y expandir tus conocimientos en nuestra plataforma de aprendizaje en línea."
            />

            <Box
                bg={colorMode === "light" ? "gray.50" : "bg_dark"}
                minH="100vh"
                py={{ base: 8, md: 16 }}
            >
                <Container
                    maxW="container.xl"
                    px={{ base: 4, md: 8 }}
                >
                    <VStack
                        spacing={{ base: 8, md: 16 }}
                    >
                        {loading ? (
                            <TenantHeaderSkeleton />
                        ) : (nombre && icono) ? (
                            <Box
                                bg={colorMode === "light" ? "white" : "bg_dark_light"}
                                p={8}
                                borderRadius="xl"
                                boxShadow="lg"
                                w="full"
                            >
                                <VStack
                                    spacing={6}
                                >
                                    <Flex
                                        w="200px"
                                        alignItems="center"
                                        gap="5px"
                                    >
                                        <Image
                                            src={icono}
                                            alt='img/logo'
                                            objectFit="contain"
                                            w="65px"
                                            h="65px"
                                        />

                                        <Text
                                            textTransform="capitalize"
                                            fontSize="20px"
                                            color="font"
                                            fontWeight="700"
                                        >
                                            {nombre}
                                        </Text>
                                    </Flex>

                                    <Text
                                        as="h2"
                                        size="xl"
                                        textAlign="center"
                                        color="font"
                                    >
                                        Cursos Disponibles
                                    </Text>

                                    <Text
                                        fontSize="lg"
                                        textAlign="center"
                                        color="font"
                                        maxW="800px"
                                    >
                                        Explora nuestra selección de cursos y comienza tu aprendizaje hoy. Descubre oportunidades para crecer profesionalmente y adquirir nuevas habilidades.
                                    </Text>
                                </VStack>
                            </Box>
                        ) : null}

                        <SimpleGrid
                            columns={{ base: 1, sm: 2, lg: 3 }}
                            spacing={{ base: 8, md: 10 }}
                            w="full"
                            justifyItems="center"
                        >
                            {loadingCursos ? (
                                Array.from({ length: 6 }).map((_, index) => (
                                    <CursosCardSkeleton
                                        key={index}
                                    />
                                ))
                            ) : (
                                Array.isArray(cursos?.data) && cursos?.data.length > 0 ? (
                                    cursos?.data.map((curso: CursosInt) => (
                                        <CardCursos
                                            key={curso?.id}
                                            curso={curso}
                                        />
                                    ))
                                ) : (
                                    <Text>No hay cursos disponibles.</Text>
                                )
                            )}
                        </SimpleGrid>

                        <Box
                            bg={colorMode === "light" ? "white" : "bg_dark_light"}
                            p={8}
                            borderRadius="xl"
                            boxShadow="md"
                            w="full"
                        >
                            <HStack
                                justifyContent="center"
                                spacing={6}
                            >
                                <Button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    isDisabled={currentPage === 1}
                                    bg="transparent"
                                    color={colorMode === "light" ? "main" : "white"}
                                    border="1px"
                                    borderColor={colorMode === "light" ? "main" : "white"}
                                    size="lg"
                                    _hover={{ bg: colorMode === "light" ? "main" : "white", color: colorMode === "light" ? "white" : "main" }}
                                >
                                    Anterior
                                </Button>

                                <Text fontSize="lg" fontWeight="medium" color="font">{`Página ${currentPage} de ${lastPage}`}</Text>

                                <Button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    isDisabled={currentPage === lastPage || lastPage === 0}
                                    bg="main"
                                    color="white"
                                    size="lg"
                                    _hover={{ opacity: 0.8 }}
                                >
                                    Siguiente
                                </Button>
                            </HStack>
                        </Box>
                    </VStack>
                </Container>
            </Box>
        </>
    );
}