import { Flex, Image, Skeleton, Text, useColorMode } from "@chakra-ui/react"
import { useData } from "../../../shared/hooks/useData"
import { EndpointTypes } from "../../../shared/utils/types/EndpointTypes"
import { useNavigate } from "react-router-dom";

interface Props {
    loadingMatriculas?: boolean;    
}

export const CardMensajes = ({ loadingMatriculas }: Props) => {
    const { colorMode } = useColorMode()
    const navigate = useNavigate();
    const { data: conversaciones, loading: loadingConversaciones } = useData({
        endpoint: EndpointTypes.MENSAJERIA_CONVERSACION
    })

    const { data: mensajes, loading: loadingMensajes } = useData({
        endpoint: EndpointTypes.MENSAJERIA
    })

    return (
        (!loadingConversaciones && !loadingMensajes && !loadingMatriculas) ?
            <Flex
                rounded="16px"
                bg={colorMode === "dark" ? "bg_dark_light" : "#FFF1ED"}
                px="24px"
                py="24px"
                direction="column"
                alignItems="start"
                justifyContent="space-between"
                h="190px"
                overflow="auto"
                position="relative"
                borderWidth="1px"
                borderStyle="solid"
                borderColor={colorMode === "dark" ? "border_variant" : "border"}
                borderLeftWidth="3px"
                borderLeftColor="#47A785"
                onClick={() => navigate("/mensajeria")}
                cursor="pointer"
                transition="all 0.3s ease"
                _hover={{
                    borderLeftColor: "main"
                }}
            >
                <Text
                    color="font"
                    fontSize="12px"
                    fontWeight="400"
                    textTransform="uppercase"
                    zIndex="99"
                >
                    Mensajería
                </Text>

                <Flex
                    direction="column"
                    alignItems="start"
                    gap="8px"
                    zIndex="99"
                >
                    <Text
                        color="font"
                        fontSize="24px"
                        fontWeight={"600"}
                    >
                        {conversaciones?.meta?.total} chats · {mensajes?.meta?.total} mensajes
                    </Text>

                    <Text
                        color="font"
                        fontSize="12px"
                        fontWeight="400">
                        Interactúa con tus tutores
                    </Text>
                </Flex>
            </Flex>
            :
            <Skeleton
                h="190px"
                w="100%"
                rounded="16px"
            />
    )
}