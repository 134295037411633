import { Box, VStack, HStack, Button, Link, Text, Image, useColorMode } from "@chakra-ui/react"
import { Link as RouterLink } from "react-router-dom"
import { CursosInt } from "../../../interfaces/CursosInt"
import defaultImageUrl from "../../../assets/img/defaultPortada.png"

interface Props {
    curso: CursosInt;
}

export const CardCursos = ({
    curso
}: Props) => {
    const { colorMode } = useColorMode();

    return (
        <Link
            as={RouterLink}
            to={`/public/cursos/${curso?.slug}`}
            _hover={{ textDecoration: 'none' }}
        >
            <Box
                borderWidth="1px"
                borderRadius="xl"
                overflow="hidden"
                transition="all 0.3s"
                _hover={{ transform: 'translateY(-5px)', shadow: 'xl' }}
                w={{ base: "100%", sm: "280px", md: "320px" }}
                h={{ base: "auto", sm: "420px" }}
                bg={colorMode === "light" ? "white" : "bg_dark_light"}
                boxShadow="md"
            >
                <Image
                    src={curso?.imagen?.url || defaultImageUrl}
                    alt={curso?.nombre}
                    objectFit="cover"
                    h={{ base: "200px", sm: "220px" }}
                    w="100%"
                    fallbackSrc={defaultImageUrl}
                />

                <VStack
                    p={5}
                    align="start"
                    spacing={3}
                    h={{ base: "auto", sm: "220px" }}
                    position="relative"
                >
                    <Text
                        size="md"
                        noOfLines={2}
                        h="2.6em"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        color="font"
                    >
                        {curso?.nombre}
                    </Text>

                    <HStack spacing={4}>
                        <Text
                            fontSize="sm"
                            fontWeight="bold"
                            color={colorMode === "light" ? "main" : "white"}
                        >
                            Duración: {curso?.duracion}h
                        </Text>

                        <Text
                            fontSize="sm"
                            color="font"
                        >
                            |
                        </Text>

                        <Text
                            fontSize="sm"
                            color="font"
                        >
                            {curso?.meta?.totalModulos} módulos
                        </Text>
                    </HStack>

                    <Text
                        fontSize="sm"
                        color="font"
                    >
                        {curso?.meta?.totalLecciones} lecciones
                    </Text>

                    <Button
                        bg="main"
                        color="white"
                        size="md"
                        width="100%"
                        position="absolute"
                        bottom="8"
                        left="0"
                        right="0"
                        mx="auto"
                        maxWidth="calc(100% - 40px)"
                        _hover={{ bg: "main", opacity: 0.8 }}
                    >
                        Ver detalles
                    </Button>
                </VStack>
            </Box>
        </Link>
    )
}