import { Box, Button, Flex, Icon, Progress, Skeleton, Text, useColorMode } from "@chakra-ui/react"
import { useData } from "../../../shared/hooks/useData"
import { EndpointTypes } from "../../../shared/utils/types/EndpointTypes"
import { MatriculasInt } from "../../../interfaces/MatriculasInt"
import { useDataId } from "../../../shared/hooks/useDataId";
import { onContinueLeccion } from "../../../shared/utils/functions/validateProgress";
import { LeccionInt } from "../../../interfaces/CursosInt";
import { BsPencilSquare } from "react-icons/bs";
import { HiArrowNarrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { CardSinModulo } from "./SinMatriculas/CardSinModulo";

interface Props {
    matricula: MatriculasInt | undefined;
}

export const CardContinuar = ({
    matricula
}: Props) => {
    const { colorMode } = useColorMode()

    const navigate = useNavigate()

    const { data: progreso } = useData({
        endpoint: EndpointTypes.PROGRESOS,
        query: {
            curso: matricula?.grupo?.cursoId!,
            matricula: matricula?.id!
        },
        ignoreRequest: !matricula
    })

    const { data: curso } = useDataId({
        endpoint: EndpointTypes.CURSOS,
        id: matricula?.grupo?.curso?.slug,
        ignoreRequest: !matricula
    })

    const modulo = onContinueLeccion(progreso?.data[0]?.progresoLecciones, curso)?.modulo;
    const leccion = modulo?.lecciones?.find((leccion: LeccionInt) =>
        leccion.slug === onContinueLeccion(progreso?.data[0]?.progresoLecciones, curso).leccion?.slug
    );

    return (
        (modulo && matricula) ?
            <Flex
                w="50%"
                p="20px"
                border="1px solid"
                borderColor="main"
                rounded="16px"
                bg={colorMode === "dark" ? "bg_dark_light" : "purewhite"}
                direction="column"
                gap="24px"
                h="255px"
                transition="all 0.2s"
                _hover={{
                    transform: "scale(1.01)",
                }}
                cursor="pointer"
                onClick={(e: React.MouseEvent) => {
                    e.stopPropagation()

                    navigate(`/aprendizaje/${matricula?.grupo?.curso?.slug}/lecciones/${leccion?.slug}?grupo=${matricula?.grupo?.slug}`)
                }}
            >
                <Text
                    fontSize="16px"
                    fontWeight="500"
                    color="font"
                >
                    Continuar lección
                </Text>

                <Flex
                    direction="column"
                    gap="14px"
                >
                    <Text
                        color={colorMode === "dark" ? "#DADEED" : "#666D80"}
                        fontSize="14px"
                        fontWeight="400"
                    >
                        Módulo {modulo?.orden}: {modulo?.nombre}
                    </Text>

                    <Flex
                        alignItems={"center"}
                        gap="6px"
                    >
                        <Icon
                            as={BsPencilSquare}
                            boxSize="20px"
                            color="main"
                        />

                        <Text
                            color="font"
                            fontSize="20px"
                            fontWeight="600"
                        >
                            {leccion?.nombre}
                        </Text>
                    </Flex>

                    <Progress
                        w="100%"
                        bg={colorMode === "dark" ? "#424242" : "#EEEFF2"}
                        maxW="100%"
                        rounded="full"
                        max={100}
                        h={"6px"}
                        value={matricula?.progresoLecciones || 0}
                        sx={{ '& > div': { background: "main" } }}
                    />

                    <Box
                        mt="10px"
                        w="100%"
                        h="1px"
                        border="1px dashed"
                        borderColor={colorMode === "dark" ? "#576776" : "#DFE1E6"}
                    />

                    <Button
                        bg="transparent"
                        _hover={{}}
                        _active={{}}
                        color={colorMode === "dark" ? "#DADEED" : "#666D80"}
                        fontSize="14px"
                        fontWeight="500"
                        display="flex"
                        w="100%"
                        alignItems="center"
                        justifyContent="space-between"
                        h="fit-content"
                        py="10px"
                        px="0"
                    >
                        Seguir aprendiendo

                        <Icon
                            as={HiArrowNarrowRight}
                            boxSize="20px"
                        />
                    </Button>
                </Flex>
            </Flex>
            : matricula && !modulo ?

                <CardSinModulo />

                :

                <Skeleton
                    w="50%"
                    h="255px"
                    rounded="16px"
                />

    )
}